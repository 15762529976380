import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class DataSharedService {

    private messageSource = new BehaviorSubject('default');
    currentMessage = this.messageSource.asObservable();

    constructor() { }

    changeMessage(message) {
        this.messageSource.next(message)
    }
}