import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyOrderdetailsPageComponent } from './my-orderdetails-page.component';

const routes1: Routes = [
    {
        path: "",
        component: MyOrderdetailsPageComponent
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes1)]
})
export class MyOrderdetailsPageRoutingModule { }