import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderService } from '../services/order.service';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../services/router.service';

declare var $: any

@Component({
  selector: 'app-payment-success-return-page',
  templateUrl: './payment-success-return-page.component.html',
  styleUrls: ['./payment-success-return-page.component.css']
})
export class PaymentSuccessReturnPageComponent implements OnInit, OnDestroy {

  orderId: string;
  approvesub: Subscription;
  submitMessage: string;
  ordernum: string;
  paymentstatus: string;
  orderapprovestatussns: any;

  message: string;

  constructor(
    private orderservice: OrderService,
    private routerService: RouterService,
    private router: ActivatedRoute
  ) { }

  ngOnInit() {

    $(document).ready(function ($) {
      $("#mobile-menu").mobileMenu({
        MenuWidth: 250,
        SlideSpeed: 300,
        WindowsMaxWidth: 767,
        PagePush: true,
        FromLeft: true,
        Overlay: true,
        CollapseMenu: true,
        ClassName: "mobile-menu"
      });
    });

    this.router.queryParams.subscribe(
      params => {
        this.ordernum = params.num;
        this.paymentstatus = params.status;
      }
    );
    /**
     * Successful Payment
     */
    if ((this.paymentstatus == 'PAID') || (this.paymentstatus == 'SUCCESS')) {
      this.updateOrderStatus(this.ordernum);
      this.message = "Thanks for shopping with us. Your order has been placed. To track your order details," +
        "please go to your 'My Order' section. " +
        "Also, we are happy to let you know that 1% of your total order amount will donate to THANGAPARA NEW HORIZON LIBRARY (https://horizonlibrary.org/) to support education for all deprived children in rural India."
    }
    /**
     * Payment Failed
     */
    else if (this.paymentstatus == 'ACTIVE') {
      this.message = "Your order has been ACTIVE. " +
        "Please reach out to us for any queries or concern at 'info@khoaihaat.com'."
    }
    /**
     * Payment successful but kept on hold by risk system
     */
    else if (this.paymentstatus == 'FLAGGED') {

    }
    /**
     * Pending Payment
     */
    else if (this.paymentstatus == 'EXPIRED') {
      this.message = "Your order has been EXPIRED due to some issues. " +
        "Please reach out to us for any queries or concern at 'info@khoaihaat.com'."
    }
    /**
     * Payment cancelled by user
     */
    else if (this.paymentstatus == 'CANCELLED') {
      this.message = "Your order has been CANCELLED. " +
        "Please reach out to us for any queries or concern at 'info@khoaihaat.com'."
    }
    /**
     * 
     */
    else if (this.paymentstatus == 'USER_DROPPED') {

    } else {
      this.routerService.routeToHome();
    }

  }

  updateOrderStatus(ordernumber) {
    var useremail: string = localStorage.getItem('user');
    this.approvesub = this.orderservice.orderApproveProcessToSNS(ordernumber, useremail)
      .subscribe(
        response => {
          response = this.orderapprovestatussns = response
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.orderapprovestatussns['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          }
        });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.approvesub) {
      this.approvesub.unsubscribe();
    }
  }

}
