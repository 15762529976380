import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../../services/authentication.service';
import { RouterService } from '../../services/router.service';
import { OrderService } from '../../services/order.service';
import { OrderList } from '../../models/order.model'
import { EncrDecrService } from '../../services/encrdecr.service';
import { Utility } from '../../common/utility';

@Component({
  selector: 'app-my-orders-info',
  templateUrl: './my-orders-info.component.html',
  styleUrls: ['./my-orders-info.component.css']
})
export class MyOrdersInfoComponent implements OnInit, OnDestroy {

  submitMessage: string;
  orderData: Subscription;
  orderlistdata: any;
  order: OrderList;
  ordercount: number;
  public mapobject: Map<string, any> = new Map<string, any>();
  items: any;
  isOrderList: boolean = false;

  constructor(
    private routerService: RouterService,
    private orderService: OrderService,
    private authService: AuthenticationService,
    private encryptService: EncrDecrService
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.getAllOrders();
  }

  getAllOrders() {
    return this.orderData = this.orderService.getAllOrderListByCustomerId(this.custId)
      .subscribe(
        data => {
          this.orderlistdata = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.orderlistdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            if (this.orderlistdata.message == undefined) {
              this.isOrderList = true;
              this.order = this.orderlistdata.customerorders;
              this.ordercount = this.orderlistdata.customerorders.length;

              var x = this.ordercount;
              for (let i = 0; i < x; i++) {
                this.mapobject.set(this.order[i].order_number, this.orderlistdata.customerorders[i].orderitems);
              }
            } else {
              this.isOrderList = false;
            }
          }
        });
  }

  submitReview(pid) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);
    //window.location.replace('/myreviews?pid=' + encodevalue);
    this.routerService.routeToMyReview(encodevalue);
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  cancelOrder(orderid: string, orderitemid: string) {
    var encryptorderid = this.encryptService.set(Utility.secretKey, orderid);
    var encryptordervalue = encodeURIComponent(encryptorderid);

    var encryptorderitem = this.encryptService.set(Utility.secretKey, orderitemid);
    var encryptorderitemvalue = encodeURIComponent(encryptorderitem);

    this.routerService.routeToCancelOrderPage(encryptordervalue, encryptorderitemvalue);
  }

  initiateReturn(orderid: string, orderitemid: string) {
    var encryptorderid = this.encryptService.set(Utility.secretKey, orderid);
    var encryptordervalue = encodeURIComponent(encryptorderid);

    var encryptorderitem = this.encryptService.set(Utility.secretKey, orderitemid);
    var encryptorderitemvalue = encodeURIComponent(encryptorderitem);

    this.routerService.routeToReturnReplaceOrderPage(encryptordervalue, encryptorderitemvalue);
  }

  viewOrderDetails(orderid: string, orderitemid: string) {

    var encryptorderid = this.encryptService.set(Utility.secretKey, orderid);
    var encryptordervalue = encodeURIComponent(encryptorderid);

    var encryptorderitem = this.encryptService.set(Utility.secretKey, orderitemid);
    var encryptorderitemvalue = encodeURIComponent(encryptorderitem);

    this.routerService.routeToOrderDetails(encryptordervalue, encryptorderitemvalue);
  }

  generateInvoice(ordernumber: string) {
    var encryptordernumber = this.encryptService.set(Utility.secretKey, ordernumber);
    var encryptordernumvalue = encodeURIComponent(encryptordernumber);

    window.open('/invoice?onum=' + encryptordernumvalue, '_blank');

  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.orderData) {
      this.orderData.unsubscribe();
    }
  }

}
