import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePageRoutingModule } from './home-page-routing.module';
import { HomePageComponent } from './home-page.component';

import SharedModule from '../shared.module';
import HomeSpecificModule from '../modules/home-specific.module';

@NgModule({
    declarations: [HomePageComponent],
    imports: [
        SharedModule,
        HomeSpecificModule,
        HomePageRoutingModule
    ],
    providers: []
})
export class HomePageModule {}