import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from '../modal-window/modal.module';

import { ForgetPasswordPopupComponent } from '../auth-component/forget-password-popup/forget-password-popup.component';

@NgModule({
    imports: [CommonModule,
        ReactiveFormsModule],
    declarations: [
        ForgetPasswordPopupComponent
    ],
    exports: [
        ForgetPasswordPopupComponent,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        ModalModule
    ]
})
export default class ForgetPasswordPopupModule {}