import { NgModule } from '@angular/core';
import { ModalModule } from '../modal-window/modal.module';

import { MyProfilePageRoutingModule } from './my-profile-page-routing.module';
import { MyProfilePageComponent } from './my-profile-page.component';
import { MyProfileInfoComponent } from '../customer-component/my-profile-info/my-profile-info.component';
import { ChangePasswordPopupComponent } from '../customer-component/change-password-popup/change-password-popup.component';

import SharedModule from '../shared.module';
import CustomerAccountRightNavModule from '../modules/customer-account-right-nav.module';

@NgModule({
    declarations: [
        MyProfilePageComponent,
        MyProfileInfoComponent,
        ChangePasswordPopupComponent
    ],
    imports: [
        SharedModule,
        ModalModule,
        MyProfilePageRoutingModule,
        CustomerAccountRightNavModule
    ],
    providers: []
})
export class MyProfilePageModule { }