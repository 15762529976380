import { NgModule } from '@angular/core';

import { LoginPageRoutingModule } from './login-page-routing.module';
import { LoginPageComponent } from './login-page.component';
import { LoginMainInfoComponent } from '../auth-component/login-main-info/login-main-info.component';

import { ModalModule } from '../modal-window/modal.module';
import SharedModule from '../shared.module';
import ForgetPasswordPopupModule from '../modules/forget-password-popup.module';
import { RegisterInfoPopupComponent } from '../auth-component/register-info-popup/register-info-popup.component';

@NgModule({
  declarations: [
    LoginPageComponent,
    LoginMainInfoComponent,
    RegisterInfoPopupComponent],
  imports: [
    SharedModule,
    LoginPageRoutingModule,
    ForgetPasswordPopupModule,
    ModalModule
  ],
  providers: []
})
export class LoginPageModule {}