import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { first, catchError } from 'rxjs/operators';
import { Observable, throwError, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ModalService } from '../../modal-window/modal.service';
import { CustomerService } from '../../services/customer.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-register-info-popup',
  templateUrl: './register-info-popup.component.html',
  styleUrls: ['./register-info-popup.component.css']
})
export class RegisterInfoPopupComponent implements OnInit, OnDestroy {

  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  customeremail = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  submitMessage: string;
  registerres: string;
  custId: string;
  custemail: string;
  custtoken: string;
  logintype: string;
  updateresponse: any;
  emailsendtstatus: any;

  registersub: Subscription;
  loginsub: Subscription;
  updatesessionsub: Subscription;
  sendregistrationsuccessemailsub: Subscription;

  registerForm = new FormGroup({
    email: this.customeremail,
    password: this.password
  });
  loading = false;
  submitted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private routerService: RouterService,
    private modalService: ModalService,
    private userService: CustomerService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.routerService.routeToHome();
    }
  }

  ngOnInit() {
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    /**
       * For Google Analytics to track sign_up event
       */

    this
      .googleAnalyticsService
      .eventEmitter("sign_up", "engagement", "method", "click", 5);

    /**
     * ***********************************************
     */

    this.registersub = this.userService.register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          data = this.registerres;
          this.loginsub = this.authenticationService.loginUser(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
              res => {
                this.custId = this.authenticationService.getCustomerId();
                this.custemail = this.authenticationService.getCustomer();
                this.custtoken = this.authenticationService.getToken();
                this.logintype = "ORGANIC";
                let sdata = {
                  "customer_id": this.custId,
                  "jwt_token": this.custtoken,
                  "customer_email": this.custemail,
                  "customer_login_type": this.logintype
                }
                //The following code is responsible to send successful email after user register for the first time
                if (this.custId != '') {
                  this.sendregistrationsuccessemailsub = this.userService.sendUserRegistrationSuccessEmail(this.custemail)
                    .subscribe(
                      data => {
                        data = this.emailsendtstatus = data;
                      });
                }
                // The service is responsible to insert user session data inside DB every time he/she logged in
                this.updatesessionsub = this.userService.addSessionDetails(sdata)
                  .subscribe(
                    data => {
                      data = this.updateresponse = data;
                      localStorage.setItem("sessionid", this.updateresponse.id);
                      localStorage.setItem("login_type", this.logintype);
                      this.routerService.routeToHome();
                    });

              });
        },
        error => {
          this.submitMessage = "Email Already Exists, please use another email.";
          this.registerForm.reset();
        });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.registersub) {
      this.registersub.unsubscribe();
    }
    if (this.loginsub) {
      this.loginsub.unsubscribe();
    }
    if (this.updatesessionsub) {
      this.updatesessionsub.unsubscribe();
    }
    if (this.sendregistrationsuccessemailsub) {
      this.sendregistrationsuccessemailsub.unsubscribe();
    }
  }

}
