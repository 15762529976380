import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyProductreviewPageComponent } from './my-productreview-page.component';

const routes1: Routes = [
    {
        path: "",
        component: MyProductreviewPageComponent
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes1)]
})
export class MyProductreviewPageRoutingModule { }