import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-sell-on-khoaihaat-info',
  templateUrl: './sell-on-khoaihaat-info.component.html',
  styleUrls: ['./sell-on-khoaihaat-info.component.css']
})
export class SellOnKhoaihaatInfoComponent implements OnInit, OnDestroy {

  selldatasub: Subscription;
  submitted = false;
  submitMessage: string;
  hideSuccessMessage: boolean = true;
  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  message: string;

  first_name = new FormControl('', Validators.required);
  last_name = new FormControl('', Validators.required);
  phone_number = new FormControl('', [Validators.required, Validators.maxLength(10)]);
  email_id = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  address = new FormControl('', Validators.required);
  comment = new FormControl('', Validators.required);

  sellToUs = new FormGroup({
    first_name: this.first_name,
    last_name: this.last_name,
    phone_number: this.phone_number,
    email_id: this.email_id,
    address: this.address,
    comment: this.comment
  });

  constructor(
    private userService: CustomerService
  ) { }

  // convenience getter for easy access to form fields
  get f() {
    return this.sellToUs.controls;
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.sellToUs.invalid) {
      return;
    }

    let querydata = {
      "first_name": this.f.first_name.value,
      "last_name": this.f.last_name.value,
      "phone_number": this.f.phone_number.value,
      "email_id": this.f.email_id.value,
      "address": this.f.address.value,
      "comment": this.f.comment.value,
    }

    this.selldatasub = this.userService.addSellerInfoQueryDetails(querydata)
      .subscribe(
        data => {
          if (data) {
            this.message = "Many thanks for contacting us. Will get back to you soon"
            this.hideSuccessMessage = false;
          } else {
            this.message = "Something went wrong during submission. Please try later. Sorry for the inconvenience."
            this.hideSuccessMessage = false;
          }
        });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
      window.location.replace('/sell');
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.selldatasub) {
      this.selldatasub.unsubscribe();
    }
  }

}
