import { NgModule } from '@angular/core';

import { ProductDetailsPageRoutingModule } from './product-details-page-routing.module';

import SharedModule from '../shared.module';
import { ProductDetailsPageComponent } from '../product-details-page/product-details-page.component';
import { ProductInfoDetailsComponent } from '../product-component/product-info-details/product-info-details.component';

@NgModule({
    declarations: [
        ProductDetailsPageComponent,
        ProductInfoDetailsComponent],
    imports: [
        SharedModule,
        ProductDetailsPageRoutingModule],
    providers: []
})
export class ProductDetailsPageModule {}