import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators'
import { throwError } from 'rxjs';

import { environment } from '../../environments/environment.prod';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }


  /**
   * Get list of all orders using a customer id
   * @param custId 
   */
  getAllOrderListByCustomerId(custId) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/list/' + custId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service will be responsible to extract order details with respect to order id and item id
   * @param orderId 
   * @param itemId 
   */
  getOrderDetails(orderId, itemId) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/details/info/' + orderId + '/' + itemId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The service will responsible to ONLY to create the order and set the delivery status as ORD_CRT
   * 
   * @param orderdata 
   * @param custId 
   */
  createOrder(orderdata, custId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/orders/' + custId;
    let body = JSON.stringify(orderdata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service is responsible to approve an order and create invoice data and send order
   * details to SNS for further processing. This service will execute after payment received successfully
   * 
   * @param processdata 
   * @param custId 
   */
  orderApproveProcessToSNS(ordernumber, useremail) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/orders/payment/approved/' + ordernumber + '/' + useremail;
    return this.http.post<any>(url, { headers: headers });
  }

  /**
   * The service will responsible to create the signature before sending the payment data to cashfree
   * Though this service not being used anymore
   * 
   * @param orderdata 
   */
  getPaymentSignature(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/orders/payment/signature/generate';
    let body = JSON.stringify(orderdata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service is responsible to create the payment link where user should be redirect to
   * for payment.
   * 
   * @param orderdata 
   */
  getPaymentLinkFromCashfree(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/payment/request';

    let body = JSON.stringify(orderdata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service will be responsible to extract the current status of an order
   * @param orderId 
   * @returns 
   */
  getOrderCurrentStatus(orderId) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/status/' + orderId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to check whether an order is eligible for return or replace
   * @param orderId 
   * @returns 
   */
  getOrderCurrentRRStatus(orderId) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/return/valid/' + orderId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to cancel order with item wise. If all item got cancelled then
   * order will be automatically cancel
   * @param orderdata 
   * @returns 
   */
  cancelOrderItem(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/item/cancel';
    let body = JSON.stringify(orderdata);

    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The following service is reponsible to send cancel order details to AWS SNS which eventually distribute 
   * to all subscribers
   * 
   * @param orderdata 
   * @returns 
   */
  sendCancelOrderInfoToSNS(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/utilitymgmt/customer/utility/process/order/cancel';
    let body = JSON.stringify(orderdata);

    return this.http.post<any>(url, body, { headers: headers });
  }


  /**
   * The following service is responsible to return or replace an item
   * @param orderdata 
   * @returns 
   */
  returnReplaceOrderItem(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/item/return';
    let body = JSON.stringify(orderdata);

    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The following service is responsible to send order data to SNS for proceesing of return or replace
   * @param orderdata 
   */
  sendReturnReplaceOrderInfoToSNS(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/utilitymgmt/customer/utility/process/order/return/replace';
    let body = JSON.stringify(orderdata);

    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service is responsible to generate invoice based on a specific order number
   * @param ordernumber 
   * @returns 
   */
  generateInvoice(ordernumber) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/invoice/' + ordernumber;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to extract AWB number using order number
   * 
   * @param ordernumber 
   * @returns 
   */
  getAWBNumberUsingOrderNumber(ordernumber: string) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/tracking/number/' + ordernumber;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to extract total order count with respect to a specific 
   * customer
   * 
   * @param custId 
   * @returns 
   */
  getTotalOrderCountForUser(custId) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/order/total/count/' + custId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to extract promo code details from DB
   * 
   * @param couponcodeid 
   * @returns 
   */
  getPromoCodeDetails(couponcodeid) {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/promocode/info/get/' + couponcodeid;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to extract all available promo code if the code is active
   * and not expired
   * 
   * @returns 
   */
  getListOfActivePromoCode() {
    let url = environment.appUrl + environment.apiURI + '/ordersmgmt/customer/promocode/active/list';
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to call Cashfree payment gateway Create Order API
   * and create the order, which in response returning back the 
   * @param orderdata 
   * @returns 
   */
  makePaymentUsingCashfree(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/paymentsmgmt/customer/cashfree/payments';

    let body = JSON.stringify(orderdata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
