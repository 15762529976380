import { NgModule } from '@angular/core';
import SharedModule from '../shared.module';
import GeneralNavModule from '../modules/general-nav.module';

import { AboutUsPageRoutingModule } from './about-us-page-routing.module';
import { AboutUsPageComponent } from './about-us-page.component';
import { AboutusMainInfoComponent } from '../general-component/aboutus-main-info/aboutus-main-info.component';

@NgModule({
    declarations: [
        AboutUsPageComponent,
        AboutusMainInfoComponent],
    imports: [
        SharedModule,
        AboutUsPageRoutingModule,
        GeneralNavModule
    ],
    providers: []
})
export class AboutUsPageModule { }