import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-customer-account-right-nav',
  templateUrl: './customer-account-right-nav.component.html',
  styleUrls: ['./customer-account-right-nav.component.css']
})
export class CustomerAccountRightNavComponent implements OnInit {

  constructor(
    private routerService: RouterService
  ) { }

  ngOnInit() {
  }

  refreshMyOrder() {
    //window.location.replace('/myorders');
    this.routerService.routeToMyOrders();
  }

  refreshMyProfile() {
    //window.location.replace('/myprofile');
    this.routerService.routeToMyProfile();
  }

  refreshMyAddress() {
    //window.location.replace('/myaddresses');
    this.routerService.routeToMyAddress();
  }

  refreshWishList() {
    //window.location.replace('/wishlistview');
    this.routerService.routeToMyWishList();
  }

}
