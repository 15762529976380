import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ProductService } from '../../services/product.service';
import { ModalService } from '../../modal-window/modal.service';
import { CustomerService } from '../../services/customer.service';
import { AuthenticationService } from '../../services/authentication.service';
import { RouterService } from '../../services/router.service';
import { UtilityService } from '../../services/utility.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';
// import { DataSharedService } from '../../services/datashared.service';

@Component({
  selector: 'app-product-search-view',
  templateUrl: './product-search-view.component.html',
  styleUrls: ['./product-search-view.component.css']
})
export class ProductSearchViewComponent implements OnInit, OnDestroy {

  s_criteria: string; // Sort Criteria
  sorttype: string | undefined;   // Sort Type
  fp: string | undefined;         // Filter Price
  fcr: string | undefined;       // Filter Customer Rating
  displaymode: string; // To define which service to execute - generic search or category search of products

  searchproducts: any;
  cartresponse: any;
  submitMessage: string;
  wishlistresponse: any;
  message: string;
  hideSuccessMessage: boolean = true;

  displaycategoryname: string;

  productDisplay: Subscription;
  productAddCart: Subscription;
  wishlistadd: Subscription;
  productlist: Subscription;
  similarproductssub: Subscription;

  constructor(
    private modalService: ModalService,
    private router: ActivatedRoute,
    private routerService: RouterService,
    private authService: AuthenticationService,
    private customerService: CustomerService,
    private productService: ProductService,
    private utilityService: UtilityService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService
    // private dataSharedService: DataSharedService,
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.router.queryParams.subscribe(
      params => {
        this.s_criteria = params.w;
        this.sorttype = params.stype;
        this.fp = params.fp;
        this.fcr = params.fcr;
        this.displaymode = params.mode
      }
    );
    if (this.fp == undefined) {
      this.fp = 'NA';
    }
    if (this.fcr == undefined) {
      this.fcr = 'NA';
    }
    if (this.sorttype == undefined) {
      this.sorttype = 'NA';
    }

    if (this.s_criteria == "lamp") {
      this.displaycategoryname = "Lamps";
    } else if (this.s_criteria == "hogla") {
      this.displaycategoryname = "Hogla Beauty";
    } else if (this.s_criteria == "wallhang") {
      this.displaycategoryname = "Wall Hanging";
    } else if (this.s_criteria == "tdecor") {
      this.displaycategoryname = "Table Decor";
    } else if (this.s_criteria == "bag") {
      this.displaycategoryname = "Bags";
    } else if (this.s_criteria == "idol") {
      this.displaycategoryname = "Idols & Figures";
    } else if (this.s_criteria == "furniture") {
      this.displaycategoryname = "Furniture";
    } else if (this.s_criteria == "ktchn") {
      this.displaycategoryname = "Kitchen Decor";
    } else if (this.s_criteria == "pf") {
      this.displaycategoryname = "Photo Frame";
    } else if (this.s_criteria == "mask") {
      this.displaycategoryname = "Mask";
    }
    else if (this.s_criteria == "tray") {
      this.displaycategoryname = "Tray";
    }

    else if (this.s_criteria == "Wood") {
      this.displaycategoryname = "Wooden Crafts";
    }
    else if (this.s_criteria == "Ceramic") {
      this.displaycategoryname = "Ceramic Creation";
    }
    else if (this.s_criteria == "Bamboo") {
      this.displaycategoryname = "Bamboo Art";
    }
    else if (this.s_criteria == "Jute") {
      this.displaycategoryname = "Jute Handicrafts";
    }
    else if (this.s_criteria == "Pottery") {
      this.displaycategoryname = "Clay Work";
    }
    else if (this.s_criteria == "Brass") {
      this.displaycategoryname = "Dokra Decor";
    }
    else if (this.s_criteria == "oxidized") {
      this.displaycategoryname = "Oxidized Jewellery";
    }
    else if (this.s_criteria == "beads") {
      this.displaycategoryname = "Beads Jewellery";
    }
    else if (this.s_criteria == "bamboo") {
      this.displaycategoryname = "Bamboo Jewellery";
    }
    else if (this.s_criteria == "SabaiGrass") {
      this.displaycategoryname = "Sabai Grass Creation";
    }
    else if (this.s_criteria == "etray") {
      this.displaycategoryname = "Elegant Trays";
    }
    else if (this.s_criteria == "organizer") {
      this.displaycategoryname = "Oraganizer";
    }
    else if (this.s_criteria == "hp") { 
      this.displaycategoryname = "Hand Pankha";
    }
    else {
      this.displaycategoryname = "Collections";
    }

    // Check whether the request came from category level or generic search level
    if (this.displaymode == 'srch') {
      this.productDisplay = this.productService.getGenericProductFilterSearch(this.s_criteria, this.sorttype, this.fp, this.fcr)
        .subscribe(
          response => {
            response = this.searchproducts = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.searchproducts['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            }
            // if ((this.searchproducts.length != null) && (this.searchproducts.length != '')) {
            //   this.dataSharedService.changeMessage("Yes");
            // } else {
            //   this.dataSharedService.changeMessage("No");
            // }
          });
    } else if (this.displaymode == 'catgry') {
      let cartdata = {
        "category_name": this.s_criteria,
        "display_status": "NA"
      }
      this.productlist = this.utilityService.getAllProductsByCategory(cartdata, this.sorttype, this.fp, this.fcr)
        .subscribe(
          response => {
            response = this.searchproducts = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.searchproducts['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            }
          });
    } else if (this.displaymode == 'menu') {
      let cartdata = {
        "category_name": "NA",
        "display_status": this.s_criteria
      }
      this.productlist = this.utilityService.getAllProductsByCategory(cartdata, this.sorttype, this.fp, this.fcr)
        .subscribe(
          response => {
            response = this.searchproducts = response;

            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.searchproducts['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            }
          });
    } else { //If user tamper the displaymode value
      let cartdata = {
        "category_name": this.s_criteria,
        "display_status": "NA"
      }
      this.productlist = this.utilityService.getAllProductsByCategory(cartdata, this.sorttype, this.fp, this.fcr)
        .subscribe(
          response => {
            response = this.searchproducts = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.searchproducts['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            }
          });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  addToCart(pid: number, price: number) {
    let cartdata = {
      "cart_items_details": [
        {
          "product_id": pid,
          "quantity": 1,
          "price": price,
          "item_size": ""
        }
      ]
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.productAddCart = this.customerService.addToCart(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.cartresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.cartresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              this.routerService.routeToShoppingCart();
            }
          });
    } else {
      localStorage.setItem("cartdata", JSON.stringify(cartdata));
      this.routerService.routeToShoppingCart();
    }

  }

  addToWishlist(pid: number) {

    /**
       * For Google Analytics to track add-to-wishlist event
       */

    this
      .googleAnalyticsService
      .eventEmitter("add_to_wishlist", "shop", "wishlist", "click", 9);

    /**
     * ***********************************************
     */

    let cartdata = {
      "wishlist_items_details": [
        {
          "product_id": pid,
          "item_size": ""
        }
      ]
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      localStorage.removeItem("wishlistdata");
      this.wishlistadd = this.customerService.addToWishList(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.wishlistresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.wishlistresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              this.hideSuccessMessage = false;
            }
          });
    } else {
      localStorage.setItem("wishlistdata", JSON.stringify(cartdata));
      this.routerService.routeToWishList();
    }
  }

  refreshProductSort(sitem: string, sorttype: string) {
    //window.location.replace('/search?w=' + sitem + '&mode=' + this.displaymode + '&stype=' + sorttype + '&fcr=' + this.fcr + '&fp=' + this.fp);
    this.routerService.routeToSearchWith5Arg(sitem, this.displaymode, sorttype, this.fp, this.fcr);
  }

  FadeOutSuccessMsg() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.productDisplay) {
      this.productDisplay.unsubscribe();
    }
    if (this.productAddCart) {
      this.productAddCart.unsubscribe();
    }
    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
    if (this.productlist) {
      this.productlist.unsubscribe();
    }
  }

}
