import { NgModule } from '@angular/core';

import { PageNotFoundRoutingModule } from './page-note-found-routing.module';
import { PageNotFoundComponent } from './page-not-found.component';

import SharedModule from '../shared.module';

@NgModule({
    declarations: [
        PageNotFoundComponent
    ],
    imports: [
        SharedModule,
        PageNotFoundRoutingModule
    ],
    providers: []
})
export class PageNotFoundModule { }