import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, FormGroupDirective, Validators, FormGroup } from '@angular/forms';

import { RouterService } from '../../services/router.service';
import { OrderService } from '../../services/order.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { Utility } from '../../common/utility';

import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-return-replace-order-info',
  templateUrl: './return-replace-order-info.component.html',
  styleUrls: ['./return-replace-order-info.component.css']
})
export class ReturnReplaceOrderInfoComponent implements OnInit, OnDestroy {

  orderid: string;
  itemid: string;
  message: string;
  isReplaceRefund: boolean;
  orderstatusresponse: any;
  orderreturnresponse: any;
  rrsnsresponse: any;
  submitted = false;

  rrOrderSub: Subscription;
  returnOrderSub: Subscription;
  sendSNSSub: Subscription;

  constructor(
    private router: ActivatedRoute,
    private routerService: RouterService,
    private orderService: OrderService,
    private authService: AuthenticationService,
    private encryptService: EncrDecrService
  ) { }

  reason_descr = new FormControl('', Validators.required);
  description = new FormControl('', Validators.required);

  returnReplaceForm = new FormGroup({
    reason_descr: this.reason_descr,
    description: this.description
  });

  // convenience getter for easy access to form fields
  get f() { return this.returnReplaceForm.controls; }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.router.queryParams.subscribe(
      params => {
        this.orderid = params.orderid;
        this.itemid = params.itemid;
      }
    );
    try {
      var order_id = decodeURIComponent(this.orderid.toString())
      this.orderid = this.encryptService.get(Utility.secretKey, order_id);
      if (this.orderid == ''){
        this.routerService.routeToTryAgainPage();
      }

      var item_id = decodeURIComponent(this.itemid.toString())
      this.itemid = this.encryptService.get(Utility.secretKey, item_id);
      if (this.itemid == ''){
        this.routerService.routeToTryAgainPage();
      }
    } catch (error) {
      this.routerService.routeToTryAgainPage();
    }

    this.checkOrderStatus(this.orderid);

  }

  onSubmit() {
    this.submitted = true;
    var useremail: string = localStorage.getItem('user');

    // Return if form is invalid
    if (this.returnReplaceForm.invalid) {
      return;
    }

    let orderdata = {
      "order_id": this.orderid,
      "order_item_id": this.itemid,
      "return_replace_reason": this.f.reason_descr.value,
      "return_replace_reason_descr": this.f.description.value,
      "useremail": useremail
    }
    // Update item status and reason for return or replace
    this.returnOrderSub = this.orderService.returnReplaceOrderItem(orderdata)
      .subscribe(
        data => {
          data = this.orderreturnresponse = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.orderreturnresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            // Send all data to SNS for actual processing for back office work
            this.sendSNSSub = this.orderService.sendReturnReplaceOrderInfoToSNS(orderdata)
              .subscribe(
                response => {
                  response = this.rrsnsresponse = response;
                  // IF block will execute only when the corresponding service is down or not
                  // getting any response
                  if (this.rrsnsresponse['message'] == 'DOWN') {
                    this.routerService.routeToTryAgainPage();
                  } else {
                    this.routerService.routeToReturnReplaceConfirmPage();
                  }
                });
          }
        });
  }

  checkOrderStatus(orderId: string) {
    return this.rrOrderSub = this.orderService.getOrderCurrentRRStatus(orderId)
      .subscribe(
        data => {
          data = this.orderstatusresponse = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.orderstatusresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.isReplaceRefund = this.orderstatusresponse.orderreturnstatus[0].is_eligible_for_return;
            if (!this.isReplaceRefund) {
              this.message = "We have observed that your order is not eligible for replacement " +
                "because of time limit. Feel free to contact us for any queries or concern at 'info@khoaihaat.com'."
            }
          }
        });
  }

  viewOrderDetails(orderid: string, orderitemid: string) {

    var encryptorderid = this.encryptService.set(Utility.secretKey, orderid);
    var encryptordervalue = encodeURIComponent(encryptorderid);

    var encryptorderitem = this.encryptService.set(Utility.secretKey, orderitemid);
    var encryptorderitemvalue = encodeURIComponent(encryptorderitem);

    this.routerService.routeToOrderDetails(encryptordervalue, encryptorderitemvalue);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.rrOrderSub) {
      this.rrOrderSub.unsubscribe();
    }
    if (this.returnOrderSub) {
      this.returnOrderSub.unsubscribe();
    }
    if (this.sendSNSSub) {
      this.sendSNSSub.unsubscribe();
    }
  }

}
