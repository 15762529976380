import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductService } from '../../services/product.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { Utility } from '../../common/utility';
import { AuthenticationService } from '../../services/authentication.service';
import { RouterService } from '../../services/router.service';
import { ProductImage } from '../../models/product.model';

declare var $: any

@Component({
  selector: 'app-productreview-customer-direct',
  templateUrl: './productreview-customer-direct.component.html',
  styleUrls: ['./productreview-customer-direct.component.css']
})
export class ProductreviewCustomerDirectComponent implements OnInit, OnDestroy {

  startcount: string;
  submitMessage: string;
  submitted = false;
  productid: string;
  reviewdata: Subscription;
  reviewresponse: string;
  isResponse = false;

  productinfosub: Subscription;
  productinfodata: any;
  productname: string;
  imagedata: ProductImage;
  iconimage: string;

  customer_name = new FormControl('', Validators.required);
  review_title = new FormControl('', Validators.required);
  review_description = new FormControl('', Validators.required);
  rating = new FormControl('');

  reviewForm = new FormGroup({
    customer_name: this.customer_name,
    review_title: this.review_title,
    review_description: this.review_description,
    rating: this.rating
  });

  constructor(
    private authService: AuthenticationService,
    private productService: ProductService,
    private router: ActivatedRoute,
    private encryptService: EncrDecrService,
    private routerService: RouterService
  ) { }

  custId: string = this.authService.getCustomerId();

  // convenience getter for easy access to form fields
  get f() { return this.reviewForm.controls; }

  ngOnInit() {
    var $star_rating = $('.star-rating .fa');

    var SetRatingStar = function () {
      return $star_rating.each(function () {
        if (parseInt($star_rating.siblings('input.rating-value').val()) >= parseInt($(this).data('rating'))) {
          return $(this).removeClass('fa-star-o').addClass('fa-star');
        } else {
          return $(this).removeClass('fa-star').addClass('fa-star-o');
        }
      });
    };

    $star_rating.on('click', function () {
      $star_rating.siblings('input.rating-value').val($(this).data('rating'));
      return SetRatingStar();
    });

    SetRatingStar();
    $(document).ready(function () {

    });

    this.router.queryParams
      .filter(params => params.pid)
      .subscribe(params => {
        this.productid = params.pid;
      });
    try {
      var p_id = decodeURIComponent(this.productid.toString())
      this.productid = this.encryptService.get(Utility.secretKey, p_id);
      if (this.productid.toString() == '') {
        this.routerService.routeToTryAgainPage();
      } else {
        this.getProductInfoByPID(this.productid);
      }
    } catch (error) {
      this.routerService.routeToTryAgainPage();
    }

  }

  getValue(value) {
    this.startcount = value;
  }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.reviewForm.invalid) {
      return;
    }

    if (this.startcount == undefined) {
      this.startcount = "1";
    }
    let feedback = {
      "product_id": this.productid,
      "customer_id": this.custId,
      "customer_name": this.f.customer_name.value,
      "review_title": this.f.review_title.value,
      "review_description": this.f.review_description.value,
      "rating": this.startcount,
      "created_by": this.f.customer_name.value,
      "updated_by": this.f.customer_name.value
    }

    this.reviewdata = this.productService.addReviewAndRating(feedback, this.productid)
      .subscribe(
        response => {
          response = this.reviewresponse = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.reviewresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.reviewForm.reset();
            this.isResponse = true;
            window.scroll(0, 0);
          }
        }
      );
  }

  getProductInfoByPID(pid: any) {
    this.productinfosub = this.productService.getProductDetailsById(pid)
      .subscribe(
        response => {
          response = this.productinfodata = response;
          if (this.productinfodata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.productname = this.productinfodata['product_name'];
            this.imagedata = this.productinfodata.productimages;
            this.iconimage = this.imagedata[0].icon_image_path;
          }
        });
  }

  toggleReturnHome() {
    this.reviewForm.reset();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.reviewdata) {
      this.reviewdata.unsubscribe();
    }
    if (this.productinfosub) {
      this.productinfosub.unsubscribe();
    }
  }

}
