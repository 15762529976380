import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";

import { GeneralNavInfoComponent } from '../general-component/general-nav-info/general-nav-info.component';

@NgModule({
    imports: [CommonModule,
        ReactiveFormsModule],
    declarations: [
        GeneralNavInfoComponent
    ],
    exports: [
        GeneralNavInfoComponent,
        ReactiveFormsModule,
        CommonModule
    ]
})
export default class GeneralNavModule {}