import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { ModalService } from '../../modal-window/modal.service';
import { ProductService } from '../../services/product.service';
import { CustomerService } from '../../services/customer.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-new-product',
  templateUrl: './home-new-product.component.html',
  styleUrls: ['./home-new-product.component.css']
})
export class HomeNewProductComponent implements OnInit, OnDestroy {
  spclcategory: string;
  displayPage: string;
  products: any[];
  cartresponse: any;
  wishlistresponse: any;
  submitMessage: string;
  wishlistaddmessage: string;
  hideSuccessMessage: boolean = true;
  homeNew: Subscription;
  homeNewAddCart: Subscription;
  wishlistadd: Subscription;

  constructor(
    private modalService: ModalService,
    private router: RouterService,
    private authService: AuthenticationService,
    private customerService: CustomerService,
    private productService: ProductService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.spclcategory = "NEW"; //Will extract all products marked as NEW
    this.displayPage = "HOME" //Will display in home page
    // this.homeNew = this.productService.getAllHomeProductListBySpecialCategory(this.spclcategory, this.displayPage)
    //   .subscribe(
    //     response => {
    //       response = this.products = response;
    //       // IF block will execute only when the corresponding service is down or not
    //       // getting any response
    //       if (this.products['message'] == 'DOWN') {
    //         this.router.routeToTryAgainPage();
    //       }
    //     });
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  viewAllProductList(dstatus: string) {
    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", dstatus, "click", 3);

    this.router.routeToProductsListDisplay(dstatus);
  }

  addToCart(pid: number, price: number) {
    let cartdata = {
      "cart_items_details": [
        {
          "product_id": pid,
          "quantity": 1,
          "price": price,
          "item_size": ""
        }
      ]
    }

    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      localStorage.removeItem("cartdata");
      this.homeNewAddCart = this.customerService.addToCart(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.cartresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.cartresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.router.routeToShoppingCart();
            }
          });
    } else {
      localStorage.setItem("cartdata", JSON.stringify(cartdata));
      this.router.routeToShoppingCart();
    }

  }

  addToWishlist(pid: number) {

    /**
       * For Google Analytics to track add-to-wishlist event
       */

    this
      .googleAnalyticsService
      .eventEmitter("add_to_wishlist", "shop", "wishlist", "click", 9);

    /**
     * ***********************************************
     */

    let cartdata = {
      "wishlist_items_details": [
        {
          "product_id": pid,
          "item_size": ""
        }
      ]
    }

    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.wishlistadd = this.customerService.addToWishList(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.wishlistresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.wishlistresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.hideSuccessMessage = false;
            }
          });
    } else {
      localStorage.setItem("wishlistdata", JSON.stringify(cartdata));
      this.router.routeToWishList();
    }
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.homeNew) {
      this.homeNew.unsubscribe();
    }

    if (this.homeNewAddCart) {
      this.homeNewAddCart.unsubscribe();
    }

    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
  }
}
