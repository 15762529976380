import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentSuccessReturnPageRoutingModule } from './payment-sucess-return-page-routing.module';
import { PaymentSuccessReturnPageComponent } from './payment-success-return-page.component';

import SharedModule from '../shared.module';
import HomeSpecificModule from '../modules/home-specific.module';

@NgModule({
    declarations: [PaymentSuccessReturnPageComponent],
    imports: [
        SharedModule,
        HomeSpecificModule,
        PaymentSuccessReturnPageRoutingModule
    ],
    providers: []
})
export class PaymentSuccessReturnPageModule { }