import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { throwError } from 'rxjs';
import { AuthService } from 'angular4-social-login';
import { RouterService } from '../services/router.service';

import { User } from '../models/user.model';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private socialAuthService: AuthService,
    private routerService: RouterService,) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  loginUser(customeremail: string, password: string) {
    let userdata = {
      email: customeremail,
      password: password
    }
    return this.http.post<any>(environment.appUrl + environment.apiURI + '/customer/login', userdata)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem("user", customeremail);

        var token: string = localStorage.getItem('currentUser');
        var decoded = jwt_decode(token);
        var userid: string = decoded['jti'];

        localStorage.setItem("userid", userid);

        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var loginType: string = localStorage.getItem('login_type');
    var token = currentUser.accessToken;
    var socialtoken = localStorage.getItem('socialtoken');

    localStorage.clear();

    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem("user");
    localStorage.removeItem("userid");
    localStorage.removeItem("product_id");
    localStorage.removeItem('s_address_id');
    localStorage.removeItem('f_name');
    localStorage.removeItem('l_name');
    localStorage.removeItem('p_number');
    localStorage.removeItem('sessionid');
    localStorage.removeItem('login_type');
    localStorage.removeItem('socialtoken');
    localStorage.removeItem('itemsizeforbuynow');

    this.currentUserSubject.next(null);

    let header = new HttpHeaders({
      'Authorization': token
    });

    return this.http.post<any>(environment.appUrl + environment.apiURI + '/customer/logout', null, {
      headers: header
    }).subscribe(
      data => {
        if (loginType == "SOCIAL") {
          this.signOut();
          this.routerService.routeToHome();
        } else {
          this.routerService.routeToLogin();
        }
      }
    );
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  getCustomer() {
    return localStorage.getItem('user');
  }

  getCustomerId() {
    return localStorage.getItem('userid');
  }

  getToken() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var token = currentUser.accessToken;
    return token;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
