import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { GridPageComponent } from './grid-page.component';

const routes1: Routes = [
    {
        path: "",
        component: GridPageComponent
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes1)]
})
export class GridPageRoutingModule {}