import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HomePageComponent } from './home-page/home-page.component';
//import { GridPageComponent } from './grid-page/grid-page.component';
//import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//import { HeaderComponent } from './header/header.component';
//import { FooterComponent } from './footer/footer.component';
//import { NavigationTopComponent } from './navigation-top/navigation-top.component';
//import { NavigationMobileComponent } from './navigation-mobile/navigation-mobile.component';
//import { HomeNewProductComponent } from './home-component/home-new-product/home-new-product.component';
//import { HomeBestSellersComponent } from './home-component/home-best-sellers/home-best-sellers.component';
//import { HomeFeaturedSliderComponent } from './home-component/home-featured-slider/home-featured-slider.component';
//import { HomeSpecialProductComponent } from './home-component/home-special-product/home-special-product.component';
//import { HomeBannerBottomComponent } from './home-component/home-banner-bottom/home-banner-bottom.component';
//import { HomeBlogComponent } from './home-component/home-blog/home-blog.component';
//import { HomeOurFeaturesComponent } from './home-component/home-our-features/home-our-features.component';
//import { HomeMainSliderComponent } from './home-component/home-main-slider/home-main-slider.component';
//import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
//import { BoxMenuLeftCategoryComponent } from './grid-list-component/box-menu-left-category/box-menu-left-category.component';
//import { BoxShopByOptionsComponent } from './grid-list-component/box-shop-by-options/box-shop-by-options.component';
//import { GridDisplayProductComponent } from './grid-list-component/grid-display-product/grid-display-product.component';
//import { PromotionalLeftSliderComponent } from './grid-list-component/promotional-left-slider/promotional-left-slider.component';
//import { ListPageComponent } from './list-page/list-page.component';
//import { ListDisplayProductComponent } from './grid-list-component/list-display-product/list-display-product.component';
//import { ProductDetailsPageComponent } from './product-details-page/product-details-page.component';
//import { ProductSimilarItemComponent } from './product-component/product-similar-item/product-similar-item.component';
//import { ProductUpsellItemComponent } from './product-component/product-upsell-item/product-upsell-item.component';
//import { ProductInfoDetailsComponent } from './product-component/product-info-details/product-info-details.component';
//import { ShoppingCartPageComponent } from './shopping-cart-page/shopping-cart-page.component';
//import { ProductInterestedComponent } from './product-component/product-interested/product-interested.component';
//import { ProductCartDetailsComponent } from './product-component/product-cart-details/product-cart-details.component';
//import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
//import { CheckoutMainInfoComponent } from './order-component/checkout-main-info/checkout-main-info.component';
//import { CheckoutRightsideInfoComponent } from './order-component/checkout-rightside-info/checkout-rightside-info.component';
//import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';
//import { CustomerAccountInfoComponent } from './customer-component/customer-account-info/customer-account-info.component';
//import { CustomerWishlistInfoComponent } from './customer-component/customer-wishlist-info/customer-wishlist-info.component';
//import { CustomerDashboardPageComponent } from './customer-dashboard-page/customer-dashboard-page.component';
//import { CustomerDashboardInfoComponent } from './customer-component/customer-dashboard-info/customer-dashboard-info.component';
//import { CustomerAccountRightNavComponent } from './customer-component/customer-account-right-nav/customer-account-right-nav.component';
//import { ProductComparePageComponent } from './product-compare-page/product-compare-page.component';
//import { ProductCompareInfoComponent } from './product-component/product-compare-info/product-compare-info.component';
//import { BlogPageComponent } from './blog-page/blog-page.component';
//import { BlogListInfoComponent } from './blog-component/blog-list-info/blog-list-info.component';
//import { BlogPopularListComponent } from './blog-component/blog-popular-list/blog-popular-list.component';
//import { BlogDetailPageComponent } from './blog-detail-page/blog-detail-page.component';
//import { BlogDetailInfoComponent } from './blog-component/blog-detail-info/blog-detail-info.component';
//import { BlogCommentFormComponent } from './blog-component/blog-comment-form/blog-comment-form.component';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from './modal-window/modal.module';
//import { QuickviewPopupPageComponent } from './quickview-popup-page/quickview-popup-page.component';

import { AppErrorHandler } from './common/app-error-handler';
//import { LoginPageComponent } from './login-page/login-page.component';
//import { LoginMainInfoComponent } from './auth-component/login-main-info/login-main-info.component';
//import { RegisterInfoPopupComponent } from './auth-component/register-info-popup/register-info-popup.component';
//import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
//import { ContactusFormInfoComponent } from './general-component/contactus-form-info/contactus-form-info.component';
//import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
//import { AboutusMainInfoComponent } from './general-component/aboutus-main-info/aboutus-main-info.component';
//import { GeneralNavInfoComponent } from './general-component/general-nav-info/general-nav-info.component';
//import { ForgetPasswordPopupComponent } from './auth-component/forget-password-popup/forget-password-popup.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { AuthenticationService } from './services/authentication.service';
import { CustomerService } from './services/customer.service';
import { RouterService } from './services/router.service';
import { DataSharedService } from './services/datashared.service';
import { OrderService } from './services/order.service';
import { UtilityService } from './services/utility.service';
import { PaymentService } from './services/payment.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
//import { HomeBannerMiddleComponent } from './home-component/home-banner-middle/home-banner-middle.component';
//import { SiteSliderTesterComponent } from './site-slider-tester/site-slider-tester.component';
//import { HeaderCustomComponent } from './header-custom/header-custom.component';
//import { MyOrdersPageComponent } from './my-orders-page/my-orders-page.component';
//import { MyProfilePageComponent } from './my-profile-page/my-profile-page.component';
//import { MyAddressesPageComponent } from './my-addresses-page/my-addresses-page.component';
//import { MyProductreviewPageComponent } from './my-productreview-page/my-productreview-page.component';
//import { MyOrdersInfoComponent } from './customer-component/my-orders-info/my-orders-info.component';
//import { MyProfileInfoComponent } from './customer-component/my-profile-info/my-profile-info.component';
//import { MyAddressInfoComponent } from './customer-component/my-address-info/my-address-info.component';
//import { MyProductreviewInfoComponent } from './customer-component/my-productreview-info/my-productreview-info.component';
//import { MyOrderdetailsPageComponent } from './my-orderdetails-page/my-orderdetails-page.component';
//import { MyOrderdetailsInfoComponent } from './customer-component/my-orderdetails-info/my-orderdetails-info.component';
import { DatePipe } from '@angular/common';
//import { ProductSearchPageComponent } from './product-search-page/product-search-page.component';
//import { ProductSearchViewComponent } from './product-component/product-search-view/product-search-view.component';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, AuthService } from 'angular4-social-login';
//import { PaymentSuccessReturnPageComponent } from './payment-success-return-page/payment-success-return-page.component';
//import { PaymentNotifyPageComponent } from './payment-notify-page/payment-notify-page.component';
//import { ChangePasswordPopupComponent } from './customer-component/change-password-popup/change-password-popup.component';
import { SharedService } from './services/shared.service';
import { EncrDecrService } from './services/encrdecr.service';
//import { UnknownErrorPageComponent } from './unknown-error-page/unknown-error-page.component';
//import { CancelOrderPageComponent } from './cancel-order-page/cancel-order-page.component';
//import { CancelOrderInfoComponent } from './customer-component/cancel-order-info/cancel-order-info.component';
//import { ReturnReplaceOrderPageComponent } from './return-replace-order-page/return-replace-order-page.component';
//import { ReturnReplaceOrderInfoComponent } from './customer-component/return-replace-order-info/return-replace-order-info.component';
//import { RefundReplaceConfirmInfoComponent } from './customer-component/refund-replace-confirm-info/refund-replace-confirm-info.component';
//import { RefundReplaceConfirmPageComponent } from './refund-replace-confirm-page/refund-replace-confirm-page.component';
//import { HeaderErrorCustomComponent } from './header-error-custom/header-error-custom.component';
//import { ReturnPolicyPageComponent } from './information/return-policy-page/return-policy-page.component';
//import { TermsOfUsePageComponent } from './information/terms-of-use-page/terms-of-use-page.component';
//import { PrivacyPageComponent } from './information/privacy-page/privacy-page.component';
//import { PurchaseProtectionPageComponent } from './information/purchase-protection-page/purchase-protection-page.component';
//import { FaqPageComponent } from './information/faq-page/faq-page.component';
//import { SitemapPageComponent } from './information/sitemap-page/sitemap-page.component';
//import { MyLoaderComponent } from './components/my-loader/my-loader.component';
//import { ProductreviewCustomerDirectComponent } from './customer-component/productreview-customer-direct/productreview-customer-direct.component';
//import { ProductreviewDirectPageComponent } from './productreview-direct-page/productreview-direct-page.component';

import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './helpers/loader-interceptor.service';
import { CustomerInvoiceComponent } from './customer-component/customer-invoice/customer-invoice.component';
//import { HomeBottomSliderComponent } from './home-component/home-bottom-slider/home-bottom-slider.component';
//import { HomeReoneProductComponent } from './home-component/home-reone-product/home-reone-product.component';

import SharedModule from './shared.module';
//import { LoginPageModule } from './login-page/login-page.module';
//import HomeSpecificModule from './modules/home-specific.module';
//import { PaymentSuccessReturnPageModule } from './payment-success-return-page/payment-success-return-page.module';

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('1670751383085172')
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('149373601005-r64gk7uk701tf6ughbd4s8mk7h6lr1gk.apps.googleusercontent.com')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutPageComponent,
    CustomerInvoiceComponent
  ],
  imports: [
    BrowserModule,
    ModalModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    AuthenticationService,
    RouterService,
    CustomerService,
    OrderService,
    UtilityService,
    DatePipe,
    DataSharedService,
    SharedService,
    EncrDecrService,
    SocialLoginModule,
    AuthService,
    LoaderService,
    PaymentService,
    GoogleAnalyticsService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: AuthServiceConfig, useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
