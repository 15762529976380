import { NgModule } from '@angular/core';

import { TrackOrdersPageRoutingModule } from './track-orders-page-routing.module';
import { TrackOrdersPageComponent } from './track-orders-page.component';
import { TrackOrdersInfoComponent } from '../general-component/track-orders-info/track-orders-info.component';

import SharedModule from '../shared.module';
import GeneralNavModule from '../modules/general-nav.module';

@NgModule({
    declarations: [
        TrackOrdersPageComponent,
        TrackOrdersInfoComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        TrackOrdersPageRoutingModule
    ],
    providers: []
})
export class TrackOrdersPageModule { }