import { Component, OnInit } from '@angular/core';

declare var $: any

@Component({
  selector: 'app-return-policy-page',
  templateUrl: './return-policy-page.component.html',
  styleUrls: ['./return-policy-page.component.css']
})
export class ReturnPolicyPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(document).ready(function ($) {
      $("#mobile-menu").mobileMenu({
        MenuWidth: 250,
        SlideSpeed: 300,
        WindowsMaxWidth: 767,
        PagePush: true,
        FromLeft: true,
        Overlay: true,
        CollapseMenu: true,
        ClassName: "mobile-menu"
      });
    });
    
  }

}
