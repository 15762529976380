import { Component, ViewChild, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, FormGroupDirective, Validators, FormGroup } from '@angular/forms';

import { ModalService } from '../../modal-window/modal.service';
import { RouterService } from '../../services/router.service';
import { CustomerService } from '../../services/customer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forget-password-popup',
  templateUrl: './forget-password-popup.component.html',
  styleUrls: ['./forget-password-popup.component.css']
})
export class ForgetPasswordPopupComponent implements OnInit, OnDestroy {

  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  customeremail = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);

  resetpasswordsub: Subscription;
  submitMessage: string;
  isSuccess: boolean = false;

  resetPassForm = new FormGroup({
    customeremail: this.customeremail
  });
  submitted = false;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private routerService: RouterService,
    private modalService: ModalService,
    private userService: CustomerService) { }

  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPassForm.controls; }

  resetPassword() {
    this.submitted = true;

    // Return if form is invalid
    if (this.resetPassForm.invalid) {
      return;
    } else {
      this.closeModal('forget-password-modal');
    }
    let userdata = {
      "customer_email": this.f.customeremail.value
    }
    this.resetpasswordsub = this.userService.resetPassword(userdata)
      .subscribe(
        data => {
          this.routerService.routeToLogin();
        },
        error => {
          this.resetPassForm.reset();
          this.formGroupDirective.resetForm();
        });

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.resetpasswordsub) {
      this.resetpasswordsub.unsubscribe();
    }
  }

}
