import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RouterService {

    constructor(
        private router: Router) { }

    routeToHome() {
        //window.location.replace('/homeview');
        this.router.navigate(['/homeview']);
    }

    routeToLogin() {
        this.router.navigate(['/login']);
    }

    routeToLogOut() {
        this.router.navigate(['/logout']);
    }

    routeToShoppingCart() {
        this.router.navigate(['/shoppingcartview'])
    }

    routeToWishList() {
        this.router.navigate(['/wishlistview']);
    }

    routePath(returnUrl: string) {
        if (returnUrl == "" || returnUrl == null) {
            //window.location.replace('/homeview');
            this.router.navigate(['/homeview']);
        } else {
            //window.location.replace(returnUrl);
            //this.router.navigate([returnUrl]);
            this.router.navigateByUrl(returnUrl);
        }
    }

    routeToProductsGridDisplay(spclcategory: string) {
        //window.location.replace('/gridview?dstatus=' + dStatus);
        this.router.navigate(['/gridview'], { queryParams: { spclcategory: spclcategory } });
    }

    routeToProductsGridShort(spclcategory: string, sorttype: string) {
        //window.location.replace('/gridview?dstatus=' + dStatus);
        this.router.navigate(['/gridview'], { queryParams: { spclcategory: spclcategory, stype: sorttype } });
    }

    routeToProductsListShort(spclcategory: string, sorttype: string) {
        //window.location.replace('/gridview?dstatus=' + dStatus);
        this.router.navigate(['/listview'], { queryParams: { spclcategory: spclcategory, stype: sorttype } });
    }

    routeToProductsListDisplay(spclcategory: string) {
        //window.location.replace('/listview?dstatus=' + dStatus);
        this.router.navigate(['/listview'], { queryParams: { spclcategory: spclcategory } });
    }

    routeToCheckOut(page: string) {
        this.router.navigate(['/checkout'], { queryParams: { page: page } });
    }

    routeToCheckOutWithoutLogin(page: string) {
        this.router.navigate(['/checkout']);
    }

    routeToMyOrders() {
        this.router.navigate(['/myorders']);
    }

    routeToMyProfile() {
        this.router.navigate(['/myprofile']);
    }

    routeToMyAddress() {
        this.router.navigate(['/myaddresses']);
    }

    routeToMyReview(pid: string) {
        this.router.navigate(['/myreviews'], { queryParams: { pid: pid } });
    }

    routeToMyWishList() {
        this.router.navigate(['/wishlistview']);
    }

    routeToSearch(categoryname: string, categorymode: string) {
        //this.router.navigate(['/search']);
        this.router.navigate(['/search'], { queryParams: { w: categoryname, mode: categorymode } });
    }

    routeToProductDetails(encryptedpid: string) {
        this.router.navigate(['/productview'], { queryParams: { pid: encryptedpid } });
    }

    routeToOrderDetails(encryptordervalue: string, encryptorderitemvalue: string) {
        //window.location.replace('/myorderdetails?orderid=' + encryptordervalue + '&itemid=' + encryptorderitemvalue);
        this.router.navigate(['/myorderdetails'], { queryParams: { orderid: encryptordervalue, itemid: encryptorderitemvalue } });
    }

    routeToTryAgainPage() {
        this.router.navigate(['/tryagain']);
    }

    routeToCancelOrderPage(encryptordervalue: string, encryptorderitemvalue: string) {
        this.router.navigate(['/cancelorder'], { queryParams: { orderid: encryptordervalue, itemid: encryptorderitemvalue } });
    }

    routeToReturnReplaceOrderPage(encryptordervalue: string, encryptorderitemvalue: string) {
        this.router.navigate(['/rrinitiateorder'], { queryParams: { orderid: encryptordervalue, itemid: encryptorderitemvalue } });
    }

    routeToReturnReplaceConfirmPage() {
        this.router.navigate(['/rrconfirm']);
    }

    routeToAboutUs() {
        this.router.navigate(['/aboutus']);
    }

    routeToContactUs() {
        this.router.navigate(['/contactus']);
    }

    routeToReplacementPolicy() {
        this.router.navigate(['/replacementpolicy']);
    }

    routeToTermsOfUse() {
        this.router.navigate(['/termsofuse']);
    }

    routeToPrivacy() {
        this.router.navigate(['/privacy']);
    }

    routeToShippPolicy() {
        this.router.navigate(['/shippingpolicy']);
    }

    routeToSiteMap() {
        this.router.navigate(['/sitemap']);
    }

    routeToSearchWith5Arg(sitem: string, mode: string, stype: string, fp: string, fcr: string) {
        //this.router.navigate(['/search']);
        this.router.navigate(['/search'], { queryParams: { w: sitem, mode: mode, stype: stype, fp: fp, fcr: fcr } });
    }

    routeToSuccessPageForCOD(ordermumber: string, status: string) {
        //this.router.navigate(['/response'], { queryParams: { num: ordermumber, status: status } });
        window.location.replace('/response?num=' + ordermumber + '&status=SUCCESS');
    }

    routeToTrackOrder() {
        this.router.navigate(['/trackorders']);
    }

    routeToBulkOrder() {
        this.router.navigate(['/bulkorders']);
    }

    routeToSellOnKhoaihaat() {
        this.router.navigate(['/sell']);
    }

    routeToSecondLevelNavPage() {
        //window.location.replace('/homeview');
        this.router.navigate(['/navpage']);
    }

}