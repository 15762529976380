import { NgModule } from '@angular/core';

import { ContactUsPageRoutingModule } from './contact-us-page-routing.module';
import { ContactUsPageComponent } from './contact-us-page.component';
import { ContactusFormInfoComponent } from '../general-component/contactus-form-info/contactus-form-info.component';

import SharedModule from '../shared.module';
import GeneralNavModule from '../modules/general-nav.module';

@NgModule({
    declarations: [
        ContactUsPageComponent,
        ContactusFormInfoComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        ContactUsPageRoutingModule
    ],
    providers: []
})
export class ContactUsPageModule { }