import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckoutPageRoutingModule } from './checkout-page-routing.module';
import { CheckoutPageComponent } from './checkout-page.component';
import { CheckoutMainInfoComponent } from '../order-component/checkout-main-info/checkout-main-info.component';

import SharedModule from '../shared.module';

@NgModule({
  declarations: [
    CheckoutPageComponent,
    CheckoutMainInfoComponent],
  imports: [
    SharedModule,
    CheckoutPageRoutingModule
  ],
  providers: []
})
export class CheckoutPageModule {}