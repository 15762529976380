import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { RouterService } from '../services/router.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    errorMessage: string;

    constructor(
        private routerService: RouterService,
        private authenticationService: AuthenticationService) { }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     return next.handle(request).pipe(retry(0),catchError(err => {
    //         if (err.status === 401) {
    //             // auto logout if 401 response returned from api
    //             this.authenticationService.logout();
    //             this.routerService.routeToLogin();
    //         }
    //         // const error = err.error.message || err.statusText;
    //         // return throwError(error.message);
    //         return throwError(err.statusText);
    //     }))
    // }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let handled: boolean = false;

        return next.handle(request)
            .pipe(
                retry(0),
                catchError((returnedError) => {
                    let errorMessage = null;

                    if (returnedError.error instanceof ErrorEvent) {
                        errorMessage = `Error: ${returnedError.error.message}`;
                    } else if (returnedError instanceof HttpErrorResponse) {
                        errorMessage = `Error Status ${returnedError.status}`;
                        handled = this.handleServerSideError(returnedError);
                    }

                    //console.error(errorMessage ? errorMessage : returnedError);

                    if (!handled) {
                        if (errorMessage) {
                            return throwError(errorMessage);
                        } else {
                            return throwError("Unexpected problem occurred");
                        }
                    } else {
                        return of(returnedError);
                    }
                })
            )
    }

    private handleServerSideError(error: HttpErrorResponse): boolean {
        let handled: boolean = false;

        switch (error.status) {
            case 401:
                //this.routeMessageService.message = "Please login again.";
                this.authenticationService.logout();
                handled = true;
                break;
            case 403:
                //this.routeMessageService.message = "Please login again.";
                this.authenticationService.logout();
                handled = true;
                break;
        }

        return handled;
    }
}