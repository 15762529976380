import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-box-shop-by-options',
  templateUrl: './box-shop-by-options.component.html',
  styleUrls: ['./box-shop-by-options.component.css']
})
export class BoxShopByOptionsComponent implements OnInit {

  s_criteria: string; // Sort Criteria
  sorttype: string | undefined;   // Sort Type
  fp: string | undefined;         // Filter Price
  fcr: string | undefined;       // Filter Customer Rating
  displaymode: string; // To define which service to execute - generic search or category search of products

  constructor(
    private router: ActivatedRoute,
    private routerService: RouterService
  ) { }

  ngOnInit() {
    this.router.queryParams.subscribe(
      params => {
        this.s_criteria = params.w;
        this.sorttype = params.stype;
        this.fp = params.fp;
        this.fcr = params.fcr;
        this.displaymode = params.mode;
      }
    );
  }

  refreshProductSortWithFilterPrice(sitem: string, filterprice: string) {
    if (this.fcr == undefined) {
      this.fcr = 'NA';
    }
    if (this.sorttype == undefined) {
      this.sorttype = 'NA';
    }
    //window.location.replace('/search?w=' + sitem + '&mode=' + this.displaymode + '&stype=' + this.sorttype + '&fp=' + filterprice + '&fcr=' + this.fcr);
    this.routerService.routeToSearchWith5Arg(sitem, this.displaymode, this.sorttype, filterprice, this.fcr);
  }

  refreshProductSortWithFilterRating(sitem: string, filterrating: string) {
    if (this.fp == undefined) {
      this.fp = 'NA';
    }
    if (this.sorttype == undefined) {
      this.sorttype = 'NA';
    }
    //window.location.replace('/search?w=' + sitem + '&mode=' + this.displaymode + '&stype=' + this.sorttype + '&fp=' + this.fp + '&fcr=' + filterrating);
    this.routerService.routeToSearchWith5Arg(sitem, this.displaymode, this.sorttype, this.fp, filterrating);
  }

  clearFilterCriteria() {
    //window.location.replace('/search?w=' + this.s_criteria + '&mode=' + this.displaymode);
    this.routerService.routeToSearch(this.s_criteria, this.displaymode);
  }

}
