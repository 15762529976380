import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import { CustomerService } from '../../services/customer.service';
import { ShippingAddress } from '../../models/checkout.model';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-my-address-info',
  templateUrl: './my-address-info.component.html',
  styleUrls: ['./my-address-info.component.css']
})
export class MyAddressInfoComponent implements OnInit, OnDestroy {

  shippingAddr: Subscription;
  addShippAddr: Subscription;
  updateAddr: Subscription;
  deleteAddr: Subscription;
  getAddr: Subscription;

  submitMessage: string;
  submitted = false;
  shippaddrdata: ShippingAddress[];
  shippaddr: ShippingAddress[];
  addshippaddrresponse: string;
  removeMessage: string;
  modifyshippaddrdata: ShippingAddress;

  isNewAddressShow = true;
  isNewAddressLinkShow = false;
  isAddressId: string;

  /**
   * For Add new address form parameters
   */

  firstName = new FormControl('', Validators.required);
  lastName = new FormControl('', Validators.required);
  streetAddr1 = new FormControl('', Validators.required);
  telePhone = new FormControl('', [Validators.required, Validators.maxLength(10)]);
  city_new = new FormControl('', Validators.required);
  shipp_landmarks = new FormControl('', Validators.required);
  postCode = new FormControl('', [Validators.required, Validators.maxLength(7)]);
  shipp_state = new FormControl('', Validators.required);
  shipp_country = new FormControl('', Validators.required);
  shipp_addr_id = new FormControl('');

  newAddressForm = new FormGroup({
    shipp_addr_id: this.shipp_addr_id,
    first_name: this.firstName,
    last_name: this.lastName,
    shipp_address1: this.streetAddr1,
    shipp_landmarks: this.shipp_landmarks,
    phone_number: this.telePhone,
    shipp_city: this.city_new,
    shipp_zip_code: this.postCode,
    shipp_state: this.shipp_state,
    shipp_country: this.shipp_country
  });


  constructor(
    private customerService: CustomerService,
    private authService: AuthenticationService,
    private routerService: RouterService
  ) { }

  custId: string = this.authService.getCustomerId();

  // convenience getter for easy access to form fields
  get f() { return this.newAddressForm.controls; }

  ngOnInit() {
    this.getAllShippingAddress();
  }

  getAllShippingAddress() {
    this.shippingAddr = this.customerService.getShippingAddressByCustomerId(this.custId)
      .subscribe(
        data => {
          this.shippaddrdata = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.shippaddrdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.shippaddr = this.shippaddrdata['shippingadress']
          }

        });
  }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.newAddressForm.invalid) {
      return;
    }
    this.isAddressId = this.f.shipp_addr_id.value;

    /**
     * Check the address id is blank or not, based on that the application will process the address data
     * as new or existing
     */
    if (this.isAddressId != null && this.isAddressId != '') {

      let addrdata = {
        "first_name": this.f.first_name.value,
        "last_name": this.f.last_name.value,
        "phone_number": this.f.phone_number.value,
        "shipp_address1": this.f.shipp_address1.value,
        "shipp_landmarks": this.f.shipp_landmarks.value,
        "shipp_city": this.f.shipp_city.value,
        "shipp_state": this.f.shipp_state.value,
        "shipp_zip_code": this.f.shipp_zip_code.value,
        "shipp_country": this.f.shipp_country.value,
        "is_active": "Y"
      }

      // Update the shipping address
      this.updateAddr = this.customerService.updateShippingAddressById(addrdata, this.isAddressId)
        .pipe(first())
        .subscribe(
          data => {
            this.addshippaddrresponse = data;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.addshippaddrresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              window.location.reload();
            }
          });

    } else {
      // Insert a new shipping address
      this.addShippAddr = this.customerService.addNewShippingAddress(this.newAddressForm.value, this.custId)
        .pipe(first())
        .subscribe(
          data => {
            this.addshippaddrresponse = data;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.addshippaddrresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              window.location.reload();
            }
          },
          error => {
            this.newAddressForm.reset();
          });
    }
  }

  toggleNewAddressDisplay() {
    this.isNewAddressShow = !this.isNewAddressShow;
    this.isNewAddressLinkShow = false;
    this.newAddressForm.reset();
  }

  toggleModifyAddress(shippaddrid) {
    window.scrollTo(0, 0);
    this.isNewAddressLinkShow = true;
    this.getAddr = this.customerService.getShippingAddressById(shippaddrid)
      .subscribe(
        data => {
          this.modifyshippaddrdata = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.modifyshippaddrdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.isNewAddressShow = false;
            this.newAddressForm.setValue(
              {
                shipp_addr_id: this.modifyshippaddrdata.shipp_addr_id,
                first_name: this.modifyshippaddrdata.first_name,
                last_name: this.modifyshippaddrdata.last_name,
                phone_number: this.modifyshippaddrdata.phone_number,
                shipp_address1: this.modifyshippaddrdata.shipp_address1,
                shipp_landmarks: this.modifyshippaddrdata.shipp_landmarks,
                shipp_city: this.modifyshippaddrdata.shipp_city,
                shipp_state: this.modifyshippaddrdata.shipp_state,
                shipp_zip_code: this.modifyshippaddrdata.shipp_zip_code,
                shipp_country: this.modifyshippaddrdata.shipp_country,
              })
          }

        });
  }

  toggleDeleteAddress(shippaddrid) {
    let data = {
      "is_active": "N"
    }
    this.deleteAddr = this.customerService.disableShippingAddress(shippaddrid, data)
      .subscribe(
        data => {
          data = this.removeMessage = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.removeMessage['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            window.location.reload();
          }
        });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.shippingAddr) {
      this.shippingAddr.unsubscribe();
    }
    if (this.deleteAddr) {
      this.deleteAddr.unsubscribe();
    }
    if (this.getAddr) {
      this.getAddr.unsubscribe();
    }
    if (this.updateAddr) {
      this.updateAddr.unsubscribe();
    }
  }

}
