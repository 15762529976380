import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-home-blog',
  templateUrl: './home-blog.component.html',
  styleUrls: ['./home-blog.component.css']
})
export class HomeBlogComponent implements OnInit {

  constructor(
    private routerService: RouterService
  ) { }

  ngOnInit() {
  }

  getProductList(categoryname: string) {
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.routerService.routeToSearch(categoryname, 'catgry');
  }

}
