import { NgModule } from '@angular/core';

import { ReturnPolicyPageRoutingModule } from './return-policy-page-routing.module';
import { ReturnPolicyPageComponent } from './return-policy-page.component';

import SharedModule from '../../shared.module';
import GeneralNavModule from '../../modules/general-nav.module';

@NgModule({
    declarations: [
        ReturnPolicyPageComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        ReturnPolicyPageRoutingModule
    ],
    providers: []
})
export class ReturnPolicyPageModule { }