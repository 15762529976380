import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { ProductService } from '../../services/product.service';
import { RouterService } from '../../services/router.service';
import { CartList } from '../../models/cart.model';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';

@Component({
  selector: 'app-product-cart-details',
  templateUrl: './product-cart-details.component.html',
  styleUrls: ['./product-cart-details.component.css']
})
export class ProductCartDetailsComponent implements OnInit, OnDestroy {

  submitMessage: string;
  cartlistdata: CartList[] = [];
  removeMessage: string;
  updateMessage: string;
  subtotalprice: number;
  grandtotalprice: number;
  shippingfee: number;
  count: number;
  pageName: string = "view";
  isCartEmpty: boolean = false;
  show: boolean = false;
  cartresponse: any;
  stockdetails: any;
  hideFailureMessage: boolean = true;
  errorMessage: string;
  isQuantityExceed: boolean = false;

  homeSpecialAddCart: Subscription;
  customerCart: Subscription;
  cartDelete: Subscription;
  cartUpdate: Subscription;
  stockchecksub: Subscription;

  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private authService: AuthenticationService,
    private routerService: RouterService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  custId: string = this.authService.getCustomerId();


  ngOnInit() {
    //To reload child component once
    if (localStorage.getItem('isLoadCartPage') == 'Y') {
      localStorage.setItem('isLoadCartPage', 'N')
      window.location.reload();
    } else {
      localStorage.removeItem('isLoadCartPage')
    }

    //this.custId = this.authService.getCustomerId();
    var cartdata = JSON.parse(localStorage.getItem("cartdata"));
    const currentUser = this.authService.currentUserValue;

    // The following block to check first whether any cart session data available or not.
    // if available then add the same into cart but for same product will not add
    if (cartdata != null) {
      this.homeSpecialAddCart = this.customerService.addToCart(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.cartresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.cartresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              setTimeout(() => {
                this.getAllCartList(this.custId);
                this.show = true;
                localStorage.removeItem("cartdata");
              }, 500);
            }
          });
    } else {
      setTimeout(() => {
        this.getAllCartList(this.custId);
        this.show = true;
      }, 500);
    }

  }

  FadeOutFailureMsg() {
    setTimeout(() => {
      this.hideFailureMessage = true;
      //this.getAllCartList(this.custId);
      window.location.reload();
    }, 3000);
  }

  continueShopping() {
    this.routerService.routeToHome();
  }

  proceedToCheckout(page) {
    /**
       * For Google Analytics to track begin_checkout event
       */

    this
      .googleAnalyticsService
      .eventEmitter("begin_checkout", "ecommerce", "product", "click", 8);

    /**
     * ***********************************************
     */

    this.routerService.routeToCheckOut(page);
  }

  getAllCartList(custId) {
    return this.customerCart = this.customerService.getAllCartList(custId)
      .subscribe(
        data => {
          data = this.cartlistdata = data;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.cartlistdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.isQuantityExceed = false;
            // Calculating sub-total & grand total price for all cart items
            this.count = this.cartlistdata.length;
            var sub_total: number = 0;
            var x = this.count;
            var y: number = +x;
            for (let i = 0; i < y; i++) {
              //update the cart with the latest product price
              this.updateCartValue(this.cartlistdata[i].quantity, this.cartlistdata[i].productinfo.sell_price, this.cartlistdata[i].shopping_cart_id);
              if (this.cartlistdata[i].quantity > this.cartlistdata[i].productinfo.stock) {
                this.isQuantityExceed = true;
              }
              sub_total = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.cartlistdata[i].total_price.toString());
            }
            this.subtotalprice = sub_total;
            this.grandtotalprice = sub_total;
            if (sub_total == 0) {
              this.isCartEmpty = true;
            }
            // Shipping cost adding based on total amount purchased
            if (this.grandtotalprice >= 300) {
              this.shippingfee = 0
            } else if (this.grandtotalprice == 0) {
              this.shippingfee = 0
            } else {
              this.shippingfee = 50
              this.grandtotalprice = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.shippingfee.toString());
            }
          }
        });
  }

  removeCartId(cartid) {

    /**
       * For Google Analytics to track remove_from_cart event
       */

    this
      .googleAnalyticsService
      .eventEmitter("remove_from_cart", "ecommerce", "product", "click", 5);

    /**
     * ***********************************************
     */

    this.cartDelete = this.customerService.removeCartItem(cartid)
      .subscribe(
        data => {
          data = this.removeMessage = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.removeMessage['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.getAllCartList(this.custId);
          }
        });
  }

  getDetails(value, price, cartid, productid, itemsize) {
    var qunatity = value;
    let cartdata = {
      "quantity": qunatity,
      "price": price
    }

    let sizedata = {
      "attribute_value": itemsize
    }

    this.stockchecksub = this.productService.getProductStockDetails(productid, sizedata)
      .subscribe(
        data => {
          data = this.stockdetails = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.stockdetails['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            // Check the quantity before updating the item price details and proceed for checkout
            if (qunatity > this.stockdetails.quantity) {
              this.hideFailureMessage = false;
              this.errorMessage = "Please reduce the quantity of the item as we have very limited stock available now."
            } else {
              this.cartUpdate = this.customerService.updateProductQuantity(cartdata, cartid)
                .subscribe(
                  data => {
                    data = this.updateMessage = data;
                    // IF block will execute only when the corresponding service is down or not
                    // getting any response
                    if (this.updateMessage['message'] == 'DOWN') {
                      this.routerService.routeToTryAgainPage();
                    } else {
                      this.getAllCartList(this.custId);
                      //window.location.reload();
                      //this.hideFailureMessage = true;
                    }
                  });
            }
          }
        });
  }

  updateCartValue(value, price, cartid) {
    var qunatity = value;
    let cartdata = {
      "quantity": qunatity,
      "price": price
    }

    this.cartUpdate = this.customerService.updateProductQuantity(cartdata, cartid)
      .subscribe(
        data => {
          data = this.updateMessage = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.updateMessage['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            //this.getList(this.custId);
          }
        });
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.customerCart) {
      this.customerCart.unsubscribe();
    }
    if (this.cartDelete) {
      this.cartDelete.unsubscribe();
    }
    if (this.cartUpdate) {
      this.cartUpdate.unsubscribe();
    }
    if (this.homeSpecialAddCart) {
      this.homeSpecialAddCart.unsubscribe();
    }
    if (this.stockchecksub) {
      this.stockchecksub.unsubscribe();
    }
  }

}
