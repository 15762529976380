import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { RouterService } from '../services/router.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private routerService: RouterService,
        private authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;

        if (currentUser && currentUser['accessToken']) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser['accessToken']}`,
                    'X_API_KEY': environment.X_API_KEY
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    'X_API_KEY': environment.X_API_KEY
                }
            });
        }
        //return next.handle(request);
        return next.handle(request).pipe(tap(
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.routerService.routeToLogin();
                    }
                }
            }
        ));
    }
}