// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //PROD
  production: true,
  // appUrl: 'https://app-service.khoaihaat.com',
  // apiURI: '/api/v1/resources',
  // paymentServiceUrl: 'https://app-service.khoaihaat.com/api/v1/resources/paymentsmgmt',
  // appId: '7816785406e248c46cb6ab41876187',
  // secretKey: 'ac05b80bed2d10126fd63c94882e0b1eb2355c0',
  //TEST
  // production: false,
  // appUrl: 'http://localhost:8765',
  // apiURI: '/api/v1/resources',
  // paymentServiceUrl: 'http://localhost:8765/api/v1/resources/paymentsmgmt',
  //appId: '33537de7687789dd38e4c023c73533',
  //secretKey: 'c7eca67fc9a52fbf760d8a1f10c722ed2b8e5090',

  //X_API_KEY: 'oeRaYY7Wo24sDqKSX3IM9ASGmdGPmkTd9jo1QTy4b7P9Ze5_9hKolVX8xNrQDcNRfVEdTZNOuOyqEGhXEbdJI-ZQ19k_o9MI0y3eZN2lp9thhjjLOVVS74740202-jbLDiz9iFVzkd81YKxMgPA7VfZeQUm4n-mOmnWMaVX30zGFU4L3oPBctYKkl4dYfqYWqRNfrgPJVi5DGFjywgxx0ASEiJHtV72paI3fDR2XwlSkyhhmY-ICjCRmsJN4fX1pdoL8a18-aQrvyu4j0Os6dVPYIoPvvY0SAZtWYKHfM15g7A3HD4cVREf9cUsprCRK93w'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
