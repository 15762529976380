import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { RouterService } from '../services/router.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-second-level-nav',
  templateUrl: './second-level-nav.component.html',
  styleUrls: ['./second-level-nav.component.css']
})
export class SecondLevelNavComponent implements OnInit {

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private routerService: RouterService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  getProductByMenu(displaystatus: string) {

    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", displaystatus, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.router.navigate(['/search'], { queryParams: { w: displaystatus, mode: 'menu' } });
  }

}
