import { NgModule } from '@angular/core';

import { CancelOrderPageRoutingModule } from './cancel-order-page-routing.module';
import { CancelOrderPageComponent } from './cancel-order-page.component';
import { CancelOrderInfoComponent } from '../customer-component/cancel-order-info/cancel-order-info.component';

import SharedModule from '../shared.module';

@NgModule({
    declarations: [
        CancelOrderPageComponent,
        CancelOrderInfoComponent
    ],
    imports: [
        SharedModule,
        CancelOrderPageRoutingModule
    ],
    providers: []
})
export class CancelOrderPageModule { }