import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators'
import { throwError } from 'rxjs';

import { User } from '../models/user.model';
import { BillingAddress } from '../models/checkout.model';
import { environment } from '../../environments/environment.prod';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  custId: String = this.authService.getCustomerId();

  /**
   * Regisre a customer
   * @param user 
   */
  register(user: User) {
    //return this.http.post(environment.appUrl + environment.apiURI + '/customer/register', user);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customer/register';
    let body = JSON.stringify(user);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * Regisre a customer while login using Facebook/Google
   * @param user 
   */
  registerSocial(user: any) {
    return this.http.post(environment.appUrl + environment.apiURI + '/customer/register', user);
  }

  /**
   * Check user email is it is already present in the DB or not
   * @param email 
   */
  checkUserEmail(email: string) {
    let url = environment.appUrl + environment.apiURI + '/customer/register/checkemail?email=' + email;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The service is responsible to send an email to customer after successfull registration
   * @param email 
   * @returns 
   */
  sendUserRegistrationSuccessEmail(email: string) {
    let url = environment.appUrl + environment.apiURI + '/utilitymgmt/customer/utility/process/registration/success/' + email;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * Extract all products store as a wishlist for a specific customer
   */
  getAllWishList(custId: string) {
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/wishlist/' + custId;
    return this.http.get<any[]>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * Extract all products store in the shopping cart for a specific customer
   */
  getAllCartList(custId: string) {
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/cart/get/' + custId;
    return this.http.get<any[]>(url).pipe(
      catchError(this.handleError));
  }

  /**
   * The following function responsible for adding products into cart
   */
  addToCart(cartdata, custId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/cart/' + custId;
    let body = JSON.stringify(cartdata);

    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following function will be responsible to add a product into wishlist
   * for a customer
   * @param cartdata 
   * @param custId 
   */
  addToWishList(cartdata, custId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/wishlist/' + custId;

    let body = JSON.stringify(cartdata);

    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following function will remove item from shopping cart for a specific user
   * @param cartId 
   */
  removeCartItem(cartId) {
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/cart/process/' + cartId;
    return this.http.delete<any>(url);
  }

  /**
   * The following function will remove item from wishlist for a specific user
   * @param cartId 
   */
  removeWishlistItem(cartId) {
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/wishlist/process/' + cartId;
    return this.http.delete<any>(url);
  }

  /**
   * The following function will be responsible to update the product quantity in the cart.
   * None of the product will have more than 3 quantity
   */
  updateProductQuantity(cartdata, cartId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/shoppingcartmgmt/customer/cart/process/' + cartId;
    let body = JSON.stringify(cartdata);

    return this.http.put<any>(url, body, { headers: headers });

  }

  /**
   * The following service will be responsible for fetch customer checkout details like addresses
   * and order details before proceeding to payment
   * 
   * @param custId 
   */
  getCustomerCheckoutInfo(custId: string) {
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/checkout/' + custId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service will be responsible for adding new shipping address for a customer
   * 
   * @param shippingaddr 
   * @param custId 
   */
  addNewShippingAddress(billAddr: BillingAddress, custId: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/s/address/' + custId;
    let body = JSON.stringify(billAddr);
    return this.http.post<any>(url, body, { headers: headers });
  }


  /**
   * The following service will be reponsible for extract shipping address using address id
   * 
   * @param shippaddrid 
   */
  getShippingAddressById(shippaddrid: number) {
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/s/address/find/' + shippaddrid;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * Get Customer Information using customer id
   * @param custId 
   */
  getCustomerInfo(custId: string) {
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/' + custId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * Update Customer Profile Information using customer id
   * @param custdata 
   * @param custId 
   */
  updateCustomerProfile(custdata, custId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/' + custId;
    let body = JSON.stringify(custdata);

    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The following service responsible to find all shipping addresses mapped with a customer
   * @param custId 
   */
  getShippingAddressByCustomerId(custId) {
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/s/address/' + custId;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to DISABLE a shipping address using address id
   * @param shippaddrid 
   */
  disableShippingAddress(shippaddrid: number, data) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/s/address/ud/status/' + shippaddrid;
    let body = JSON.stringify(data);
    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The service is responsible to update shipping address using address id
   * @param addrdata 
   * @param shippaddrid 
   */
  updateShippingAddressById(addrdata, shippaddrid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/s/address/ud/' + shippaddrid;
    let body = JSON.stringify(addrdata);

    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The following function responsible for inserting users session data during login time
   */
  addSessionDetails(sessiondata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customer/session/data/create';
    let body = JSON.stringify(sessiondata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following function responsible for upading existing users session data during logout time
   */
  updateSessionDetails(sessiondata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customer/session/data/update';
    let body = JSON.stringify(sessiondata);
    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The following function responsible to reset password
   */
  resetPassword(userdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customer/password/reset';
    let body = JSON.stringify(userdata);
    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * The function is responsible to change the customer 
   * password only
   * 
   * @param userdata 
   */
  updatePassword(userdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customer/password/update';
    let body = JSON.stringify(userdata);
    return this.http.put<any>(url, body, { headers: headers });
  }

  /**
   * This function is responsible to add all queries submitted to Khoaihaat
   * @param querydata 
   * @returns 
   */
  addContactUsQueryDetails(querydata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/contactus/query/submission';
    let body = JSON.stringify(querydata);
    console.log(body);
    console.log(url);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * This service is responsible to submit Bulk Order Query
   * 
   * @param querydata 
   * @returns 
   */
  addBulkOrdersQueryDetails(querydata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/bulkorder/query/submission';
    let body = JSON.stringify(querydata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * This service is responsible to submit Seller Info Query
   * 
   * @param querydata 
   * @returns 
   */
  addSellerInfoQueryDetails(querydata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/customermgmt/customer/sellerinfo/query/submission';
    let body = JSON.stringify(querydata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
