import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators'
import { throwError } from 'rxjs';

import { environment } from '../../environments/environment.prod';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  /**
   * The following service will be responsible to extract a payment response which was received from Payment Gateway only.
   * @param orderNumber 
   * @returns 
   */
  getOrderStatusFromGateway(orderNumber) {
    let url = environment.appUrl + environment.apiURI + '/paymentsmgmt/customer/payment/gateway/response/' + orderNumber;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service will be resonsible to update the Payment Getway table with order values
   * for Cash On Delivery only
   * @param orderdata 
   * @returns 
   */
  updatePaymentGatewayForCOD(orderdata) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/paymentsmgmt/customer/payment/gateway/cod';

    let body = JSON.stringify(orderdata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
