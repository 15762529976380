import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AboutUsPageComponent } from './about-us-page.component';

const routes1: Routes = [
    {
        path: "",
        component: AboutUsPageComponent
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes1)]
})
export class AboutUsPageRoutingModule {}