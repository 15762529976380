import { NgModule } from '@angular/core';

import { BulkOrdersPageRoutingModule } from './bulk-orders-page-routing.module';
import { BulkOrdersPageComponent } from './bulk-orders-page.component';
import { BulkOrdersInfoComponent } from '../general-component/bulk-orders-info/bulk-orders-info.component';

import SharedModule from '../shared.module';
import GeneralNavModule from '../modules/general-nav.module';

@NgModule({
    declarations: [
        BulkOrdersPageComponent,
        BulkOrdersInfoComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        BulkOrdersPageRoutingModule
    ],
    providers: []
})
export class BulkOrdersPageModule { }