import { NgModule } from '@angular/core';

import { PurchaseProtectionRoutingModule } from './purchase-protection-page-routing.module';
import { PurchaseProtectionPageComponent } from './purchase-protection-page.component';

import SharedModule from '../../shared.module';
import GeneralNavModule from '../../modules/general-nav.module';

@NgModule({
    declarations: [
        PurchaseProtectionPageComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        PurchaseProtectionRoutingModule
    ],
    providers: []
})
export class PurchaseProtectionPageModule { }