import { NgModule } from '@angular/core';

import { PrivacyPageRoutingModule } from './privacy-page-routing.module';
import { PrivacyPageComponent } from './privacy-page.component';

import SharedModule from '../../shared.module';
import GeneralNavModule from '../../modules/general-nav.module';

@NgModule({
    declarations: [
        PrivacyPageComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        PrivacyPageRoutingModule
    ],
    providers: []
})
export class PrivacyPageModule { }