import { Component, OnInit } from '@angular/core';

import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-refund-replace-confirm-info',
  templateUrl: './refund-replace-confirm-info.component.html',
  styleUrls: ['./refund-replace-confirm-info.component.css']
})
export class RefundReplaceConfirmInfoComponent implements OnInit {

  message: string;
  isReplaceRefund: boolean = true;
  constructor(
    private routerService: RouterService
  ) { }

  ngOnInit() {
    this.message = "Your request for return/replace of the item has been submitted successfully. " +
      "Please follow the process to return/replace your item."
  }

  viewOrderDetails() {
    this.routerService.routeToMyOrders()
  }

}
