import { NgModule } from '@angular/core';

import { TermsOfUsePageRoutingModule } from './terms-of-use-page-routing.module';
import { TermsOfUsePageComponent } from './terms-of-use-page.component';

import SharedModule from '../../shared.module';
import GeneralNavModule from '../../modules/general-nav.module';

@NgModule({
    declarations: [
        TermsOfUsePageComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        TermsOfUsePageRoutingModule
    ],
    providers: []
})
export class TermsOfUsePageModule { }