import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { CustomerService } from '../../services/customer.service';
import { WishList } from '../../models/wishlist.model';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { RouterService } from '../../services/router.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { Utility } from '../../common/utility';

@Component({
  selector: 'app-customer-wishlist-info',
  templateUrl: './customer-wishlist-info.component.html',
  styleUrls: ['./customer-wishlist-info.component.css']
})
export class CustomerWishlistInfoComponent implements OnInit, OnDestroy {

  wishlistdata: WishList[];
  wishcartresponse: any;
  show: boolean = false;
  wishlistresponse: any;
  submitMessage: string;
  removeMessage: string;

  hideSuccessMessage: boolean = true;
  errormessage: string;

  wishlistadd: Subscription;
  customerWish: Subscription;
  deleteWishlist: Subscription;
  addtoCart: Subscription;


  constructor(
    private customerService: CustomerService,
    private authService: AuthenticationService,
    private routerService: RouterService,
    private encryptService: EncrDecrService
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    var wishdata = JSON.parse(localStorage.getItem("wishlistdata"));

    if (wishdata != null) {
      this.addToWishList(wishdata);
    } else {
      setTimeout(() => {
        this.getAllWishList(this.custId);
        this.show = true;
        localStorage.removeItem("wishlistdata");
      }, 500);
    }
  }

  addToWishList(wishdata) {
    this.wishlistadd = this.customerService.addToWishList(wishdata, this.custId)
      .subscribe(
        response => {
          response = this.wishlistresponse = response;
          if (this.wishlistresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            setTimeout(() => {
              this.getAllWishList(this.custId);
              this.show = true;
              localStorage.removeItem("wishlistdata");
            }, 500);
          }
        });
  }

  getAllWishList(custId) {
    this.customerWish = this.customerService.getAllWishList(custId)
      .subscribe(
        data => {
          data = this.wishlistdata = data;
          if (this.wishlistdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          }
        });
  }

  removeWishlistId(cartid) {
    this.deleteWishlist = this.customerService.removeWishlistItem(cartid)
      .subscribe(
        data => {
          data = this.removeMessage = data;
          if (this.removeMessage['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.getAllWishList(this.custId);
          }
        });
  }

  addToCart(pid: number, price: number, itemSize: string) {
    let cartdata = {
      "cart_items_details": [
        {
          "product_id": pid,
          "quantity": 1,
          "price": price,
          "item_size": itemSize
        }
      ]
    }
    this.custId = this.authService.getCustomerId();
    this.addtoCart = this.customerService.addToCart(cartdata, this.custId)
      .subscribe(
        response => {
          response = this.wishcartresponse = response;
          if (this.wishcartresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.submitMessage = "Item added to cart"
            this.hideSuccessMessage = false;
          }
        });
    //this.router.routeToShoppingCart();
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.customerWish) {
      this.customerWish.unsubscribe();
    }
    if (this.deleteWishlist) {
      this.deleteWishlist.unsubscribe();
    }
    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
  }

}
