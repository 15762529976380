import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment.prod';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  /**
   * The following service is responsible to extract all products based on 
   * NEW, FEATURED, SPECIAL status etc. and the same to display on page using page info
   * @param dStatus 
   * @param dPage 
   */
  getAllHomeProductList(dStatus: String, dPage: String) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/display/list/' + dStatus + '/' + dPage;
    return this.http.get<any[]>(url);
  }

  /**
   * The following service is responsible to extract all products based on NEW, FEATURED, SPECIAL status etc.
   * @param dStatus 
   */
  getAllDisplayProductList(spclcategory: String) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/display/list/all/' + spclcategory;
    return this.http.get<any[]>(url);
  }

  /**
   * The following service is responsible to get a product details using product id
   * @param productId 
   */
  getProductDetailsById(productId: number) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/' + productId;
    return this.http.get(url).
      pipe(
        map((data: Product) => {
          return data;
        }), catchError(error => {
          return throwError('Something went wrong during product details extract');
        })
      );
  }

  /**
   * The service will be responsible to extract product short info based on checkout page
   * 
   * @param productid 
   */
  getProductShortInfo(productid: string, itemsize: string) {
    // let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/display/short/' + productid;
    // return this.http.get<any>(url).pipe(
    //   catchError(this.handleError));
    let sizedata = {
      "attr_value": itemsize
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/display/short/' + productid;
    let body = JSON.stringify(sizedata);

    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service will be responsible to add a review for a specific product
   * 
   * @param feedback 
   * @param productId 
   */
  addReviewAndRating(feedback, productId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/review/' + productId;
    let body = JSON.stringify(feedback);

    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service is responsible to sort product list based on NEW, FEATURED, SPECIAL status etc and sorting order like
   * price asc, desc etc.
   * 
   * @param dStatus 
   */
  getSortAllDisplayProductList(spclcategory: String, sortType: String) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/products/sort/' + spclcategory + '/' + sortType;
    return this.http.get<any[]>(url);
  }

  /**
   * The following service is responsible to search products based on customers input from site
   * 
   * @param keyword 
   */
  getProductSearch(keyword: String) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/products/search/' + keyword;
    return this.http.get<any[]>(url);
  }

  getGenericProductFilterSearch(keyword: String, sortType: String, fp: String, fcr: String) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/products/search/sort/' + keyword + '/' + sortType + '/' + fp + '/' + fcr;
    return this.http.get<any[]>(url);
  }

  /**
   * The following service is responsible to extract all similar products based on 
   * product selected by customer
   * @param dStatus 
   * @param dPage 
   */
  getAllSimilarProductList(skucode: String, productId) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/search/similar/list/' + skucode + '/' + productId;
    return this.http.get<any[]>(url);
  }

  /**
   * The following service is responsible to extract stock details using product id
   * @param productid 
   */
  getProductStockDetails(productid: string, sizedata) {
    // let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/stock/' + productid+'/'+itemsize;
    // return this.http.get<any>(url).pipe(
    //   catchError(this.handleError));

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/stock/' + productid;
    let body = JSON.stringify(sizedata);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The service is responsible to extract products under special category for displaying inside HOME or any specific pages
   * @param spclcategory 
   * @param dPage 
   * @returns 
   */
  getAllHomeProductListBySpecialCategory(spclcategory: String, dPage: String) {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/product/special/category/list/' + spclcategory + '/' + dPage;
    return this.http.get<any[]>(url);
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
