import { NgModule } from '@angular/core';

import { MyProductreviewPageRoutingModule } from './my-productreview-page-routing.module';
import { MyProductreviewPageComponent } from './my-productreview-page.component';
import { MyProductreviewInfoComponent } from '../customer-component/my-productreview-info/my-productreview-info.component';

import SharedModule from '../shared.module';
import CustomerAccountRightNavModule from '../modules/customer-account-right-nav.module';

@NgModule({
    declarations: [
        MyProductreviewPageComponent,
        MyProductreviewInfoComponent
    ],
    imports: [
        SharedModule,
        MyProductreviewPageRoutingModule,
        CustomerAccountRightNavModule
    ],
    providers: []
})
export class MyProductreviewPageModule { }