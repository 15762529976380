import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridPageRoutingModule } from './grid-page-routing.module';
import { GridPageComponent } from './grid-page.component';

import SharedModule from '../shared.module';
import SharedGridListModule from '../shared-grid-list.module';
//import SharedMobileMenuModule from '../shared-mobile-menu.module';

@NgModule({
  declarations: [GridPageComponent],
  imports: [
    SharedModule,
    SharedGridListModule,
    GridPageRoutingModule
  ],
  providers: []
})
export class GridPageModule {}