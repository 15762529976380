import { NgModule } from '@angular/core';

import { ReturnReplaceOrderPageRoutingModule } from './return-replace-order-page-routing.module';
import { ReturnReplaceOrderPageComponent } from './return-replace-order-page.component';
import { ReturnReplaceOrderInfoComponent } from '../customer-component/return-replace-order-info/return-replace-order-info.component';

import SharedModule from '../shared.module';

@NgModule({
    declarations: [
        ReturnReplaceOrderPageComponent,
        ReturnReplaceOrderInfoComponent
    ],
    imports: [
        SharedModule,
        ReturnReplaceOrderPageRoutingModule
    ],
    providers: []
})
export class ReturnReplaceOrderPageModule { }