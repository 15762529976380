import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, NgModel } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { CartList } from '../../models/cart.model';
import { CustomerService } from '../../services/customer.service';
import { OrderService } from '../../services/order.service';
import { PaymentService } from '../../services/payment.service';
import { ProductService } from '../../services/product.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DataSharedService } from '../../services/datashared.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { CheckoutInfo, ShippingAddress } from '../../models/checkout.model';
import { UtilityService } from '../../services/utility.service';
import { environment } from 'src/environments/environment.prod';

//For Test Environment
//import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";

//For Production Environment
// import { cashfreeProd } from "cashfree-pg-sdk-javascript";

import { load } from '@cashfreepayments/cashfree-js';

@Component({
  selector: 'app-checkout-main-info',
  templateUrl: './checkout-main-info.component.html',
  styleUrls: ['./checkout-main-info.component.css']
})
export class CheckoutMainInfoComponent implements OnInit, OnDestroy {

  @ViewChild('couponcode') inputpromocode;

  isDeliveryShow = false;
  isOrderShow = true;
  isPaymentShow = true;
  isNewAddressShow = true;
  isHideAddress = false;
  isModifyAddressShow = true;
  isHideNewAddressRadioButton = false;
  isCancelButtonEnable = false;
  isConfirmAddressButton = true;
  isAddresschangeLink = true;
  isOrderReviewChangeLink = true;
  isCartOrderListDisplay = true;
  isBuyNowOrderListDisplay = true;

  submitMessage: string;
  submitted = false;
  m_submitted = false;
  addShippAddr: Subscription;
  custCheckInfo: Subscription;
  shippingAddr: Subscription;
  sharedData: Subscription;
  prshortData: Subscription;
  addToCartData: Subscription;
  customerCart: Subscription;
  cartupdate: Subscription;
  paymentsub: Subscription;
  orderapprovesub: Subscription;
  stockchecksub: Subscription;
  delhiveryservicechecksub: Subscription;
  redirectpaymentcodsub: Subscription;

  totalordercountsub: Subscription;
  promocodesub: Subscription;

  count: number;
  pcount: number;
  addshippaddrresponse: string;
  message: string;
  displaystatus: string;
  cartresponse: any;

  checkoutdata: CheckoutInfo;
  modifyshippaddrdata: ShippingAddress;
  productshortdata: any;
  productidvalue: string;

  productId: string;
  productName: string;
  productPrice: string;
  pPrice: string;
  productIconImage: string;
  qty: number;
  totalprice: number;
  updatemessage: string;
  productsize: string;

  shippaddrdata: ShippingAddress[];
  productlistdata: CartList[] = [];
  productbuynowdata: any[];

  orderitems = [];
  orderdata: Subscription;

  shoppingcartid = [];

  //Payment variabled
  customerEmail: string;
  customerName: string;
  customerPhone: string;
  notifyUrl: string;
  returnUrl: string;
  orderAmount: string;
  orderId: string;
  signature: string;
  gnumber: string;
  displaystatusfromsession: string;
  createorderresponse: any;
  paymentlinkresponse: any;

  ordercountresponse: any;
  totalordercount: string;
  promocoderesponse: any;
  coupon_code: string;
  coupon_type: string;
  discount_value: string;
  discount_percentage: any;
  valid_from: any;
  valid_until: any;
  minimum_order_value: string;
  maximum_discount_amount: string;
  total_order_count_required: string;
  messageerror: string;
  promomessage: string;
  hideFailurePromoCodeMessage: boolean = true;
  subtotalforpromo: any;
  discountvalueonorder: any = 0;
  finalpromocodename: string;

  stockdetails: any;
  hideFailureMessage: boolean = true;
  errorMessage: string;

  hideCODAmountMessage: boolean = true;
  codamountcheckessage: string;

  shippingfee: number;
  ispincodeavailableforcod: boolean = false;
  delhiveryserviceresponse: any;
  ispaymentmethod: string;
  isConfirmAPaymentButton: boolean = true;
  ordnum: string;
  redirectpaymentresponse: any;

  isCheckedNewAddressRadio: boolean = false;
  radioStatus: boolean = false;
  afterdiscountvalue: any = 0;

  isResponse: boolean = false;

  listofpromocodesub: Subscription;
  listofpromocoderesponse: any;
  pcodelistdata: [];

  /**
   * For Add new address form parameters
   */

  firstName = new FormControl('', Validators.required);
  lastName = new FormControl('', Validators.required);
  streetAddr1 = new FormControl('', Validators.required);
  phone_number = new FormControl('', [Validators.required, Validators.maxLength(10)]);
  city_new = new FormControl('', Validators.required);
  shipp_landmarks = new FormControl('', Validators.required);
  postCode = new FormControl('', [Validators.required, Validators.maxLength(7)]);
  shipp_state = new FormControl('', Validators.required);
  shipp_country = new FormControl('', Validators.required);

  newAddressForm = new FormGroup({
    first_name: this.firstName,
    last_name: this.lastName,
    shipp_address1: this.streetAddr1,
    shipp_landmarks: this.shipp_landmarks,
    phone_number: this.phone_number,
    shipp_city: this.city_new,
    shipp_zip_code: this.postCode,
    shipp_state: this.shipp_state,
    shipp_country: this.shipp_country
  });

  constructor(
    private customerService: CustomerService,
    private dataSharedService: DataSharedService,
    private productService: ProductService,
    private authService: AuthenticationService,
    private router: ActivatedRoute,
    private routerService: RouterService,
    private orderservice: OrderService,
    private paymentservice: PaymentService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilityservice: UtilityService
  ) { }

  custId: string = this.authService.getCustomerId();

  // convenience getter for easy access to form fields
  get f() { return this.newAddressForm.controls; }


  ngOnInit() {
    window.scrollTo(0, 0)
    this.displaystatusfromsession = localStorage.getItem('checkoutmode');

    this.getTotalOrderCountByUser();
    this.getListofOffers();

    this.router.queryParams
      .filter(params => params.page)
      .subscribe(params => {
        this.displaystatus = params.page;
      });

    if (this.displaystatus == null) {
      this.displaystatus = this.displaystatusfromsession;
    }

    this.dataSharedService.currentMessage.subscribe(message => this.message = message)
    this.getCustomerCheckoutInfo();
    if (this.displaystatus == 'view') {

      this.isCartOrderListDisplay = false;
      this.isBuyNowOrderListDisplay = true;
      localStorage.removeItem('product_id');
      localStorage.removeItem('checkoutmode');

      this.getAllCartList(this.custId);

    } else if (this.displaystatus == 'viewinfo') {
      this.productsize = localStorage.getItem('itemsizeforbuynow');

      localStorage.removeItem('checkoutmode');

      this.isBuyNowOrderListDisplay = false;
      this.isCartOrderListDisplay = true;
      this.qty = 1;
      this.productId = localStorage.getItem("product_id");

      if (this.productId != '' && this.productId != null) {
        this.getCheckoutProductInfo(this.productId, this.productsize);
      }

    } else {
      localStorage.removeItem('checkoutmode');
      this.routerService.routeToHome();
    }

    // Reset the value of address selection id once the page loading
    localStorage.removeItem('s_address_id');
    localStorage.removeItem('f_name');
    localStorage.removeItem('l_name');
    localStorage.removeItem('p_number');
  }

  getCustomerCheckoutInfo() {
    this.custCheckInfo = this.customerService.getCustomerCheckoutInfo(this.custId)
      .subscribe(
        data => {
          this.checkoutdata = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.checkoutdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.shippaddrdata = this.checkoutdata.shipp_address;
            this.count = 0;
            this.count = this.shippaddrdata.length;
            if (this.count == 0) {
              this.isHideAddress = true;
              this.isNewAddressShow = false;
              this.isModifyAddressShow = true;
              this.isHideNewAddressRadioButton = true;
              this.isCancelButtonEnable = true;
            }
          }
        });
  }

  getCheckoutProductInfo(productId: string, itemSize: string) {
    this.prshortData = this.productService.getProductShortInfo(productId, itemSize)
      .subscribe(
        data => {
          this.productshortdata = data;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.productshortdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.productName = this.productshortdata['product_name'];
            this.pPrice = this.productshortdata['sell_price'];
            this.productPrice = this.productshortdata['sell_price'];
            this.productIconImage = this.productshortdata['icon_image_path'];
            this.totalprice = this.productshortdata['sell_price'];
            this.subtotalforpromo = this.totalprice;

            // Shipping cost adding based on total amount purchased
            this.afterdiscountvalue = this.totalprice
            if (this.totalprice >= 300) {
              this.shippingfee = 0
              this.radioStatus = false;
            } else {
              this.shippingfee = 50
              this.radioStatus = true;
              this.totalprice = Number.parseInt(this.totalprice.toString()) + Number.parseFloat(this.shippingfee.toString());
            }

            this.productidvalue = productId;
            // add to cart
            var pid = Number.parseInt(productId.toString());
            var price = Number.parseInt(this.pPrice.toString());
            this.addToCart(pid, price, itemSize);
            setTimeout(() => {
              this.getSingleCartInfo(this.custId, pid);
            }, 1000);
          }
        });
  }

  getAllCartList(custId) {
    return this.customerCart = this.customerService.getAllCartList(custId)
      .subscribe(
        data => {
          data = this.productlistdata = data;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.productlistdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            // Calculating sub-total & grand total price for all cart items
            this.count = this.productlistdata.length;
            var sub_total: number = 0;
            var x = this.count;
            var y: number = +x;
            var totalquantity = 0
            for (let i = 0; i < y; i++) {
              totalquantity = totalquantity + this.productlistdata[i].quantity;
              this.shoppingcartid.push({
                "shopping_cart_id": this.productlistdata[i].shopping_cart_id
              })
              sub_total = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.productlistdata[i].total_price.toString());
            }

            this.qty = totalquantity;
            this.productPrice = sub_total.toString();
            this.subtotalforpromo = sub_total.toString();
            this.totalprice = sub_total;
            //this.dataSharedService.changeMessage(sdata);
            // Shipping cost adding based on total amount purchased
            this.afterdiscountvalue = this.totalprice
            if (this.totalprice >= 300) {
              this.shippingfee = 0
              this.radioStatus = false;
            } else {
              this.shippingfee = 50
              this.radioStatus = true;
              this.totalprice = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.shippingfee.toString());
            }
          }
        });

  }

  getSingleCartInfo(custId, pid) {
    return this.customerCart = this.customerService.getAllCartList(custId)
      .subscribe(
        data => {
          data = this.productlistdata = data;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.productlistdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.count = this.productlistdata.length;
            var x = this.count;
            var y: number = +x;
            for (let i = 0; i < y; i++) {
              if (this.productlistdata[i].product_id == pid) {
                this.shoppingcartid.push({
                  "shopping_cart_id": this.productlistdata[i].shopping_cart_id
                })
              }

            }
            localStorage.setItem("cartids", JSON.stringify(this.shoppingcartid));
            this.shoppingcartid = JSON.parse(localStorage.getItem('cartids'));
          }
        });
  }

  onSubmit() {

    this.submitted = true;
    // Return if form is invalid
    if (this.newAddressForm.invalid) {
      return;
    }
    this.addShippAddr = this.customerService.addNewShippingAddress(this.newAddressForm.value, this.custId)
      .pipe(first())
      .subscribe(
        data => {
          this.addshippaddrresponse = data;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.addshippaddrresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            window.location.reload();
          }
        },
        error => {
          this.submitMessage = error.error.message;
          this.newAddressForm.reset();
        });
  }

  onModifySubmit() {
    this.m_submitted = true;
  }

  toggleOrderDisplay() {
    this.isOrderShow = !this.isOrderShow;
    this.isDeliveryShow = true;
    this.isAddresschangeLink = false;
    this.isConfirmAPaymentButton = true;
  }

  toggleDeliveryDisplay() {
    this.isDeliveryShow = !this.isDeliveryShow;
    this.isDeliveryShow = true;
    this.isOrderReviewChangeLink = false;
    this.isAddresschangeLink = false;
    this.isConfirmAPaymentButton = false;
    //this.ispaymentmethod = "";
    this.isResponse = false;
    this.togglePaymentDisplay();
  }

  togglePaymentDisplay() {
    this.isPaymentShow = false;
    this.isOrderShow = true;
  }

  toggleNewAddressDisplay() {
    this.isNewAddressShow = !this.isNewAddressShow;
    this.isHideAddress = true;
    this.isModifyAddressShow = true;
    this.isCheckedNewAddressRadio = true;
    this.isConfirmAPaymentButton = true;
  }

  toggleHideNewAddress() {
    this.isNewAddressShow = true;
    this.isHideAddress = false;
    this.isCheckedNewAddressRadio = false;
  }

  toggleModifyAddress(saddrid: number) {
    this.isHideAddress = true;
    this.isNewAddressShow = false;
    this.isHideNewAddressRadioButton = true;
    this.shippingAddr = this.customerService.getShippingAddressById(saddrid)
      .subscribe(
        data => {
          this.modifyshippaddrdata = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.modifyshippaddrdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          }
        });

  }

  toggleHideModifyAddress() {
    this.isModifyAddressShow = true;
    this.isHideAddress = false;
  }

  onShippingAddressSelect(value, fName, lName, phoneNumber, pincode) {
    localStorage.setItem("s_address_id", value);
    localStorage.setItem("f_name", fName);
    localStorage.setItem("l_name", lName);
    localStorage.setItem("p_number", phoneNumber);
    this.isConfirmAddressButton = false;
    this.isConfirmAPaymentButton = true;
    this.checkDelhiveryServiceForCOD(pincode);
  }

  /**
   * The method is responsible to check Courier Service COD option is available for the selected PIN Code or not
   * @param pincode 
   */
  checkDelhiveryServiceForCOD(pincode) {
    this.delhiveryservicechecksub = this.utilityservice.getDelhiveryPinCodeServiceDetails(pincode)
      .subscribe(
        response => {
          response = this.delhiveryserviceresponse = response;
          var isCOD = this.delhiveryserviceresponse['cod']

          if (isCOD == 'Y') {
            this.ispincodeavailableforcod = true;
          } else {
            this.ispincodeavailableforcod = false;
            // Resting the values if the users change the payment options after applying a discount on order
            this.discountvalueonorder = 0;
            this.afterdiscountvalue = this.productPrice;
            this.totalprice = Number.parseInt(this.productPrice.toString());
            this.isResponse = false;
          }
        });
  }

  /**
   * This method will be executed on selection of radio button for choosing final payment option whether COD or ONLINE
   * 
   * @param selectvalue 
   */
  selectPayment(selectvalue) {
    this.isConfirmAPaymentButton = false;

    if (selectvalue == 1) {
      //this.radioStatus = false;
      this.ispaymentmethod = "ONLINE";

      // Resting the values if the users change the payment options after applying a discount on order
      this.discountvalueonorder = 0;
      this.afterdiscountvalue = this.productPrice;
      this.totalprice = Number.parseInt(this.productPrice.toString());
      if (this.totalprice <= 300) {
        this.shippingfee = 50
        this.totalprice = Number.parseInt(this.totalprice.toString()) + Number.parseFloat(this.shippingfee.toString());
      }
      this.isResponse = false;

    } else {
      this.ispaymentmethod = "COD";

      // Resting the values if the users change the payment options after applying a discount on order
      this.discountvalueonorder = 0;
      this.afterdiscountvalue = this.productPrice;
      this.totalprice = Number.parseInt(this.productPrice.toString());
      if (this.totalprice <= 300) {
        this.shippingfee = 50
        this.totalprice = Number.parseInt(this.totalprice.toString()) + Number.parseFloat(this.shippingfee.toString());
      }
      this.isResponse = false;
      this.checkCODAmount();
    }
  }

  /**
   * The method is checking order sub total amount value above Rs.300 or not for COD option selection
   */
  checkCODAmount() {
    if ((Number.parseInt(this.productPrice) > 300) && (Number.parseInt(this.productPrice) < 2000)) {
      this.isConfirmAPaymentButton = false;
      this.hideCODAmountMessage = true;
      this.radioStatus = false;
    } else {
      this.hideCODAmountMessage = false;
      this.codamountcheckessage = "Cash On Delivery amount should be ₹300 above and below ₹2000";
      this.isConfirmAPaymentButton = true;
      this.radioStatus = true;
    }
  }

  openDeliveryAddressSection() {
    this.isDeliveryShow = false;
    this.isOrderShow = true;
    this.isOrderReviewChangeLink = true;
    this.isPaymentShow = true;
    this.isConfirmAPaymentButton = true;
  }

  openOrderReviewSection() {
    this.isDeliveryShow = true;
    this.isOrderShow = false;
    this.isAddresschangeLink = true;
    this.isPaymentShow = true;
    this.isConfirmAPaymentButton = true;
  }

  /**
   * The following method is responsible to update the item count and respective total price based on the users selection
   * during the checkout time. It is executed only when the user comes to checkout page from "Buy Now" only
   * 
   * @param value 
   * @param price 
   * @param productid 
   * @param psize 
   */
  onProductQuantityChange(value, price, productid, psize) {
    //this.dataSharedService.changeMessage(value);
    this.discountvalueonorder = 0; //reset discount value to 0
    let sizedata = {
      "attribute_value": psize
    }
    this.stockchecksub = this.productService.getProductStockDetails(productid, sizedata)
      .subscribe(
        data => {
          data = this.stockdetails = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.stockdetails['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            // Check the quantity before updating the item price details and proceed for checkout
            if (value > this.stockdetails.quantity) {
              this.hideFailureMessage = false;
              this.errorMessage = "Please reduce the quantity of the item as we have very limited stock available now."
            } else {
              var x = price;
              var y = value;
              var m_value: number = 1;
              m_value = x * Number.parseInt(y.toString())
              //this.qty = 1;
              this.productPrice = m_value.toString();
              this.subtotalforpromo = m_value.toString();

              this.totalprice = m_value;
              this.qty = value;
              // Shipping cost adding based on total amount purchased
              this.afterdiscountvalue = this.totalprice
              if (this.totalprice > 300) {
                this.shippingfee = 0
                this.radioStatus = false;
              } else {
                this.shippingfee = 50
                this.radioStatus = true;
                this.totalprice = Number.parseInt(this.totalprice.toString()) + Number.parseFloat(this.shippingfee.toString());
              }
            }
          }
        });
  }

  /**
   * The following method is responsible to update the item count and respective total price based on the users selection
   * during the checkout time. It is executed only when the user comes to checkout page from shopping cart only
   * 
   * @param value 
   * @param price 
   * @param cartid 
   * @param productid 
   * @param psize 
   */
  onListProductQuantityChange(value, price, cartid, productid, psize) {
    this.discountvalueonorder = 0; //reset discount value to 0
    let cartdata = {
      "quantity": value,
      "price": price
    }
    let sizedata = {
      "attribute_value": psize
    }

    this.stockchecksub = this.productService.getProductStockDetails(productid, sizedata)
      .subscribe(
        data => {
          data = this.stockdetails = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.stockdetails['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            // Check the quantity before updating the item price details and proceed for checkout
            if (value > this.stockdetails.quantity) {
              this.hideFailureMessage = false;
              this.errorMessage = "Please reduce the quantity of the item as we have very limited stock available now."
            } else {
              this.cartupdate = this.customerService.updateProductQuantity(cartdata, cartid)
                .subscribe(
                  data => {
                    data = this.updatemessage = data;
                    this.getAllCartList(this.custId);
                  });
            }
          }
        });
  }

  addToCart(pid: number, price: number, itemSize: string) {
    let cartdata = {
      "cart_items_details": [
        {
          "product_id": pid,
          "quantity": 1,
          "price": price,
          "item_size": itemSize
        }
      ]
    }
    this.addToCartData = this.customerService.addToCart(cartdata, this.custId)
      .subscribe(
        response => {
          response = this.cartresponse = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.cartresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          }
        });
  }

  deleteItem(value, itemquantity, price) {
    const index: number = this.productlistdata.indexOf(value);
    var sub_total: number = 0;
    if (value !== -1) {
      this.productlistdata.splice(value, 1);
      if (this.productlistdata.length == 0) {
        this.routerService.routeToShoppingCart();
      }
    }

    // Resting the values if the users change the payment options after applying a discount on order
    this.discountvalueonorder = 0;
    this.afterdiscountvalue = this.productPrice;
    this.totalprice = Number.parseInt(this.productPrice.toString());
    this.isResponse = false;

    var x = price;
    var y = itemquantity;

    var m_value: number = 1;
    m_value = x * Number.parseInt(y.toString())
    sub_total = Number.parseInt(this.totalprice.toString()) - Number.parseFloat(m_value.toString());
    this.productPrice = sub_total.toString();
    this.subtotalforpromo = sub_total.toString();
    this.discountvalueonorder = 0; //reset discount value to 0
    this.qty = this.qty - 1;
    this.totalprice = sub_total;

    // Shipping cost adding based on total amount purchased
    this.afterdiscountvalue = this.totalprice
    if (this.totalprice >= 300) {
      this.shippingfee = 0
      this.radioStatus = false;
    } else {
      this.shippingfee = 50
      this.radioStatus = true;
      this.totalprice = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.shippingfee.toString());
    }
  }

  deleteSingleItem() {
    this.routerService.routeToShoppingCart();
  }

  //This mentod will call once the user clink on "Place Your Order and Pay" button
  paymentProcessing() {
    //If the COD option selected for payment
    if (this.ispaymentmethod == "COD") {
      if (this.ispincodeavailableforcod) { // check if the provided PIN code not available for COD
        if ((Number.parseInt(this.afterdiscountvalue) > 300) && (Number.parseInt(this.afterdiscountvalue) < 2000)) { // check the sub-total amount is Rs.300 above and below Rs.2000 or not
          this.isConfirmAPaymentButton = false;
          this.hideCODAmountMessage = true;
          this.radioStatus = false;
          this.submitPay();
        } else {
          this.hideCODAmountMessage = false;
          this.codamountcheckessage = "Cash On Delivery amount should be ₹300 above and below ₹2000";
          this.isConfirmAPaymentButton = true;
          this.radioStatus = true;
        }
      } else {
        this.hideCODAmountMessage = false;
        this.codamountcheckessage = "COD NOT available, select ONLINE payment option"
        this.isConfirmAPaymentButton = true;
        this.radioStatus = true;
      }

    } else if (this.ispaymentmethod == "ONLINE") { // this part is for ONLINE payment option only
      this.submitPay();
    } else {
      this.hideCODAmountMessage = false;
      this.codamountcheckessage = "Please select a Payment Option first"
      this.isConfirmAPaymentButton = true;
    }
  }

  //Once all the conditions satified for payment, the following method will be executed
  submitPay() {

    /**
       * For Google Analytics to track checkout_progress
       */

    this
      .googleAnalyticsService
      .eventEmitter("checkout_progress", "ecommerce", "checkout", "click", 7);

    /**
     * ***************************************************
     */

    var c_name = localStorage.getItem('f_name') + ' ' + localStorage.getItem('l_name');

    // The following condition will be executed if the user comes to checkout page directly from shopping cart
    if (!this.isCartOrderListDisplay) {
      this.pcount = this.productlistdata.length;
      var x = this.pcount;
      var y: number = +x;
      this.orderitems = [];
      for (let i = 0; i < y; i++) {
        var productid: number = this.productlistdata[i].product_id;
        var quantity: number = this.productlistdata[i].quantity;
        var price: number = this.productlistdata[i].total_price;
        var psize: string = this.productlistdata[i].product_size;
        var unitprice: number = this.productlistdata[i].productinfo.sell_price;
        this.orderitems.push({
          "product_id": productid,
          "quantity": quantity,
          "product_unit_price": unitprice,
          "total_product_price": price,
          "product_size": psize
        });
      }
      this.ordnum = this.generateOrderNumber();

      let ordercreatedata = {
        "order_number": this.ordnum,
        "payment_method_id": 2,
        "total_order_price": this.totalprice,
        "shipping_fee": this.shippingfee,
        "bill_addr_id": 0,
        "shipp_addr_id": localStorage.getItem('s_address_id'),
        "discount_percentage": this.discount_percentage,
        "discount_value": this.discountvalueonorder,
        "promo_code": this.finalpromocodename,
        "orderitems": this.orderitems,
        "cartids": this.shoppingcartid
      }
      this.orderdata = this.orderservice.createOrder(ordercreatedata, this.custId)
        .subscribe(
          response => {
            response = this.createorderresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.createorderresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              if (this.ispaymentmethod == "ONLINE") {
                // Execute the Payment Gateway Service for creating payment link
                this.generatePaymentLink(response.order_number, c_name);
              } else {
                let orderdata = {
                  "order_number": this.ordnum,
                  "order_amount": this.totalprice,
                }
                this.redirectpaymentcodsub = this.paymentservice.updatePaymentGatewayForCOD(orderdata)
                  .subscribe(
                    response => {
                      response = this.redirectpaymentresponse = response;
                      this.routerService.routeToSuccessPageForCOD(this.ordnum, this.redirectpaymentresponse['msg']);
                    });
              }
            }
          });
    } else { // The following else block will execute if the user comes to checkout page directly from "Buy Now" button click
      this.shoppingcartid = JSON.parse(localStorage.getItem('cartids'));
      this.ordnum = this.generateOrderNumber();

      let ordercreatedata = {
        "order_number": this.ordnum,
        "payment_method_id": 2,
        "total_order_price": this.totalprice,
        "shipping_fee": this.shippingfee,
        "bill_addr_id": 0,
        "shipp_addr_id": localStorage.getItem('s_address_id'),
        "discount_percentage": this.discount_percentage,
        "discount_value": this.discountvalueonorder,
        "promo_code": this.finalpromocodename,
        "orderitems": [
          {
            "product_id": this.productId,
            "quantity": this.qty,
            "product_unit_price": this.pPrice,
            "total_product_price": this.pPrice,
            "product_size": this.productsize
          }
        ],
        "cartids": this.shoppingcartid
      }
      this.orderdata = this.orderservice.createOrder(ordercreatedata, this.custId)
        .subscribe(
          response => {
            response = this.createorderresponse = response;

            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.createorderresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              if (this.ispaymentmethod == "ONLINE") {
                // Execute the Payment Gateway Service for creating payment link
                this.generatePaymentLink(response.order_number, c_name);
              } else {
                let orderdata = {
                  "order_number": this.ordnum,
                  "order_amount": this.totalprice,
                }
                this.redirectpaymentcodsub = this.paymentservice.updatePaymentGatewayForCOD(orderdata)
                  .subscribe(
                    response => {
                      response = this.redirectpaymentresponse = response;
                      this.routerService.routeToSuccessPageForCOD(this.ordnum, this.redirectpaymentresponse['msg']);
                    });
              }
            }
          });
    }
  }

  /**
   * Generate ORDER number which will be use for all future communication purpose
   * @returns 
   */
  generateOrderNumber() {
    let now = Date.now().toString(); // '1492341545873'
    // pad with extra random digit
    now += now + Math.floor(Math.random() * 10);
    this.gnumber = [now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join('');
    return this.gnumber;
  }

  /**
   * The following method is responsible to generate cashfree payment link for payment
   * @param ordernumber 
   * @param c_name 
   */
  generatePaymentLink(ordernumber: string, c_name: string) {
    var c_email = localStorage.getItem('user');
    let ordercreatedata = {
      "x-client-id": environment.appId,
      "x-api-version": environment.apiVersion,
      "x-client-secret": environment.secretKey,
      "cashfree_url": environment.cashfreeURL,
      "order_id": ordernumber,
      "order_amount": this.totalprice,
      "order_currency": "INR",
      "order_note": "Khoaihaat Order",
      "customer_id": this.custId,
      "customer_name": c_name,
      "customer_email": c_email,
      "customer_phone": localStorage.getItem('p_number'),
      "return_url": environment.paymentServiceUrl + "/customer/payments/response/",
      "notify_url": environment.paymentServiceUrl + "/customer/payments/notify"
    }

    // this.paymentsub = this.orderservice.getPaymentLinkFromCashfree(ordercreatedata)
    //   .subscribe(
    //     response => {
    //       response = this.paymentlinkresponse = response;
    //       console.log(this.paymentlinkresponse);
    //       // IF block will execute only when the corresponding service is down or not
    //       // getting any response
    //       if (this.paymentlinkresponse['message'] == 'DOWN') {
    //         this.routerService.routeToTryAgainPage();
    //       } else {
    //         console.log(response.paymentLink);
    //         //window.location.href = response.paymentLink;
    //       }

    //     });

    this.paymentsub = this.orderservice.makePaymentUsingCashfree(ordercreatedata)
      .subscribe(
        async response => {
          response = this.paymentlinkresponse = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.paymentlinkresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            //window.location.href = response.paymentLink;
            try {
              const paymentSessionId = this.paymentlinkresponse['payment_session_id'];
              //For TEST Environment
              // let cashfree = new cashfreeSandbox.Cashfree(paymentSessionId);

              // //For PROD Environment
              // //let cashfree = new cashfreeProd.Cashfree(paymentSessionId);

              // cashfree.redirect();

              let cashfree;
              // var initializeSDK = async function () {
              //   cashfree = await load({
              //     mode: "sandbox"
              //   });

              //   console.log("Cashfree SDK initialized:", cashfree);
              // };

              // initializeSDK();

              cashfree = await load({
                mode: environment.envvalue
              });

              let checkoutOptions = {
                paymentSessionId: paymentSessionId
              }
              cashfree.checkout(checkoutOptions).then(function (result) {
                if (result.error) {
                  //this.routerService.routeToTryAgainPage();
                  console.log(result.error.message);
                }
                if (result.redirect) {
                  console.log("Redirection")
                }
              });

            } catch (error) {
              //this.routerService.routeToTryAgainPage();
              console.log(error);
            }
          }

        });
  }

  /**
   * This method will execute when a user click on the "apply" button after giving a PROMO CODE
   * @param pcode 
   */
  applyCoupon(pcode: string) {
    if ((this.ispaymentmethod != '') && (this.ispaymentmethod != undefined)) {
      if (pcode != '') {
        this.getPromoCodeInfo(pcode);
      }
    } else {
      this.hideCODAmountMessage = false;
      this.codamountcheckessage = "Please select a Payment Option first"
      this.isConfirmAPaymentButton = true;
    }
  }

  /**
   * The method is responsible to calculate total order count for an user
   */
  getTotalOrderCountByUser() {
    this.totalordercountsub = this.orderservice.getTotalOrderCountForUser(this.custId)
      .subscribe(
        response => {
          response = this.ordercountresponse = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.ordercountresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.totalordercount = this.ordercountresponse['order_count'];
          }

        });
  }

  /**
   * The following method is responsible to calculate available promo code and all related conditions before proceed for payment
   * 
   * @param pcode 
   */
  getPromoCodeInfo(pcode: string) {
    this.promocodesub = this.orderservice.getPromoCodeDetails(pcode)
      .subscribe(
        response => {
          response = this.promocoderesponse = response;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.promocoderesponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.messageerror = this.promocoderesponse['message'];
            if (this.messageerror) { //when no active promo code found in the system
              // Clear the Promo Code input value
              this.inputpromocode.nativeElement.value = '';
              this.promomessage = "The Promo Code is not matching, please try again later"
              this.hideFailurePromoCodeMessage = false;
              this.isResponse = false;
            } else {
              this.coupon_code = this.promocoderesponse['coupon_code'];
              this.coupon_type = this.promocoderesponse['coupon_type'];
              this.discount_value = this.promocoderesponse['discount_value'];
              this.discount_percentage = this.promocoderesponse['discount_percentage'];
              this.valid_from = this.promocoderesponse['valid_from'];
              this.valid_until = this.promocoderesponse['valid_until'];
              this.minimum_order_value = this.promocoderesponse['minimum_order_value'];
              this.maximum_discount_amount = this.promocoderesponse['maximum_discount_amount'];
              this.total_order_count_required = this.promocoderesponse['total_order_count_required'];


              //Following block is for calculating discount based on different checks and % or value 
              //of discount *****************************************

              var validfrom = new Date(this.valid_from);
              var validuntil = new Date(this.valid_until);
              var minimumordervalue = this.minimum_order_value;

              if (this.isPromoCodeExpired(validfrom, validuntil)) { // if promocode expired
                this.promomessage = "The Promo Code is expired, please try a new PROMO CODE if available"
                this.hideFailurePromoCodeMessage = false;
                this.isResponse = false;
              } else if (!this.checkPromoCodeNameWithFirstOrderCountValid(this.totalordercount, this.coupon_code, this.total_order_count_required)) { // check whether user make the order first time or not
                this.promomessage = "The Promo Code is applicable for your FIRST ORDER only"
                this.hideFailurePromoCodeMessage = false;
                this.isResponse = false;
              } else if (!this.checkCouponType(this.ispaymentmethod, this.coupon_type)) {
                this.promomessage = "The Promo Code is NOT eligble for selected payment option"
                this.hideFailurePromoCodeMessage = false;
                this.isResponse = false;
              } else {

                if (this.checkMinimumOrderValue(minimumordervalue, this.subtotalforpromo)) {
                  this.isResponse = true;
                  this.finalpromocodename = this.coupon_code;
                  var dis_amount = this.discount_value;
                  var x = this.subtotalforpromo; //Existing subtotal value of the order

                  var finaldisamount;
                  var dis_value;
                  /**
                   * The following block is responsible to calculate discount value based on the PROMO CODE
                   * and % or amount whatever mentioned in the system with respect to PROMO Code
                   */

                  if (this.discount_value) { // If the discount value is an amount

                    finaldisamount = x - Number.parseInt(dis_amount.toString()); // final amount after discount applied
                    this.discountvalueonorder = this.discount_value;

                  } else if (this.discount_percentage) { // If the discount value is a percentage

                    //calculating discount based on % assigned with respect to promo code//
                    dis_value = (Number.parseInt(this.subtotalforpromo) * (Number.parseInt(this.discount_percentage) / 100)).toFixed(2);

                    // Check Maximum Discount amount if available and satisfying or not with the calculated
                    // discount amount on top of total order
                    if ((dis_value > this.maximum_discount_amount) && (this.maximum_discount_amount != '0')) {
                      dis_value = this.maximum_discount_amount;
                    }

                    finaldisamount = x - Number.parseInt(dis_value.toString());
                    this.discountvalueonorder = dis_value;
                  }

                  // Shipping cost adding based on total amount purchased after discount applied
                  this.afterdiscountvalue = finaldisamount;
                  if (finaldisamount >= 300) {
                    this.shippingfee = 0
                    this.radioStatus = false;
                    this.totalprice = Number.parseInt(finaldisamount.toString());
                  } else {
                    this.shippingfee = 50
                    this.radioStatus = true;
                    this.totalprice = Number.parseInt(finaldisamount.toString()) + Number.parseFloat(this.shippingfee.toString());
                  }

                } else {
                  this.promomessage = "Minimum Order Amount should be ₹" + this.minimum_order_value;
                  this.isResponse = false;
                  this.hideFailurePromoCodeMessage = false;
                }
              }
            }

          }

        });
  }

  /**
   * The following method is responsible to check the coupon code type whether that is applicable for boht ONLINE and COD or any one 
   * in beween
   */
  checkCouponType(selectedtype: string, systemcoupontype: string): boolean {
    if (systemcoupontype == 'BOTH') {
      return true;
    } else if (selectedtype == systemcoupontype) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * The following method is responsible to check PROMO CODE name have the predefined values or not. This method is mainly for 
   * first order use PROMO CODE.
   * Apart from the below existing PROMOCODE, for any new addition, admin has to change the below code
   * 
   * @param ordercount 
   * @param pcodename 
   * @param requiredcount 
   * @returns 
   */
  checkPromoCodeNameWithFirstOrderCountValid(ordercount: string, pcodename: string, requiredcount: string): boolean {

    // If required order count 0, then there is no need to check required count condition
    if (requiredcount == 'NA') {
      return true;
    }

    if ((pcodename == 'WELCOME10') && (ordercount == requiredcount)) { // For first time order only
      return true;
    } else if ((pcodename == 'WELCOME20') && (ordercount == requiredcount)) {
      return true;
    } else if ((pcodename == 'WELCOME15') && (ordercount == requiredcount)) {
      return true;
    } else {
      return false;
    }

  }

  /**
   * The following method is responsible to check whether the applied PROMO CODE has been expired or not
   * 
   * @param validfrom 
   * @param validuntil 
   * @returns 
   */
  isPromoCodeExpired(validfrom, validuntil): boolean {
    // Clear the Promo Code input value
    this.inputpromocode.nativeElement.value = '';

    let currentdate = new Date();
    if ((currentdate.getTime() > validfrom.getTime()) && (currentdate.getTime() < validuntil.getTime())) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * The method is responsible for check whether PROMOCODE have any minimum order amount mentioned
   * @param minimumordervalue 
   * @param subtotalforpromo 
   * @returns 
   */
  checkMinimumOrderValue(minimumordervalue: any, subtotalforpromo: any): boolean {

    if (minimumordervalue == 0) {
      return true;
    } else if (Number.parseInt(subtotalforpromo.toString()) >= Number.parseInt(minimumordervalue.toString())) {
      return true;
    } else {
      return false;
    }

  }

  getListofOffers() {
    this.listofpromocodesub = this.orderservice.getListOfActivePromoCode()
      .subscribe(
        response => {
          try {
            response = this.listofpromocoderesponse = response;
            this.pcodelistdata = this.listofpromocoderesponse['promocodelist']
          } catch (error) { }

        });
  }

  FadeOutFailureMsg() {
    setTimeout(() => {
      this.hideFailureMessage = true;
      //this.getAllCartList(this.custId);
      window.location.reload();
    }, 3000);
  }

  FadeOutFailurePromoCodeMsg() {
    setTimeout(() => {
      this.hideFailurePromoCodeMessage = true;
      //this.getAllCartList(this.custId);
    }, 3000);
  }

  FadeOutFailureCODAmountMsg() {
    setTimeout(() => {
      this.hideCODAmountMessage = true;
      //this.getAllCartList(this.custId);
    }, 3000);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.addShippAddr) {
      this.addShippAddr.unsubscribe();
    }
    if (this.custCheckInfo) {
      this.custCheckInfo.unsubscribe();
    }
    if (this.shippingAddr) {
      this.shippingAddr.unsubscribe();
    }
    if (this.sharedData) {
      this.sharedData.unsubscribe();
    }
    if (this.prshortData) {
      this.prshortData.unsubscribe();
    }
    if (this.addToCartData) {
      this.addToCartData.unsubscribe();
    }
    if (this.cartupdate) {
      this.cartupdate.unsubscribe();
    }
    if (this.paymentsub) {
      this.paymentsub.unsubscribe();
    }
    if (this.stockchecksub) {
      this.stockchecksub.unsubscribe();
    }
    if (this.delhiveryservicechecksub) {
      this.delhiveryservicechecksub.unsubscribe();
    }
    if (this.redirectpaymentcodsub) {
      this.redirectpaymentcodsub.unsubscribe();
    }
    if (this.totalordercountsub) {
      this.totalordercountsub.unsubscribe();
    }
    if (this.promocodesub) {
      this.promocodesub.unsubscribe();
    }
    if (this.listofpromocodesub) {
      this.listofpromocodesub.unsubscribe();
    }
  }

}
