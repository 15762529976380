import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../services/router.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-home-banner-bottom',
  templateUrl: './home-banner-bottom.component.html',
  styleUrls: ['./home-banner-bottom.component.css']
})
export class HomeBannerBottomComponent implements OnInit {

  constructor(
    private routerService: RouterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private routerapi: Router
  ) { }

  ngOnInit() {
  }

  getProductList(categoryname: string) {
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.routerService.routeToSearch(categoryname, 'catgry');
  }

  getProductByMenu(displaystatus: string) {

    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", displaystatus, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.routerapi.navigate(['/search'], { queryParams: { w: displaystatus, mode: 'menu' } });
  }

  viewAllProductList(spclcategory: string) {
    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", spclcategory, "click", 3);

    this.routerService.routeToProductsListDisplay(spclcategory);
  }

  goToSecondLevelNavPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToSecondLevelNavPage();
  }

}
