import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { RouterService } from '../services/router.service';
import { CustomerService } from '../services/customer.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { CartList } from '../models/cart.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public href: string = "";
  isShoppingCartPage: boolean = false;
  customerCart: Subscription;
  cartlistdata: CartList[] = [];
  subtotalprice: number;
  grandtotalprice: number = 0;
  submitMessage: string;
  count: number = 0;

  submitted = false;
  keyword: string;

  searchitem = new FormControl('', Validators.required);

  searchForm = new FormGroup({
    searchitem: this.searchitem
  });

  constructor(
    public authenticationService: AuthenticationService,
    private routerService: RouterService,
    private customerService: CustomerService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  custId: string = this.authenticationService.getCustomerId();

  // convenience getter for easy access to form fields
  get f() { return this.searchForm.controls; }


  ngOnInit() {
    this.href = this.router.url;
    if (this.href == "/shoppingcartview") {
      this.isShoppingCartPage = true;
    } else {
      this.isShoppingCartPage = false;
    }
    if ((this.custId != '') && (this.custId != null)) {
      this.getAllCartList();
    }

  }

  goToShoppingCart() {
    this.routerService.routeToShoppingCart();
  }

  goToHome() {
    this.routerService.routeToHome();
  }

  getAllCartList() {
    return this.customerCart = this.customerService.getAllCartList(this.custId)
      .subscribe(
        data => {
          data = this.cartlistdata = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.cartlistdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            // Calculating sub-total & grand total price for all cart items
            this.count = this.cartlistdata.length;
            var sub_total: number = 0;
            var itemcount: number = 0;
            var x = this.count;
            var y: number = +x;
            for (let i = 0; i < y; i++) {
              sub_total = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.cartlistdata[i].total_price.toString());
            }
            this.subtotalprice = sub_total;
            this.grandtotalprice = sub_total;

            if (x == undefined) {
              this.count = itemcount;
            }
          }
        });

  }

  onSubmit() {
    this.submitted = true;

    // Return if form is invalid
    if (this.searchForm.invalid) {
      return;
    }

    this.keyword = this.f.searchitem.value;

    /**
       * For Google Analytics to track search event
       */

    this
      .googleAnalyticsService
      .eventEmitter("search", "engagement", this.keyword, "click", 3);

    /**
     * ***********************************************
     */

    //window.location.replace('/search?w=' + this.keyword + '&mode=srch');
    this.router.navigate(['/search'], { queryParams: { w: this.keyword, mode: 'srch' } })

  }

  gotoLogin() {
    this.routerService.routeToLogin();
  }

  gotoLogOut() {
    this.routerService.routeToLogOut();
  }

  gotoAboutUs() {
    this.routerService.routeToAboutUs();
  }

  gotoContactUs() {
    this.routerService.routeToContactUs();
  }

  gotoReplacementPolicy() {
    this.routerService.routeToReplacementPolicy();
  }

  gotoTermsOfUse() {
    this.routerService.routeToTermsOfUse();
  }

  gotoPrivacy() {
    this.routerService.routeToPrivacy();
  }

  gotoShippPolicy() {
    this.routerService.routeToShippPolicy();
  }

  gotoMyOrder() {
    this.routerService.routeToMyOrders();
  }

  gotoMyProfile() {
    this.routerService.routeToMyProfile();
  }

  gotoMyAddress() {
    this.routerService.routeToMyAddress();
  }

  gotoMyWishList() {
    this.routerService.routeToMyWishList();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.customerCart) {
      this.customerCart.unsubscribe();
    }
  }

}
