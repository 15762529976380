import { Component, OnInit } from '@angular/core';
import { RouterService } from '../services/router.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private routerService: RouterService
  ) { }

  ngOnInit() {
  }

  getProductList(categoryname: string) {
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.routerService.routeToSearch(categoryname, 'catgry');
  }

  goMyOrderPage() {
    //window.location.replace('/myorders');
    this.routerService.routeToMyOrders();
  }

  goMyProfilePage() {
    //window.location.replace('/myprofile');
    this.routerService.routeToMyProfile();
  }

  goMyAddressPage() {
    //window.location.replace('/myaddresses');
    this.routerService.routeToMyAddress();
  }

  goMyWishListPage() {
    //window.location.replace('/wishlistview');
    this.routerService.routeToMyWishList();
  }

  goToHomePage() {
    //window.location.replace('/homeview');
    this.routerService.routeToHome();
  }

  goToSiteMapPage() {
    this.routerService.routeToSiteMap();
  }

  goAboutUsPage() {
    //window.location.replace('/aboutus');
    this.routerService.routeToAboutUs();
  }

  goContactUsPage() {
    //window.location.replace('/contactus');
    this.routerService.routeToContactUs();
  }

  goReplacementPage() {
    //window.location.replace('/replacementpolicy');
    this.routerService.routeToReplacementPolicy();
  }

  goTermUsePage() {
    //window.location.replace('/termsofuse');
    this.routerService.routeToTermsOfUse();
  }

  goPrivacyPolicyPage() {
    //window.location.replace('/privacy');
    this.routerService.routeToPrivacy();
  }

  goShippingPolicyPage() {
    //window.location.replace('/shippingpolicy');
    this.routerService.routeToShippPolicy();
  }

  goMyCheckoutPage() {
    //window.location.replace('/shoppingcartview');
    this.routerService.routeToShoppingCart();
  }

  goToTrackOrderPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToTrackOrder();
  }

  goToBulkOrderPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToBulkOrder();
  }

  goToSell() {
    //window.location.replace('/homeview');
    this.routerService.routeToSellOnKhoaihaat();
  }

  goToSecondLevelNavPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToSecondLevelNavPage();
  }

}
