import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { OrderService } from '../../services/order.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { RouterService } from '../../services/router.service';
import { Utility } from '../../common/utility';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.css']
})
export class CustomerInvoiceComponent implements OnInit, OnDestroy {

  ordernumber: string;
  invoicedataSub: Subscription;
  invoicedata: any;
  shippingaddress: string;
  invdata: string;
  invoicedate: string;
  invoicenumber: string;
  items: any;
  totalprice: string;
  shippingfee: string;
  subtotalprice: string;
  discountvalue: string;

  first_name: string;
  last_name: string;
  phone_number: string;
  shipp_address1: string;
  shipp_landmarks: string;
  shipp_city: string;
  shipp_state: string;
  shipp_zip_code: string;
  shipp_country: string;

  constructor(
    private router: ActivatedRoute,
    private orderService: OrderService,
    private encryptService: EncrDecrService,
    private routerService: RouterService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.router.queryParams.subscribe(
      params => {
        this.ordernumber = params.onum;
      }
    );
    // The following block of code will be responsible of URI value was malformed then will redirect to try again page
    try {
      var order_number = decodeURIComponent(this.ordernumber.toString())
      this.ordernumber = this.encryptService.get(Utility.secretKey, order_number);

      if (this.ordernumber.toString() == '') {
        this.routerService.routeToTryAgainPage();
      }

      this.generateInvoice(this.ordernumber);
    } catch (error) {
      this.routerService.routeToTryAgainPage();
    }

  }

  generateInvoice(ordernumber: string) {
    this.invoicedataSub = this.orderService.generateInvoice(ordernumber)
      .subscribe(
        data => {
          this.invoicedata = data;
          console.log(this.invoicedata);
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.invoicedata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            try {
              this.shippingaddress = this.invoicedata['info'].billingaddress;

              this.first_name = this.shippingaddress['first_name'];
              this.last_name = this.shippingaddress['last_name'];
              this.phone_number = this.shippingaddress['phone_number'];
              this.shipp_address1 = this.shippingaddress['shipp_address1'];
              this.shipp_landmarks = this.shippingaddress['shipp_landmarks'];
              this.shipp_city = this.shippingaddress['shipp_city'];
              this.shipp_state = this.shippingaddress['shipp_state'];
              this.shipp_zip_code = this.shippingaddress['shipp_zip_code'];
              this.shipp_country = this.shippingaddress['shipp_country'];

              this.invoicenumber = this.invoicedata['invoiceinfo'];
              this.invoicenumber = this.invoicenumber['invoice_number'];
              this.invdata = this.invoicedata['invoiceinfo'];
              this.invoicedate = this.invdata['created_date'];

              this.items = this.invoicedata['orderitems'];

              var count = this.items.length;
              var sub_total: number = 0;
              for (let i = 0; i < count; i++) {
                sub_total = Number.parseInt(sub_total.toString()) + Number.parseFloat(this.items[i].total_product_price.toString());
              }
              this.subtotalprice = sub_total.toString();
              this.discountvalue = this.invoicedata['info'].discount_value;
              this.totalprice = this.invoicedata['info'].total_order_price;
              this.shippingfee = this.invoicedata['info'].shipping_fee;
            } catch (error) {
              this.routerService.routeToTryAgainPage();
            }
          }
        });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.invoicedataSub) {
      this.invoicedataSub.unsubscribe();
    }
  }

}
