import { NgModule } from '@angular/core';

import { SecondLevelNavPageRoutingModule } from './second-level-nav-page-routing.module';
import { SecondLevelNavComponent } from '../second-level-nav/second-level-nav.component';
import { SecondLevelNavPageComponent } from '../second-level-nav-page/second-level-nav-page.component'
import SharedModule from '../shared.module';
import GeneralNavModule from '../modules/general-nav.module';

@NgModule({
    declarations: [
        SecondLevelNavPageComponent,
        SecondLevelNavComponent],
    imports: [
        SharedModule,
        SecondLevelNavPageRoutingModule,
        GeneralNavModule
    ],
    providers: []
})
export class SecondLevelNavPageModule { }