import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators'
import { throwError } from 'rxjs';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * The following service is responsible to get all category, sub-category details
   */
  getAllMenu() {
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/menu/details';
    return this.http.get<any[]>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service will be responsible to get all products tagged under a specific 
   * category or sub-category
   */
  getAllProductsByCategory(categoryname, sortType: String, fp: String, fcr: String) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.appUrl + environment.apiURI + '/productmgmt/category/id/products/all/' + sortType + '/' + fp + '/' + fcr;
    let body = JSON.stringify(categoryname);
    return this.http.post<any>(url, body, { headers: headers });
  }

  /**
   * The following service is responsible to check the Delhivery courier service COD available or not based
   * on pin code provided by user
   * 
   * @param pincode 
   * @returns 
   */
  getDelhiveryPinCodeServiceDetails(pincode) {
    let url = environment.appUrl + environment.apiURI + '/utilitymgmt/customer/utility/delhivery/courier/pincode/service/' + pincode;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  /**
   * The following service is responsible to get the package delivery live status using 
   * AWB number
   * 
   * @param awbnumber 
   * @returns 
   */
  getDeliveryStatusByAWBDetails(awbnumber) {
    let url = environment.appUrl + environment.apiURI + '/utilitymgmt/customer/utility/delhivery/courier/live/status/service/' + awbnumber;
    return this.http.get<any>(url).pipe(
      retry(0),
      catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
