import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";

import { HomeMainSliderComponent } from '../home-component/home-main-slider/home-main-slider.component';
import { HomeNewProductComponent } from '../home-component/home-new-product/home-new-product.component';
import { HomeBannerMiddleComponent } from '../home-component/home-banner-middle/home-banner-middle.component';
import { HomeFeaturedSliderComponent } from '../home-component/home-featured-slider/home-featured-slider.component';
import { HomeBlogComponent } from '../home-component/home-blog/home-blog.component';
import { HomeSpecialProductComponent } from '../home-component/home-special-product/home-special-product.component';
import { HomeBannerBottomComponent } from '../home-component/home-banner-bottom/home-banner-bottom.component';
import { HomeReoneProductComponent } from '../home-component/home-reone-product/home-reone-product.component';
import { HomeBottomSliderComponent } from '../home-component/home-bottom-slider/home-bottom-slider.component';
import { OfferZoneComponent } from '../home-component/offer-zone/offer-zone.component';
import { NgoBannerComponent } from '../home-component/ngo-banner/ngo-banner.component';
import { VideoBlockComponent } from '../home-component/video-block/video-block.component';

@NgModule({
    imports: [CommonModule,
        ReactiveFormsModule],
    declarations: [
        HomeMainSliderComponent,
        HomeNewProductComponent,
        HomeBannerMiddleComponent,
        HomeFeaturedSliderComponent,
        HomeBlogComponent,
        HomeSpecialProductComponent,
        HomeBannerBottomComponent,
        HomeReoneProductComponent,
        HomeBottomSliderComponent,
        OfferZoneComponent,
        NgoBannerComponent,
        VideoBlockComponent
    ],
    exports: [
        HomeMainSliderComponent,
        HomeNewProductComponent,
        HomeBannerMiddleComponent,
        HomeFeaturedSliderComponent,
        HomeBlogComponent,
        HomeSpecialProductComponent,
        HomeBannerBottomComponent,
        HomeReoneProductComponent,
        HomeBottomSliderComponent,
        OfferZoneComponent,
        VideoBlockComponent,
        NgoBannerComponent,
        ReactiveFormsModule,
        CommonModule,
        FormsModule
    ]
})
export default class HomeSpecificModule { }