import { NgModule } from '@angular/core';

import { MyOrdersPageRoutingModule } from './my-orders-page-routing.module';
import { MyOrdersPageComponent } from './my-orders-page.component';
import { MyOrdersInfoComponent } from '../customer-component/my-orders-info/my-orders-info.component';

import SharedModule from '../shared.module';
import CustomerAccountRightNavModule from '../modules/customer-account-right-nav.module';

@NgModule({
    declarations: [
        MyOrdersPageComponent,
        MyOrdersInfoComponent
    ],
    imports: [
        SharedModule,
        MyOrdersPageRoutingModule,
        CustomerAccountRightNavModule
    ],
    providers: []
})
export class MyOrdersPageModule { }