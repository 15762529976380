import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';

import { AuthenticationService } from '../../services/authentication.service';
import { RouterService } from '../../services/router.service';
import { OrderService } from '../../services/order.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { PaymentService } from '../../services/payment.service';
import { Utility } from '../../common/utility';
import { UtilityService } from '../../services/utility.service';


@Component({
  selector: 'app-my-orderdetails-info',
  templateUrl: './my-orderdetails-info.component.html',
  styleUrls: ['./my-orderdetails-info.component.css']
})
export class MyOrderdetailsInfoComponent implements OnInit, OnDestroy {

  orderinfo: Subscription;
  orderpaymentinfo: Subscription;
  submitMessage: string;
  orderinfodata: any;
  orderpaymentinfodata: any;
  orderid: number;
  itemid: number;

  ordernumber: string;
  odate: string;
  orderdate: string;
  ordertotalprice: string;
  orderstatus: string;
  orderstatuscode: string;

  address: any;
  fName: string;
  lName: string;
  pNumber: string;
  sAddr1: string;
  sLandmark: string;
  sCity: string;
  sState: string;
  sZippcode: string;
  sCountry: string;

  items: any;
  productid: string;
  productname: string;
  imagepath: string;
  quantity: string;
  itemprice: string;
  itemstatus: string;
  itemrrstatusdescr: string;
  productsize: string;
  shippingfee: string;
  discount_value: string;

  gateway_name: string;
  payment_mode: string;
  transaction_status: string;
  message: string;


  deliverydata: any[];
  deliverycompletion: string;


  deliverystatuschecksub: Subscription;
  findawbnumbersub: Subscription;
  deliverystatusresponse: any;
  awbnumberresponse: any;
  messageerror: string;
  awbNumber: string;
  hideErrorMessage: boolean = true;
  livetrackmessage: string;

  //package related properties based on Delhivery API service

  packagedestination: string;
  ordertype: string;
  pickupdate: string;
  deliverydate: string;
  destreceivedate: string;
  expecteddeliverydate: string;
  promisedeliverydate: string;
  originreceivedate: string;
  finalstatus: string;
  statuslocation: string;
  statusdatetime: string;
  receivedby: string;
  statuscode: string;
  statustype: string;
  instruction: string;
  packagelivetracker: any;

  constructor(
    private router: ActivatedRoute,
    private routerService: RouterService,
    private orderService: OrderService,
    private datePipe: DatePipe,
    private authService: AuthenticationService,
    private encryptService: EncrDecrService,
    private paymentService: PaymentService,
    private utilityservice: UtilityService
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.router.queryParams.subscribe(
      params => {
        this.orderid = params.orderid;
        this.itemid = params.itemid
      }
    );
    try {
      var order_id = decodeURIComponent(this.orderid.toString())
      this.orderid = this.encryptService.get(Utility.secretKey, order_id);
      if (this.orderid.toString() == '') {
        this.routerService.routeToTryAgainPage();
      }
    } catch (error) {
      this.routerService.routeToTryAgainPage();
    }
    try {
      var item_id = decodeURIComponent(this.itemid.toString())
      this.itemid = this.encryptService.get(Utility.secretKey, item_id);
      if (this.itemid.toString() == '') {
        this.routerService.routeToTryAgainPage();
      }
    } catch (error) {
      this.routerService.routeToTryAgainPage();
    }
    this.displayOrderInfo();
  }

  displayOrderInfo() {
    window.scrollTo(0, 0);

    return this.orderinfo = this.orderService.getOrderDetails(this.orderid, this.itemid)
      .subscribe(
        data => {
          this.orderinfodata = data;

          this.ordernumber = this.orderinfodata['order_number'];
          //For Live Tracking
          this.getAWBNumberUsingOrderNumber(this.ordernumber);

          this.odate = this.getDateTimeFormatted(this.orderinfodata['created_date']);
          this.ordertotalprice = this.orderinfodata['total_order_price'];
          this.shippingfee = this.orderinfodata['shipping_fee']
          this.discount_value = this.orderinfodata['discount_value'];

          this.address = this.orderinfodata['deliveryaddress'];
          this.fName = this.address['first_name'];
          this.lName = this.address['last_name'];
          this.pNumber = this.address['phone_number'];
          this.sAddr1 = this.address['shipp_address1'];
          this.sLandmark = this.address['shipp_landmarks'];
          this.sCity = this.address['shipp_city'];
          this.sState = this.address['shipp_state'];
          this.sZippcode = this.address['shipp_zip_code'];
          this.sCountry = this.address['shipp_country'];

          this.items = this.orderinfodata['orderitems'];
          this.quantity = this.items['quantity'];
          this.itemprice = this.items['total_product_price'];
          this.productid = this.items['product_id'];
          this.productname = this.items['product_name'];
          this.imagepath = this.items['icon_image_path'];
          this.itemstatus = this.items['item_status'];
          this.productsize = this.items['product_size'];


          if ((this.itemstatus == "RETURN") || (this.itemstatus == "REPLACE")) {
            this.itemrrstatusdescr = "The item has been initiated for return/replace. Please contact us for any concern/queries"
          }

          this.deliverydata = this.orderinfodata['orderdelivery'];
          var count = this.deliverydata.length;
          for (let i = 0; i < count; i++) {
            this.orderstatus = this.deliverydata[i]['delivery_phase_name'];
            this.orderstatuscode = this.deliverydata[i]['delivery_status_code'];
          }
          if (this.orderstatuscode == 'CRT_ORD') {
            this.deliverycompletion = '20';
          } else if (this.orderstatuscode == 'CRT_ORD') {
            this.deliverycompletion = '40';
          } else if (this.orderstatuscode == 'APPRVD_ORD') {
            this.deliverycompletion = '60';
          } else if (this.orderstatuscode == 'SHIPD_ORD') {
            this.deliverycompletion = '80';
          } else if (this.orderstatuscode == 'DLVRY_ORD') {
            this.deliverycompletion = '100';
          } else if (this.orderstatuscode == 'CANCL_ORD') {
            this.deliverycompletion = '0';
          } else if (this.orderstatuscode == 'RETURN') {
            this.deliverycompletion = '0';
          } else if (this.orderstatuscode == 'REPLACE') {
            this.deliverycompletion = '100';
          }

          this.getOrderPaymentInfo(this.ordernumber);
        });
  }

  submitReview(pid) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);
    //window.location.replace('/myreviews?pid=' + encodevalue);
    this.routerService.routeToMyReview(encodevalue);
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  getOrderPaymentInfo(orderNumber: string) {
    return this.orderpaymentinfo = this.paymentService.getOrderStatusFromGateway(orderNumber)
      .subscribe(
        data => {
          this.orderpaymentinfodata = data;
          this.gateway_name = this.orderpaymentinfodata['gateway_name'];
          this.payment_mode = this.orderpaymentinfodata['payment_mode'];
          this.transaction_status = this.orderpaymentinfodata['transaction_status'];

          if ((this.transaction_status == 'PAID') || (this.transaction_status == 'SUCCESS')) {
            this.message = "";
          } else if (this.transaction_status == 'ACTIVE') {
            this.message = "Your order has been in ACTIVE state due to some issues from payment gateway " +
              "part. Please check your order status after some time. If the issue still persists, please do contact us info@khoaihaat.com ";
          } else if (this.transaction_status == 'EXPIRED') {
            this.message = "Your order could not be processed by the payment gateway. " +
              "Please cancel the current created order and purchased again. Sorry for the inconvenience, please excuse us.";
          } else if (this.transaction_status == 'FLAGGED') {
            this.message = "Your order processed successfully, but there is some validation going on by the payment gateway system. " +
              "Please do co-operate with us and check after some time. Sorry for the inconvenience, please excuse us.";
          } else {
            this.payment_mode = "NOT PROCESSED";
            this.gateway_name = "Cashfee";
            this.transaction_status = "USER DROPPED";
            this.message = "";
          }
        });
  }

  generateInvoice(ordernumber: string) {
    var encryptordernumber = this.encryptService.set(Utility.secretKey, ordernumber);
    var encryptordernumvalue = encodeURIComponent(encryptordernumber);

    window.open('/invoice?onum=' + encryptordernumvalue, '_blank');

  }

  getAWBNumberUsingOrderNumber(ordernumber: string) {
    this.findawbnumbersub = this.orderService.getAWBNumberUsingOrderNumber(ordernumber)
      .subscribe(
        response => {
          response = this.awbnumberresponse = response;

          this.messageerror = this.awbnumberresponse['message'];
          if (this.messageerror) { // AWB Number Not found
            //TO DO
            this.livetrackmessage = "The order status NOT being updated yet, please check later. Sorry for the inconvenience."
            this.hideErrorMessage = false;
          } else { //Response received from DB

            if (this.awbnumberresponse['order_tracking_number'] != 0) { //Order Number found in DB
              this.hideErrorMessage = true;
              this.getDeliveryStatusByAWB(this.awbnumberresponse['order_tracking_number']);

            } else {
              //TO DO
              this.livetrackmessage = "The order status NOT being updated yet, please check later. Sorry for the inconvenience."
              this.hideErrorMessage = false;
            }
          }
        });
  }

  getDeliveryStatusByAWB(awbnumber: string) {
    this.deliverystatuschecksub = this.utilityservice.getDeliveryStatusByAWBDetails(awbnumber)
      .subscribe(
        response => {
          response = this.deliverystatusresponse = response;

          this.messageerror = this.deliverystatusresponse['message'];
          if (this.messageerror) { // AWB, Order Number Not found
            this.livetrackmessage = "The order status NOT being updated yet, please check later. Sorry for the inconvenience.";
            this.deliverydate = "Not yet updated";
            this.promisedeliverydate = "Not yet updated";
            this.finalstatus = "Not yet updated";
            this.hideErrorMessage = false;
          } else {                                    //Response received from Courier
            //TO DO
            this.hideErrorMessage = true;
            this.packagedestination = this.deliverystatusresponse['destination'];
            this.ordertype = this.deliverystatusresponse['ordertype'];
            this.pickupdate = this.getDateTimeFormatted(this.deliverystatusresponse['pickupdate']);
            if (this.deliverystatusresponse['deliverydate'] == '') {
              this.deliverydate = "Not yet updated";
            } else {
              this.deliverydate = this.getDateFormatted(this.deliverystatusresponse['deliverydate']);
            }
            this.destreceivedate = this.getDateTimeFormatted(this.deliverystatusresponse['destreceivedate']);
            this.expecteddeliverydate = this.getDateTimeFormatted(this.deliverystatusresponse['expecteddeliverydate']);
            if (this.deliverystatusresponse['promisedeliverydate'] == '') {
              this.promisedeliverydate = "Not yet updated";
            } else {
              this.promisedeliverydate = this.getDateFormatted(this.deliverystatusresponse['promisedeliverydate']);
            }
            this.originreceivedate = this.getDateTimeFormatted(this.deliverystatusresponse['originreceivedate']);
            if (this.deliverystatusresponse['finalstatus'] == '') {
              this.finalstatus = "Not yet updated";
            } else {
              this.finalstatus = this.deliverystatusresponse['finalstatus'];
            }
            this.statuslocation = this.deliverystatusresponse['statuslocation'];
            this.statusdatetime = this.deliverystatusresponse['statusdatetime'];
            this.receivedby = this.deliverystatusresponse['receivedby'];
            this.statuscode = this.deliverystatusresponse['statuscode'];
            this.statustype = this.deliverystatusresponse['statustype'];
            this.instruction = this.deliverystatusresponse['instruction'];

            this.packagelivetracker = this.deliverystatusresponse['packagelivetracker'];
          }
        });
  }

  getDateTimeFormatted(dtvalue: string) {
    let formattedDt = formatDate(dtvalue, 'dd-MM-yyyy hh:mm', 'en_US');
    return formattedDt
  }

  getDateFormatted(dtvalue: string) {
    let fdate = formatDate(dtvalue, 'dd-MM-yyyy', 'en_US');
    return fdate
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.orderinfo) {
      this.orderinfo.unsubscribe();
    }
    if (this.orderpaymentinfo) {
      this.orderpaymentinfo.unsubscribe();
    }
    if (this.findawbnumbersub) {
      this.findawbnumbersub.unsubscribe();
    }
    if (this.deliverystatuschecksub) {
      this.deliverystatuschecksub.unsubscribe();
    }
  }

}
