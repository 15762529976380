import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListPageRoutingModule } from './list-page-routing.module';
import { ListPageComponent } from './list-page.component';

import SharedModule from '../shared.module';
import SharedGridListModule from '../shared-grid-list.module';
//import SharedMobileMenuModule from '../shared-mobile-menu.module';

@NgModule({
  declarations: [ListPageComponent],
  imports: [
    SharedModule,
    SharedGridListModule,
    ListPageRoutingModule
  ],
  providers: []
})
export class ListPageModule {}