import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-general-nav-info',
  templateUrl: './general-nav-info.component.html',
  styleUrls: ['./general-nav-info.component.css']
})
export class GeneralNavInfoComponent implements OnInit {

  constructor(
    private routerService: RouterService
  ) { }

  ngOnInit() {
  }

  goAboutUsPage() {
    this.routerService.routeToAboutUs();
  }

  goContactUsPage() {
    this.routerService.routeToContactUs();
  }

  goReplacementPage() {
    this.routerService.routeToReplacementPolicy();
  }

  goTermUsePage() {
    this.routerService.routeToTermsOfUse();
  }

  goPrivacyPolicyPage() {
    this.routerService.routeToPrivacy();
  }

  goShippingPolicyPage() {
    this.routerService.routeToShippPolicy();
  }

  goToSiteMapPage() {
    this.routerService.routeToSiteMap();
  }

}
