import { NgModule } from '@angular/core';

import { ProductreviewDirectPageRoutingModule } from './productreview-direct-page-routing.module';
import { ProductreviewDirectPageComponent } from './productreview-direct-page.component';
import { ProductreviewCustomerDirectComponent } from '../customer-component/productreview-customer-direct/productreview-customer-direct.component';

import SharedModule from '../shared.module';
import CustomerAccountRightNavModule from '../modules/customer-account-right-nav.module';

@NgModule({
    declarations: [
        ProductreviewDirectPageComponent,
        ProductreviewCustomerDirectComponent
    ],
    imports: [
        SharedModule,
        CustomerAccountRightNavModule,
        ProductreviewDirectPageRoutingModule
    ],
    providers: []
})
export class ProductreviewDirectPageModule { }