import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule
} from "@angular/router";

//import { HomePageComponent } from './home-page/home-page.component';
//import { ListPageComponent } from './list-page/list-page.component';
//import { ProductDetailsPageComponent } from './product-details-page/product-details-page.component';
//import { ShoppingCartPageComponent } from './shopping-cart-page/shopping-cart-page.component';
//import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
//import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';
//import { CustomerDashboardPageComponent } from './customer-dashboard-page/customer-dashboard-page.component';
//import { ProductComparePageComponent } from './product-compare-page/product-compare-page.component';
//import { BlogPageComponent } from './blog-page/blog-page.component';
//import { BlogDetailPageComponent } from './blog-detail-page/blog-detail-page.component';
//import { LoginPageComponent } from './login-page/login-page.component';
//import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
//import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { AuthGuard } from './helpers/auth.guard';
//import { SiteSliderTesterComponent } from './site-slider-tester/site-slider-tester.component';
//import { MyOrdersPageComponent } from './my-orders-page/my-orders-page.component';
//import { MyProfilePageComponent } from './my-profile-page/my-profile-page.component';
//import { MyAddressesPageComponent } from './my-addresses-page/my-addresses-page.component';
//import { MyProductreviewPageComponent } from './my-productreview-page/my-productreview-page.component';
//import { MyOrderdetailsPageComponent } from './my-orderdetails-page/my-orderdetails-page.component';
//import { ProductSearchPageComponent } from './product-search-page/product-search-page.component';
//import { PaymentSuccessReturnPageComponent } from './payment-success-return-page/payment-success-return-page.component';
//import { PaymentNotifyPageComponent } from './payment-notify-page/payment-notify-page.component';
//import { CancelOrderPageComponent } from './cancel-order-page/cancel-order-page.component';
//import { ReturnReplaceOrderPageComponent } from './return-replace-order-page/return-replace-order-page.component';
//import { RefundReplaceConfirmPageComponent } from './refund-replace-confirm-page/refund-replace-confirm-page.component';
//import { ReturnPolicyPageComponent } from './information/return-policy-page/return-policy-page.component';
//import { TermsOfUsePageComponent } from './information/terms-of-use-page/terms-of-use-page.component';
//import { PrivacyPageComponent } from './information/privacy-page/privacy-page.component';
//import { PurchaseProtectionPageComponent } from './information/purchase-protection-page/purchase-protection-page.component';
//import { FaqPageComponent } from './information/faq-page/faq-page.component';
//import { SitemapPageComponent } from './information/sitemap-page/sitemap-page.component';
import { CustomerInvoiceComponent } from './customer-component/customer-invoice/customer-invoice.component';
//import { ProductreviewDirectPageComponent } from './productreview-direct-page/productreview-direct-page.component';


//import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//import { UnknownErrorPageComponent } from './unknown-error-page/unknown-error-page.component';

import { GridPageModule } from './grid-page/grid-page.module';
import { ListPageModule } from './list-page/list-page.module';
import { HomePageModule } from './home-page/home-page.module';
import { PaymentSuccessReturnPageModule } from './payment-success-return-page/payment-success-return-page.module';
import { LoginPageModule } from './login-page/login-page.module';
import { ProductSearchPageModule } from './product-search-page/product-search.module';
import { ProductDetailsPageModule } from './product-details-page/product-details-page.module';
import { ShoppingCartPageModule } from './shopping-cart-page/shopping-cart-page.module';
import { CheckoutPageModule } from './checkout-page/checkout-page.module';
import { WishlistPageModule } from './wishlist-page/wishlist-page.module';
import { AboutUsPageModule } from './about-us-page/about-us-page.module'
import { MyOrdersPageModule } from './my-orders-page/my-orders-page.module'
import { MyOrderdetailsPageModule } from './my-orderdetails-page/my-orderdetails-page.module';
import { MyProfilePageModule } from './my-profile-page/my-profile-page.module';
import { MyAddressesPageModule } from './my-addresses-page/my-addresses-page.module';
import { MyProductreviewPageModule } from './my-productreview-page/my-productreview-page.module';
import { UnknownErrorPageModule } from './unknown-error-page/unknown-error-page.module';
import { CancelOrderPageModule } from './cancel-order-page/cancel-order-page.module';
import { ReturnReplaceOrderPageModule } from './return-replace-order-page/return-replace-order.module'
import { RefundReplaceConfirmPageModule } from './refund-replace-confirm-page/refund-replace-confirm-page.module'
import { ReturnPolicyPageModule } from './information/return-policy-page/return-policy-page.module';
import { TermsOfUsePageModule } from './information/terms-of-use-page/terms-of-use-page.module';
import { PrivacyPageModule } from './information/privacy-page/privacy-page.module';
import { PurchaseProtectionPageModule } from './information/purchase-protection-page/purchase-protection-page.module';
import { SitemapPageModule } from './information/sitemap-page/sitemap-page.module';
import { ContactUsPageModule } from './contact-us-page/contact-us-page.module';
import { ProductreviewDirectPageModule } from './productreview-direct-page/productreview-direct-page.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';

import { TrackOrdersPageModule } from './track-orders-page/track-orders-page.module';
import { BulkOrdersPageModule } from './bulk-orders-page/bulk-orders-page.module';
import { SellOnKhoaihaatPageModule } from './sell-on-khoaihaat-page/sell-on-khoaihaat-page.module';

import { SecondLevelNavPageModule } from './second-level-nav-page/second-level-nav-page.module'

const routes: Routes = [
  //{ path: 'homeview', component: HomePageComponent },
  {
    path: "homeview",
    loadChildren: () => HomePageModule
  },
  //{ path: 'login', component: LoginPageComponent },
  {
    path: "login",
    loadChildren: () => LoginPageModule
  },
  { path: 'logout', component: LogoutPageComponent },
  //{ path: 'gridview', component: GridPageComponent },
  {
    path: "gridview",
    loadChildren: () => GridPageModule
  },
  //{ path: 'listview', component: ListPageComponent },
  {
    path: "listview",
    loadChildren: () => ListPageModule
  },
  //{ path: 'search', component: ProductSearchPageComponent },
  {
    path: "search",
    loadChildren: () => ProductSearchPageModule
  },
  //{ path: 'productview', component: ProductDetailsPageComponent },
  {
    path: "productview",
    loadChildren: () => ProductDetailsPageModule
  },
  //{ path: 'shoppingcartview', component: ShoppingCartPageComponent, canActivate: [AuthGuard] },
  {
    path: "shoppingcartview",
    loadChildren: () => ShoppingCartPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'checkout', component: CheckoutPageComponent, canActivate: [AuthGuard] },
  {
    path: "checkout",
    loadChildren: () => CheckoutPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'wishlistview', component: WishlistPageComponent, canActivate: [AuthGuard] },
  {
    path: "wishlistview",
    loadChildren: () => WishlistPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'dashbaord', component: CustomerDashboardPageComponent, canActivate: [AuthGuard] },
  //{ path: 'productcompare', component: ProductComparePageComponent },
  //{ path: 'blogview', component: BlogPageComponent },
  // { path: 'blogdetailsview', component: BlogDetailPageComponent },
  //{ path: 'aboutus', component: AboutUsPageComponent },
  {
    path: "aboutus",
    loadChildren: () => AboutUsPageModule
  },
  //{ path: 'myorders', component: MyOrdersPageComponent, canActivate: [AuthGuard] },
  {
    path: "myorders",
    loadChildren: () => MyOrdersPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'myorderdetails', component: MyOrderdetailsPageComponent, canActivate: [AuthGuard] },
  {
    path: "myorderdetails",
    loadChildren: () => MyOrderdetailsPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'myprofile', component: MyProfilePageComponent, canActivate: [AuthGuard] },
  {
    path: "myprofile",
    loadChildren: () => MyProfilePageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'myaddresses', component: MyAddressesPageComponent, canActivate: [AuthGuard] },
  {
    path: "myaddresses",
    loadChildren: () => MyAddressesPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'myreviews', component: MyProductreviewPageComponent, canActivate: [AuthGuard] },
  {
    path: "myreviews",
    loadChildren: () => MyProductreviewPageModule,
    canActivate: [AuthGuard]
  },
  // { path: 'sliderdemo', component: SiteSliderTesterComponent },
  //{ path: 'response', component: PaymentSuccessReturnPageComponent },
  {
    path: "response",
    loadChildren: () => PaymentSuccessReturnPageModule
  },
  // { path: 'notifyus', component: PaymentNotifyPageComponent },
  //{ path: 'tryagain', component: UnknownErrorPageComponent },
  {
    path: "tryagain",
    loadChildren: () => UnknownErrorPageModule
  },
  //{ path: 'cancelorder', component: CancelOrderPageComponent, canActivate: [AuthGuard] },
  {
    path: "cancelorder",
    loadChildren: () => CancelOrderPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'rrinitiateorder', component: ReturnReplaceOrderPageComponent, canActivate: [AuthGuard] },
  {
    path: "rrinitiateorder",
    loadChildren: () => ReturnReplaceOrderPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'rrconfirm', component: RefundReplaceConfirmPageComponent, canActivate: [AuthGuard] },
  {
    path: "rrconfirm",
    loadChildren: () => RefundReplaceConfirmPageModule,
    canActivate: [AuthGuard]
  },
  //{ path: 'replacementpolicy', component: ReturnPolicyPageComponent },
  {
    path: "replacementpolicy",
    loadChildren: () => ReturnPolicyPageModule
  },
  //{ path: 'termsofuse', component: TermsOfUsePageComponent },
  {
    path: "termsofuse",
    loadChildren: () => TermsOfUsePageModule
  },
  //{ path: 'privacy', component: PrivacyPageComponent },
  {
    path: "privacy",
    loadChildren: () => PrivacyPageModule
  },
  //{ path: 'shippingpolicy', component: PurchaseProtectionPageComponent },
  {
    path: "shippingpolicy",
    loadChildren: () => PurchaseProtectionPageModule
  },
  // { path: 'faqpage', component: FaqPageComponent },
  //{ path: 'sitemap', component: SitemapPageComponent },
  {
    path: "sitemap",
    loadChildren: () => SitemapPageModule
  },
  //{ path: 'contactus', component: ContactUsPageComponent },
  {
    path: "contactus",
    loadChildren: () => ContactUsPageModule
  },
  {
    path: "bulkorders",
    loadChildren: () => BulkOrdersPageModule
  },
  {
    path: "sell",
    loadChildren: () => SellOnKhoaihaatPageModule
  },
  {
    path: "trackorders",
    loadChildren: () => TrackOrdersPageModule
  },
  { path: 'invoice', component: CustomerInvoiceComponent, canActivate: [AuthGuard] },
  //{ path: 'directreview', component: ProductreviewDirectPageComponent, canActivate: [AuthGuard] },
  {
    path: "directreview",
    loadChildren: () => ProductreviewDirectPageModule,
    canActivate: [AuthGuard]
  },
  {
    path: "navpage",
    loadChildren: () => SecondLevelNavPageModule
  },
  { path: '', redirectTo: '/homeview', pathMatch: 'full' },
  //{ path: '**', component: PageNotFoundComponent },
  {
    path: "**",
    loadChildren: () => PageNotFoundModule
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
