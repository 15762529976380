import { NgModule } from '@angular/core';

import { WishlistPageRoutingModule } from './wishlist-page-routing.module';

import SharedModule from '../shared.module';
import { WishlistPageComponent } from './wishlist-page.component';
import { CustomerAccountInfoComponent } from '../customer-component/customer-account-info/customer-account-info.component';
import { CustomerWishlistInfoComponent } from '../customer-component/customer-wishlist-info/customer-wishlist-info.component';

@NgModule({
    declarations: [
        WishlistPageComponent,
        CustomerWishlistInfoComponent,
        CustomerAccountInfoComponent
    ],
    imports: [
        SharedModule,
        WishlistPageRoutingModule],
    providers: []
})
export class WishlistPageModule { }