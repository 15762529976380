import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.css']
})
export class ListPageComponent implements OnInit {

  displaystatus: string;
  isHideFilters: boolean

  constructor(
    private router: ActivatedRoute
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0)

    $(document).ready(function ($) {
      $("#mobile-menu").mobileMenu({
        MenuWidth: 250,
        SlideSpeed: 300,
        WindowsMaxWidth: 767,
        PagePush: true,
        FromLeft: true,
        Overlay: true,
        CollapseMenu: true,
        ClassName: "mobile-menu"
      });
    });

    this.router.queryParams
      .filter(params => params.dstatus)
      .subscribe(params => {
        this.displaystatus = params.dstatus;
      });

    if (this.displaystatus == "new") {
      this.isHideFilters = true;
    } else if (this.displaystatus == "featured") {
      this.isHideFilters = true;
    } else if (this.displaystatus == "special") {
      this.isHideFilters = true;
    } else {
      this.isHideFilters = true;
      this.displaystatus = "new"
    }
  }
}

