import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../services/router.service';

declare var $: any

@Component({
  selector: 'app-sitemap-page',
  templateUrl: './sitemap-page.component.html',
  styleUrls: ['./sitemap-page.component.css']
})
export class SitemapPageComponent implements OnInit {

  constructor(
    private router: RouterService
  ) { }

  ngOnInit() {
    $(document).ready(function ($) {
      $("#mobile-menu").mobileMenu({
        MenuWidth: 250,
        SlideSpeed: 300,
        WindowsMaxWidth: 767,
        PagePush: true,
        FromLeft: true,
        Overlay: true,
        CollapseMenu: true,
        ClassName: "mobile-menu"
      });
    });
  }

  getProductList(categoryname: string) {
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.router.routeToSearch(categoryname, 'catgry');
  }

}
