import { NgModule } from '@angular/core';

import { SitemapPageRoutingModule } from './sitemap-page-routing.module';
import { SitemapPageComponent } from './sitemap-page.component';

import SharedModule from '../../shared.module';

@NgModule({
    declarations: [
        SitemapPageComponent
    ],
    imports: [
        SharedModule,
        SitemapPageRoutingModule
    ],
    providers: []
})
export class SitemapPageModule { }