import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { RouterService } from '../../services/router.service';
import { OrderService } from '../../services/order.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { Utility } from '../../common/utility';

import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cancel-order-info',
  templateUrl: './cancel-order-info.component.html',
  styleUrls: ['./cancel-order-info.component.css']
})
export class CancelOrderInfoComponent implements OnInit, OnDestroy {

  orderid: string;
  itemid: string;
  orderstatusresponse: any;
  isCancel: boolean;
  message: string;
  cancelorderresponse: any;
  cancelsnsresp: any;

  cancelOrderSub: Subscription;
  submitCancelSub: Subscription;
  cancelOrderSNSSub: Subscription;

  constructor(
    private router: ActivatedRoute,
    private routerService: RouterService,
    private orderService: OrderService,
    private authService: AuthenticationService,
    private encryptService: EncrDecrService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.router.queryParams.subscribe(
      params => {
        this.orderid = params.orderid;
        this.itemid = params.itemid;
      }
    );

    try {
      var order_id = decodeURIComponent(this.orderid.toString())
      this.orderid = this.encryptService.get(Utility.secretKey, order_id);
      if (this.orderid == '') {
        this.routerService.routeToTryAgainPage();
      }

      var item_id = decodeURIComponent(this.itemid.toString())
      this.itemid = this.encryptService.get(Utility.secretKey, item_id);
      if (this.itemid == '') {
        this.routerService.routeToTryAgainPage();
      }
    } catch (error) {
      this.routerService.routeToTryAgainPage();
    }

    this.checkOrderStatus(this.orderid);
  }

  checkOrderStatus(orderId: string) {
    return this.cancelOrderSub = this.orderService.getOrderCurrentStatus(this.orderid)
      .subscribe(
        data => {
          data = this.orderstatusresponse = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.orderstatusresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.isCancel = this.orderstatusresponse.orderstatus[0].is_eligible_for_cancel;

            if (!this.isCancel) {
              this.message = "We have observed that your order has been shipped already. You cannot cancel " +
                "the order because of that. But you can refurnd or replace the same once it has been " +
                "deliver to you. Please feel free to contact us for any queries or concern at 'info@khoaihaat.com'."
            }
          }
        });
  }

  viewOrderDetails(orderid: string, orderitemid: string) {

    var encryptorderid = this.encryptService.set(Utility.secretKey, orderid);
    var encryptordervalue = encodeURIComponent(encryptorderid);

    var encryptorderitem = this.encryptService.set(Utility.secretKey, orderitemid);
    var encryptorderitemvalue = encodeURIComponent(encryptorderitem);

    this.routerService.routeToOrderDetails(encryptordervalue, encryptorderitemvalue);
  }

  cancelItem(value, orderid: string, orderitemid: string) {
    var useremail: string = localStorage.getItem('user');

    if (value == "YES") {
      let orderdata = {
        "order_id": orderid,
        "order_item_id": orderitemid,
        "useremail": useremail
      }

      this.submitCancelSub = this.orderService.cancelOrderItem(orderdata)
        .subscribe(
          data => {
            data = this.cancelorderresponse = data;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.cancelorderresponse['message'] == 'DOWN') {
              this.routerService.routeToTryAgainPage();
            } else {
              this.cancelOrderSNSSub = this.orderService.sendCancelOrderInfoToSNS(orderdata)
                .subscribe(
                  data => {
                    data = this.cancelsnsresp = data;
                    // IF block will execute only when the corresponding service is down or not
                    // getting any response
                    if (this.cancelsnsresp['message'] == 'DOWN') {
                      this.routerService.routeToTryAgainPage();
                    } else {
                      this.routerService.routeToMyOrders();
                    }
                  });
            }
          });
    } else {
      this.routerService.routeToMyOrders();
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.cancelOrderSub) {
      this.cancelOrderSub.unsubscribe();
    }
    if (this.submitCancelSub) {
      this.submitCancelSub.unsubscribe();
    }
    if (this.cancelOrderSNSSub) {
      this.cancelOrderSNSSub.unsubscribe();
    }
  }

}
