import { Component, OnInit } from '@angular/core';
import { RouterService } from '../services/router.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header-error-custom',
  templateUrl: './header-error-custom.component.html',
  styleUrls: ['./header-error-custom.component.css']
})
export class HeaderErrorCustomComponent implements OnInit {

  submitted = false;
  keyword: string;
  searchitem = new FormControl('', Validators.required);

  searchForm = new FormGroup({
    searchitem: this.searchitem
  });

  constructor(
    private routerService: RouterService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.searchForm.controls; }

  ngOnInit() {
  }

  goToHome() {
    this.routerService.routeToHome();
  }

  refreshMyOrder() {
    //window.location.replace('/myorders');
    this.routerService.routeToMyOrders();
  }

  refreshMyProfile() {
    //window.location.replace('/myprofile');
    this.routerService.routeToMyProfile();
  }

  refreshMyAddress() {
    //window.location.replace('/myaddresses');
    this.routerService.routeToMyAddress();
  }

  refreshWishList() {
    //window.location.replace('/wishlistview');
    this.routerService.routeToMyWishList();
  }

  onSubmit() {
    this.submitted = true;

    // Return if form is invalid
    if (this.searchForm.invalid) {
      return;
    }

    this.keyword = this.f.searchitem.value;

    /**
       * For Google Analytics to track search event
       */

    this
      .googleAnalyticsService
      .eventEmitter("search", "engagement", this.keyword, "click", 3);

    /**
     * ***********************************************
     */

    //window.location.replace('/search?w=' + this.keyword + '&mode=srch');
    this.routerService.routeToSearch(this.keyword, 'srch');

  }

}
