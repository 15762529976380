import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { ModalService } from '../../modal-window/modal.service';
import { ProductService } from '../../services/product.service';
import { CustomerService } from '../../services/customer.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-reone-product',
  templateUrl: './home-reone-product.component.html',
  styleUrls: ['./home-reone-product.component.css']
})
export class HomeReoneProductComponent implements OnInit {

  displayStatus: string;
  displayPage: string;
  products: any[];
  cartresponse: any;
  wishlistresponse: any;
  submitMessage: string;
  wishlistaddmessage: string;
  hideSuccessMessage: boolean = true;
  homeNewOffer: Subscription;
  wishlistadd: Subscription;

  constructor(
    private modalService: ModalService,
    private router: RouterService,
    private authService: AuthenticationService,
    private customerService: CustomerService,
    private productService: ProductService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private routerapi: Router
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.displayStatus = "REONE"; //Will extract all products marked as NEW
    this.displayPage = "HOME" //Will display in home page
    // this.homeNewOffer = this.productService.getAllHomeProductList(this.displayStatus, this.displayPage)
    //   .subscribe(
    //     response => {
    //       response = this.products = response;
    //       // IF block will execute only when the corresponding service is down or not
    //       // getting any response
    //       if (this.products['message'] == 'DOWN') {
    //         this.router.routeToTryAgainPage();
    //       }
    //     });
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  getProductList(categoryname: string) {
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.router.routeToSearch(categoryname, 'catgry');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  viewAllProductList(dstatus: string) {
    this.router.routeToProductsGridDisplay(dstatus);
  }

  addToWishlist(pid: number) {

    /**
       * For Google Analytics to track add-to-wishlist event
       */

    this
      .googleAnalyticsService
      .eventEmitter("add_to_wishlist", "shop", "wishlist", "click", 9);

    /**
     * ***********************************************
     */

    let cartdata = {
      "wishlist_items_details": [
        {
          "product_id": pid,
          "item_size": ""
        }
      ]
    }

    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.wishlistadd = this.customerService.addToWishList(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.wishlistresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.wishlistresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.hideSuccessMessage = false;
            }
          });
    } else {
      localStorage.setItem("wishlistdata", JSON.stringify(cartdata));
      this.router.routeToWishList();
    }
  }

  getProductByMenu(displaystatus: string) {

    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", displaystatus, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.routerapi.navigate(['/search'], { queryParams: { w: displaystatus, mode: 'menu' } });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.homeNewOffer) {
      this.homeNewOffer.unsubscribe();
    }

    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
  }

}
