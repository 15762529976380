import { Component, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

declare var $: any

import { ProductService } from '../../services/product.service';
import { CustomerService } from '../../services/customer.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';
import { UtilityService } from '../../services/utility.service';
import { OrderService } from '../../services/order.service';
import { Product, ProductPricing, ProductImage, ProductReview, ProductStock, ProductAttributes, ProductAttributesStockPrice } from '../../models/product.model';

declare let gtag: Function;

@Component({
  selector: 'app-product-info-details',
  templateUrl: './product-info-details.component.html',
  styleUrls: ['./product-info-details.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductInfoDetailsComponent implements OnInit, OnDestroy {

  pageName: string = "viewinfo";
  productid: number;
  productdata: Product;
  pricedata: ProductPricing;
  imagedata: ProductImage;
  reviewdata: ProductReview[];
  stockdata: ProductStock;
  attributesdata: ProductAttributes[];
  attributestockpricedata: ProductAttributesStockPrice[];
  productcategorydata: any;
  cartresponse: any;
  submitMessage: string;
  wishlistresponse: any;
  similarproductsresponse: any;
  hideSuccessMessage: boolean = true;
  isMultipleSizeAvailable = false;
  isitemsizenotfound: boolean = true;
  isThumbnailImage4Available: boolean = false;
  isThumbnailImage5Available: boolean = false;
  isVideoFilePathAvailable: boolean = false;
  productInfo: Subscription;
  wishlistadd: Subscription;
  similarproductssub: Subscription;
  delhiveryservicechecksub: Subscription;
  listofpromocodesub: Subscription;

  pname: string;
  pshortdesc: string;
  plongdesc: string;
  skucode: string;
  pcategoryname: string;

  newprice: number;
  oldprice: number;
  discountvalue: number;
  discountpercentage: number;

  iconimage: string;
  inconimagezoom: string;
  thumbnailImage1: string;
  thumbnailImage2: string;
  thumbnailImage3: string;
  thumbnailImage4: string;
  thumbnailImage5: string;
  videofilepath: string;

  totalreviewcount: number;
  ratingvalue: number;

  productquantity: number;
  isOutOfStock: boolean;
  message: string;
  itemsizeinfo: string;

  scount: number;
  acount: number;
  catcount: number;
  itemactive: any;
  item1: any;
  item2: any;
  item3: any;
  item4: any;

  isItemActive: boolean;
  isItem1: boolean;
  isItem2: boolean;
  isItem3: boolean;
  isItem4: boolean;

  show: boolean = false;

  selectedMessage: any;
  brand: string;
  sold_by: string;
  mindeliverydate: string;
  maxdeliverydate: string;
  isFree: string;

  msg: string;
  isdigitonly: boolean = true;
  iscodavailable: boolean = false;
  delhiveryserviceresponse: any;
  listofpromocoderesponse: any;
  pcodelistdata: any;

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(
    private router: ActivatedRoute,
    private routerService: RouterService,
    private customerService: CustomerService,
    private authService: AuthenticationService,
    private productService: ProductService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilityservice: UtilityService,
    private orderservice: OrderService
  ) {
    //debugger;
  }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.router.queryParams
      .filter(params => params.pid)
      .subscribe(params => {
        this.productid = params.pid;
      });

    if (this.productid == undefined) {
      this.routerService.routeToTryAgainPage();
    } else {
      try {
        var p_id = decodeURIComponent(this.productid.toString())
        this.productid = this.encryptService.get(Utility.secretKey, p_id);
        if (this.productid.toString() == '') {
          this.routerService.routeToTryAgainPage();
        }
      } catch (error) {
        this.routerService.routeToTryAgainPage();
      }
    }

    // To calculate delivery date based on the current date
    let date1: Date = new Date();
    let datePipe: DatePipe = new DatePipe('en-US');

    date1.setDate(date1.getDate() + 4);
    this.mindeliverydate = datePipe.transform(date1, 'EEEE d MMMM y');

    let date2: Date = new Date();
    date2.setDate(date2.getDate() + 7);
    this.maxdeliverydate = datePipe.transform(date2, 'EEEE d MMMM y');
    this.getListofOffers();
    this.productInfo = this.productService.getProductDetailsById(this.productid)
      .subscribe(
        data => {
          this.productdata = data;
          this.attributestockpricedata = this.productdata.productattributestockpriceassoc;

          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.productdata['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.pname = this.productdata.product_name;
            this.pshortdesc = this.productdata.product_short_descr;
            this.plongdesc = this.productdata.product_long_descr;
            this.skucode = this.productdata.sku_code;
            this.brand = this.productdata.brand;
            this.sold_by = this.productdata.sold_by;

            // this.pricedata = this.productdata.producpricing;
            // if (this.pricedata[0] != null) {
            //   this.newprice = this.pricedata[0].sell_price;
            //   this.oldprice = this.pricedata[0].actual_price;
            // } else {
            //   this.newprice = 0;
            //   this.oldprice = 0;
            // }

            this.imagedata = this.productdata.productimages;
            this.iconimage = this.imagedata[0].icon_image_path;
            this.thumbnailImage1 = this.imagedata[0].thumbnail_image1_path;
            this.thumbnailImage2 = this.imagedata[0].thumbnail_image2_path;
            this.thumbnailImage3 = this.imagedata[0].thumbnail_image3_path;
            this.thumbnailImage4 = this.imagedata[0].thumbnail_image4_path;

            if ((this.thumbnailImage4 == "") || (this.thumbnailImage4 == undefined) || (this.thumbnailImage4 == null)) {
              this.isThumbnailImage4Available = false;
            } else {
              this.isThumbnailImage4Available = true;
            }
            this.thumbnailImage5 = this.imagedata[0].thumbnail_image5_path;

            if ((this.thumbnailImage5 == "") || (this.thumbnailImage5 == undefined) || (this.thumbnailImage5 == null)) {
              this.isThumbnailImage5Available = false;
            } else {
              this.isThumbnailImage5Available = true;
            }

            this.videofilepath = this.imagedata[0].video_file_path;

            if ((this.videofilepath == "") || (this.videofilepath == undefined) || (this.videofilepath == null)) {
              this.isVideoFilePathAvailable = false;
            } else {
              this.isVideoFilePathAvailable = true;
            }

            setTimeout(() => {
              this.getImageLoad();
              this.show = true;
            }, 1000);

            this.reviewdata = this.productdata.productreviewrating;
            this.totalreviewcount = this.reviewdata.length;
            var x = this.totalreviewcount;
            var y: number = +x;
            if (this.totalreviewcount != 0 && this.totalreviewcount != null) {
              var sum_rating: number = 0;

              for (let i = 0; i < y; i++) {
                var rating: number = this.reviewdata[i].rating;

                sum_rating = Number.parseInt(sum_rating.toString()) + Number.parseFloat(rating.toString());
              }
              var avg = sum_rating / x;
              var calculatedRating = avg * 20;
              this.ratingvalue = calculatedRating;
            } else {
              this.ratingvalue = 0;
            }

            // this.stockdata = this.productdata.productstock;
            // if ((this.stockdata[0].quantity != null) && (this.stockdata[0].quantity != 0)) {
            //   this.productquantity = this.stockdata[0].quantity;
            //   this.isOutOfStock = false;
            // } else {
            //   this.productquantity = 0;
            //   this.isOutOfStock = true;
            // }

            this.acount = this.attributestockpricedata.length;
            var x = this.acount;
            var y: number = +x;
            if (this.acount == 1) {
              this.isMultipleSizeAvailable = false;
            } else {
              this.isMultipleSizeAvailable = true;
            }
            for (let i = 0; i < y; i++) {
              if (this.attributestockpricedata[i]['quantity'] > 0) {
                this.newprice = this.attributestockpricedata[i]['sell_price']
                this.oldprice = this.attributestockpricedata[i]['actual_price']

                //calculate discount %
                this.discountvalue = this.oldprice - this.newprice;

                this.discountpercentage = Math.round((this.discountvalue / this.oldprice) * 100);

                this.productquantity = this.attributestockpricedata[i]['quantity'];
                this.itemsizeinfo = this.attributestockpricedata[i]['attribute_value'];
                localStorage.setItem("itemsize_default", this.itemsizeinfo);
                this.isOutOfStock = false;
              } else {
                this.productquantity = 0;
                this.isOutOfStock = true;
              }
            }

            if (this.newprice > 300) {
              this.isFree = "FREE";
            } else {
              this.isFree = "";
            }

            //For Google Analytics Feed

            this.attributesdata = this.productdata.productattributeassoc;
            this.productcategorydata = this.productdata['productcategoryassoc'];
            this.catcount = this.productdata['productcategoryassoc'].length;
            var s = this.catcount;

            for (let i = 0; i < s; i++) {
              this.pcategoryname = this.productcategorydata[i]['prod_category_name'];
            }

            gtag('event', 'view_item', {
              "items": [
                {
                  "id": this.productdata.sku_code,
                  "name": this.productdata.product_name,
                  "brand": "Khoaihaat",
                  "category": this.pcategoryname,
                  "variant": this.itemsizeinfo,
                  "list_position": 1,
                  "quantity": this.productquantity,
                  "price": this.newprice
                }
              ]
            });

            // The following function will get all similar products details
            this.getSimilarProducts(this.skucode, this.productid);
          }
        }
      )

  }

  getImageLoad() {
    $(document).ready(function () {
      $('#product-zoom').elevateZoom({
        zoomType: "window",
        responsive: true,
        cursor: "crosshair",
        galleryActiveClass: "active",
        zoomWindowFadeIn: 500,
        zoomWindowFadeOut: 750,
        gallery: 'gallery_01'
      });

      $("#gallery_01 .slider-items").owlCarousel({
        autoplay: false,
        items: 3, //10 items above 1000px browser width
        itemsDesktop: [1024, 3], //5 items between 1024px and 901px
        itemsDesktopSmall: [900, 2], // 3 items betweem 900px and 601px
        itemsTablet: [600, 3], //2 items between 600 and 0;
        itemsMobile: [320, 2],
        navigation: true,
        navigationText: ["<a class=\"flex-prev\"></a>", "<a class=\"flex-next\"></a>"],
        slideSpeed: 500,
        pagination: false
      });
    });

  }

  addToCart(pid: number, price: number, itemcount: number) {
    localStorage.setItem("isLoadCartPage", "Y");
    var itemSize: string = "";

    if (itemcount == 1) {
      itemSize = localStorage.getItem("itemsize_default");
    } else if (itemcount > 1) {
      itemSize = localStorage.getItem("itemsize");
    } else {
      this.isitemsizenotfound = false;
    }

    if ((itemSize == null) && (itemcount != 1)) {
      this.isitemsizenotfound = false;
    } else {
      let cartdata = {
        "cart_items_details": [
          {
            "product_id": pid,
            "quantity": 1,
            "price": price,
            "item_size": itemSize
          }
        ]
      }
      const currentUser = this.authService.currentUserValue;

      /**
       * For Google Analytics to track add-to-cart event
       */

      this
        .googleAnalyticsService
        .eventEmitter("add_to_cart", "shop", "cart", "click", 10);

      /**
       * ***********************************************
       */

      if (currentUser) {
        localStorage.removeItem("cartdata");
        this.customerService.addToCart(cartdata, this.custId)
          .subscribe(
            response => {
              response = this.cartresponse = response;
              // IF block will execute only when the corresponding service is down or not
              // getting any response
              if (this.cartresponse['message'] == 'DOWN') {
                this.routerService.routeToTryAgainPage();
              } else {
                //window.location.replace('/shoppingcartview');
                this.routerService.routeToShoppingCart();
              }
            });
      } else {
        localStorage.setItem("cartdata", JSON.stringify(cartdata));
        this.routerService.routeToShoppingCart();
        //window.location.replace('/shoppingcartview');
      }
    }
  }

  proceedToCheckout(pid: string, page: string, itemcount: number) {

    /**
       * For Google Analytics to track begin_checkout event
       */

    this
      .googleAnalyticsService
      .eventEmitter("begin_checkout", "ecommerce", "product", "click", 8);

    /**
     * ***********************************************
     */

    var itemSize: string = "";
    if (itemcount == 1) {
      itemSize = localStorage.getItem("itemsize_default");
    } else if (itemcount > 1) {
      itemSize = localStorage.getItem("itemsize");
    } else {
      this.isitemsizenotfound = false;
    }
    if ((itemSize == null) && (itemcount != 1)) {
      this.isitemsizenotfound = false;
    } else {
      localStorage.setItem("product_id", pid);
      localStorage.setItem("itemsizeforbuynow", itemSize);
      const currentUser = this.authService.currentUserValue;
      if (currentUser) {
        //window.location.replace('/checkout?page=viewinfo');
        this.routerService.routeToCheckOut(page);
      } else {
        localStorage.setItem("checkoutmode", "viewinfo");
        //window.location.replace('/checkout');
        this.routerService.routeToCheckOutWithoutLogin(page);
      }
    }
  }

  addToWishlist(pid: number, itemcount: number) {
    /**
       * For Google Analytics to track add-to-wishlist event
       */

    this
      .googleAnalyticsService
      .eventEmitter("add_to_wishlist", "shop", "wishlist", "click", 9);

    /**
     * ***********************************************
     */

    var itemSize: string = "";
    if (itemcount == 1) {
      itemSize = localStorage.getItem("itemsize_default");
    } else if (itemcount > 1) {
      itemSize = localStorage.getItem("itemsize");
    } else {
      this.isitemsizenotfound = false;
    }
    if ((itemSize == null) && (itemcount != 1)) {
      this.isitemsizenotfound = false;
    } else {
      let cartdata = {
        "wishlist_items_details": [
          {
            "product_id": pid,
            "item_size": itemSize
          }
        ]
      }

      const currentUser = this.authService.currentUserValue;
      if (currentUser) {
        localStorage.removeItem("wishlistdata");
        this.wishlistadd = this.customerService.addToWishList(cartdata, this.custId)
          .subscribe(
            response => {
              response = this.wishlistresponse = response;
              // IF block will execute only when the corresponding service is down or not
              // getting any response
              if (this.wishlistresponse['message'] == 'DOWN') {
                this.routerService.routeToTryAgainPage();
              } else {
                this.hideSuccessMessage = false;
              }
            });
      } else {
        localStorage.setItem("wishlistdata", JSON.stringify(cartdata));
        this.routerService.routeToWishList();
      }
    }
  }

  getSimilarProducts(scode: string, productId: number) {
    this.similarproductssub = this.productService.getAllSimilarProductList(scode, productId)
      .subscribe(
        response => {
          response = this.similarproductsresponse = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.similarproductsresponse['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.scount = this.similarproductsresponse.length;
            var x = this.scount;
            var y: number = +x;
            for (let i = 0; i < y; i++) {
              if (this.similarproductsresponse[i]['item_active'] != undefined) {
                this.isItemActive = true;
                this.itemactive = this.similarproductsresponse[i]['item_active'];
              }
              if (this.similarproductsresponse[i]['item1'] != undefined) {
                this.isItem1 = true;
                this.item1 = this.similarproductsresponse[i]['item1'];
              }
              if (this.similarproductsresponse[i]['item2'] != undefined) {
                this.isItem2 = true;
                this.item2 = this.similarproductsresponse[i]['item2'];
              }
              if (this.similarproductsresponse[i]['item3'] != undefined) {
                this.isItem3 = true;
                this.item3 = this.similarproductsresponse[i]['item3'];
              }
              if (this.similarproductsresponse[i]['item4'] != undefined) {
                this.isItem4 = true;
                this.item4 = this.similarproductsresponse[i]['item4'];
              }
            }
          }
        });
  }

  submitReview(pid) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);
    //window.location.replace('/myreviews?pid=' + encodevalue);
    this.routerService.routeToMyReview(encodevalue);
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  FadeOutFailedMsg() {
    setTimeout(() => {
      this.isitemsizenotfound = true;
    }, 2500);
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  selectProductSpecification(val: string, pattrdata: any) {
    this.scount = pattrdata.length;
    var x = this.scount;
    var y: number = +x;
    localStorage.removeItem("itemsize");
    for (let i = 0; i < y; i++) {
      if (val == pattrdata[i]['attribute_value']) {
        this.newprice = pattrdata[i]['sell_price'];
        this.oldprice = pattrdata[i]['actual_price'];
        this.itemsizeinfo = pattrdata[i]['attribute_value'];
        localStorage.setItem("itemsize", pattrdata[i]['attribute_value']);
        if ((pattrdata[i]['quantity'] != null) && (pattrdata[i]['quantity'] != 0)) {
          this.productquantity = pattrdata[i]['quantity'];
          this.isOutOfStock = false;
        } else {
          this.productquantity = 0;
          this.isOutOfStock = true;
        }
      }
    }
    //calculate discount %
    this.discountvalue = this.oldprice - this.newprice;

    this.discountpercentage = Math.round((this.discountvalue / this.oldprice) * 100);

  }

  checkPinCode(pcode): boolean {
    if (pcode == '') {
      this.iscodavailable = true;
      this.msg = "Enter 6-digit pincode only";
      return;
    }
    if (pcode.length == 6) {
      this.delhiveryservicechecksub = this.utilityservice.getDelhiveryPinCodeServiceDetails(pcode)
        .subscribe(
          response => {
            response = this.delhiveryserviceresponse = response;
            var isCOD = this.delhiveryserviceresponse['cod']
            if (isCOD == 'Y') {
              this.iscodavailable = true;
              this.msg = "Cash-On-Delivery AVAILABLE";
            } else {
              this.iscodavailable = true;
              this.msg = "Cash-On-Delivery NOT available";
            }
          });
    } else {
      this.iscodavailable = true;
      this.msg = "Enter 6-digit pincode only";
    }

  }

  getListofOffers() {
    this.listofpromocodesub = this.orderservice.getListOfActivePromoCode()
      .subscribe(
        response => {
          response = this.listofpromocoderesponse = response;
          this.pcodelistdata = this.listofpromocoderesponse['promocodelist']
        });
  }

  FadeOutIsDigitOnlyFailedMsg() {
    setTimeout(() => {
      this.isdigitonly = true;
    }, 2500);
  }

  FadeOutIsCODMsg() {
    setTimeout(() => {
      this.iscodavailable = false;
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    localStorage.removeItem("itemsize");
    localStorage.removeItem("itemsize_default");
    if (this.productInfo) {
      this.productInfo.unsubscribe();
    }
    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
    if (this.similarproductssub) {
      this.similarproductssub.unsubscribe();
    }
    if (this.delhiveryservicechecksub) {
      this.delhiveryservicechecksub.unsubscribe();
    }
    if (this.listofpromocodesub) {
      this.listofpromocodesub.unsubscribe();
    }
  }

}
