import { NgModule } from '@angular/core';

import { MyOrderdetailsPageRoutingModule } from './my-orderdetails-page-routing.module';
import { MyOrderdetailsPageComponent } from './my-orderdetails-page.component';
import { MyOrderdetailsInfoComponent } from '../customer-component/my-orderdetails-info/my-orderdetails-info.component';

import SharedModule from '../shared.module';
import CustomerAccountRightNavModule from '../modules/customer-account-right-nav.module';

@NgModule({
    declarations: [
        MyOrderdetailsPageComponent,
        MyOrderdetailsInfoComponent
    ],
    imports: [
        SharedModule,
        MyOrderdetailsPageRoutingModule,
        CustomerAccountRightNavModule
    ],
    providers: []
})
export class MyOrderdetailsPageModule { }