import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../services/utility.service';
import { OrderService } from '../../services/order.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-track-orders-info',
  templateUrl: './track-orders-info.component.html',
  styleUrls: ['./track-orders-info.component.css']
})
export class TrackOrdersInfoComponent implements OnInit, OnDestroy {

  submitted = false;
  deliverystatuschecksub: Subscription;
  findawbnumbersub: Subscription;
  deliverystatusresponse: any;
  awbnumberresponse: any;
  messageerror: string;
  awbNumber: string;
  hideErrorMessage: boolean = true;
  message: string;
  show: boolean = false;

  //package related properties based on Delhivery API service

  packagedestination: string;
  ordertype: string;
  pickupdate: string;
  deliverydate: string;
  destreceivedate: string;
  expecteddeliverydate: string;
  promisedeliverydate: string;
  originreceivedate: string;
  finalstatus: string;
  statuslocation: string;
  statusdatetime: string;
  receivedby: string;
  statuscode: string;
  statustype: string;
  instruction: string;
  packagelivetracker: any;



  trackradiooption1 = new FormControl('', Validators.required);
  tracknumber = new FormControl('', Validators.required);

  track_order = new FormGroup({
    trackradiooption1: this.trackradiooption1,
    tracknumber: this.tracknumber
  });

  constructor(
    private utilityservice: UtilityService,
    private orderService: OrderService
  ) { }

  // convenience getter for easy access to form fields
  get f() {
    return this.track_order.controls;
  }

  ngOnInit() { }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.track_order.invalid) {
      return;
    }

    var radiooptionflag = this.f.trackradiooption1.value;

    if (radiooptionflag == 'ON') { //If the value eneterd as Order Number
      this.getAWBNumberUsingOrderNumber(this.f.tracknumber.value);
    } else {
      this.getDeliveryStatusByAWB(this.f.tracknumber.value);
    }
  }

  getDeliveryStatusByAWB(awbnumber: string) {
    this.deliverystatuschecksub = this.utilityservice.getDeliveryStatusByAWBDetails(awbnumber)
      .subscribe(
        response => {
          response = this.deliverystatusresponse = response;

          this.messageerror = this.deliverystatusresponse['message'];
          if (this.messageerror) { // AWB, Order Number Not found
            this.message = "The order status NOT being updated yet, please check later. Sorry for the inconvenience."
            this.hideErrorMessage = false;
            this.show = false;
          } else {                                    //Response received from Courier
            //TO DO
            this.hideErrorMessage = true;
            this.show = true;
            this.packagedestination = this.deliverystatusresponse['destination'];
            this.ordertype = this.deliverystatusresponse['ordertype'];
            this.pickupdate = this.getDateTimeFormatted(this.deliverystatusresponse['pickupdate']);
            this.deliverydate = this.getDateTimeFormatted(this.deliverystatusresponse['deliverydate']);
            this.destreceivedate = this.getDateTimeFormatted(this.deliverystatusresponse['destreceivedate']);
            this.expecteddeliverydate = this.getDateTimeFormatted(this.deliverystatusresponse['expecteddeliverydate']);
            this.promisedeliverydate = this.getDateTimeFormatted(this.deliverystatusresponse['promisedeliverydate']);
            this.originreceivedate = this.getDateTimeFormatted(this.deliverystatusresponse['originreceivedate']);
            this.finalstatus = this.deliverystatusresponse['finalstatus'];
            this.statuslocation = this.deliverystatusresponse['statuslocation'];
            this.statusdatetime = this.deliverystatusresponse['statusdatetime'];
            this.receivedby = this.deliverystatusresponse['receivedby'];
            this.statuscode = this.deliverystatusresponse['statuscode'];
            this.statustype = this.deliverystatusresponse['statustype'];
            this.instruction = this.deliverystatusresponse['instruction'];

            this.packagelivetracker = this.deliverystatusresponse['packagelivetracker'];
          }
        });
  }

  getDateTimeFormatted(dtvalue: string) {
    let formattedDt = "";
    if (dtvalue != null || dtvalue == 'undefined') {
      formattedDt = formatDate(dtvalue, 'dd-MM-yyyy hh:mm', 'en_US');
    } else {
      formattedDt = "";
    }

    return formattedDt
  }

  getAWBNumberUsingOrderNumber(ordernumber: string) {
    this.findawbnumbersub = this.orderService.getAWBNumberUsingOrderNumber(ordernumber)
      .subscribe(
        response => {
          response = this.awbnumberresponse = response;

          this.messageerror = this.awbnumberresponse['message'];
          if (this.messageerror) { // AWB Number Not found
            //TO DO
            this.message = "The Order/AWB Number Not found in our system, please check later"
            this.hideErrorMessage = false;
            this.show = false;
          } else { //Response received from DB

            if (this.awbnumberresponse['order_tracking_number'] != 0) { //Order Number found in DB
              this.hideErrorMessage = true;
              this.show = true;
              this.getDeliveryStatusByAWB(this.awbnumberresponse['order_tracking_number']);

            } else {
              //TO DO
              this.message = "The Order/AWB Number Not found in our system, please check later"
              this.hideErrorMessage = false;
              this.show = false;
            }
          }
        });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideErrorMessage = true;
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.deliverystatuschecksub) {
      this.deliverystatuschecksub.unsubscribe();
    }
    if (this.findawbnumbersub) {
      this.findawbnumbersub.unsubscribe();
    }
  }

}
