import { NgModule } from '@angular/core';

import { SellOnKhoaihaatPageRoutingModule } from './sell-on-khoaihaat-page-routing.module';
import { SellOnKhoaihaatPageComponent } from './sell-on-khoaihaat-page.component';
import { SellOnKhoaihaatInfoComponent } from '../general-component/sell-on-khoaihaat-info/sell-on-khoaihaat-info.component';

import SharedModule from '../shared.module';
import GeneralNavModule from '../modules/general-nav.module';

@NgModule({
    declarations: [
        SellOnKhoaihaatPageComponent,
        SellOnKhoaihaatInfoComponent
    ],
    imports: [
        SharedModule,
        GeneralNavModule,
        SellOnKhoaihaatPageRoutingModule
    ],
    providers: []
})
export class SellOnKhoaihaatPageModule { }