import { NgModule } from '@angular/core';

import { RefundReplaceConfirmPageRoutingModule } from './refund-replace-confirm-page-routing.module';
import { RefundReplaceConfirmPageComponent } from './refund-replace-confirm-page.component';
import { RefundReplaceConfirmInfoComponent } from '../customer-component/refund-replace-confirm-info/refund-replace-confirm-info.component';

import SharedModule from '../shared.module';

@NgModule({
    declarations: [
        RefundReplaceConfirmPageComponent,
        RefundReplaceConfirmInfoComponent
    ],
    imports: [
        SharedModule,
        RefundReplaceConfirmPageRoutingModule
    ],
    providers: []
})
export class RefundReplaceConfirmPageModule { }