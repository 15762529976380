import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";

import { CustomerAccountRightNavComponent } from '../customer-component/customer-account-right-nav/customer-account-right-nav.component';

@NgModule({
    imports: [CommonModule,
        ReactiveFormsModule],
    declarations: [
        CustomerAccountRightNavComponent
    ],
    exports: [
        CustomerAccountRightNavComponent,
        ReactiveFormsModule,
        CommonModule
    ]
})
export default class CustomerAccountRightNavModule {}