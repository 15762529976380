import { NgModule } from '@angular/core';

import { UnknownErrorPageRoutingModule } from './unknown-error-page-routing.module';
import { UnknownErrorPageComponent } from './unknown-error-page.component';

import SharedModule from '../shared.module';

@NgModule({
    declarations: [
        UnknownErrorPageComponent
    ],
    imports: [
        SharedModule,
        UnknownErrorPageRoutingModule
    ],
    providers: []
})
export class UnknownErrorPageModule { }