import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilityService } from '../services/utility.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { RouterService } from '../services/router.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-top',
  templateUrl: './navigation-top.component.html',
  styleUrls: ['./navigation-top.component.css']
})
export class NavigationTopComponent implements OnInit {

  productlist: Subscription;
  submitMessage: string
  productlistresponse: any[]
  loadAPI: Promise<any>;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private routerService: RouterService,
    private router: Router
  ) { }

  ngOnInit() {
    //this.getProductList('6');
    //this.getAllMenuItems();
  }

  // getAllMenuItems() {
  //   this.menudata = this.utilityService.getAllMenu()
  //     .subscribe(
  //       data => {
  //         this.menucategories = data;
  //         this.categories = this.menucategories['menu'];
  //         console.log(this.categories[0]['L0'].category_name)
  //         console.log(this.menucategories['menu'][0]['sub-category'])
  //         console.log(this.menucategories['menu'][0]['sub-category'][0])
  //       });
  // }

  getProductList(categoryname: string) {
    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", categoryname, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.router.navigate(['/search'], { queryParams: { w: categoryname, mode: 'catgry' } });
  }

  getProductByMenu(displaystatus: string) {

    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", displaystatus, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.router.navigate(['/search'], { queryParams: { w: displaystatus, mode: 'menu' } });
  }

  viewAllProductList(spclcategory: string) {
    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", spclcategory, "click", 3);

    this.routerService.routeToProductsListDisplay(spclcategory);
  }

  goToHomePage() {
    //window.location.replace('/homeview');
    this.routerService.routeToHome();
  }

  goToTrackOrderPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToTrackOrder();
  }

  goToBulkOrderPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToBulkOrder();
  }

  goToSell() {
    //window.location.replace('/homeview');
    this.routerService.routeToSellOnKhoaihaat();
  }

  goToSecondLevelNavPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToSecondLevelNavPage();
  }

}
