import { Component, ViewChild, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, FormGroupDirective, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Subscription } from 'rxjs';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angular4-social-login';

import { ModalService } from '../../modal-window/modal.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CustomerService } from '../../services/customer.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-login-main-info',
  templateUrl: './login-main-info.component.html',
  styleUrls: ['./login-main-info.component.css']
})
export class LoginMainInfoComponent implements OnInit, OnDestroy {

  customeremail = new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]);
  password = new FormControl('', Validators.required);
  submitMessage: string;
  returnUrl: string;
  custId: string;
  custemail: string;
  custtoken: string;
  logintype: string;
  socialtoken: string;
  isEmailPresent: boolean;
  responsedata: any;

  user: SocialUser;
  loggedIn: boolean = false;

  validateemail: Subscription;
  authservicesub: Subscription;
  sessionsub: Subscription;

  loginForm = new FormGroup({
    email: this.customeremail,
    password: this.password
  });
  loading = false;
  submitted = false;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private modalService: ModalService,
    private routerService: RouterService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private socialAuthService: AuthService,
    private userService: CustomerService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.routerService.routeToHome();
    }
  }

  ngOnInit() {
    this.loggedIn = false;
    // This block is for Facebook,Google oAuth2 login
    this.socialAuthService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);

      // If the user logged in successfully using facebook/google
      if (user != null) {
        let userdata = {
          "email": this.user.email,
          "password": this.user.email
        }

        this.validateemail = this.userService.checkUserEmail(this.user.email)
          .subscribe(
            data => {
              data = this.isEmailPresent = data;
              if (this.isEmailPresent) { // If the user email already present in the DB

                this.authenticationService.loginUser(this.user.email, this.user.email)
                  .pipe(first())
                  .subscribe(
                    data => {
                      this.custId = this.authenticationService.getCustomerId();
                      this.custemail = this.user.email;
                      this.custtoken = this.authenticationService.getToken();
                      this.socialtoken = this.user.authToken;
                      this.logintype = "SOCIAL";
                      let ssdata = {
                        "customer_id": this.custId,
                        "jwt_token": this.custtoken,
                        "social_token": this.socialtoken,
                        "social_id": this.user.id,
                        "customer_email": this.custemail,
                        "customer_name": this.user.name,
                        "social_provider": this.user.provider,
                        "customer_login_type": this.logintype
                      }
                      // The service is responsible to insert user session data inside DB every time he/she logged in
                      this.sessionsub = this.userService.addSessionDetails(ssdata)
                        .subscribe(
                          data => {
                            data = this.responsedata = data;
                            localStorage.setItem("sessionid", this.responsedata.id);
                            localStorage.setItem("login_type", this.logintype);
                            localStorage.setItem("socialtoken", this.socialtoken);
                            this.routerService.routeToHome();
                          });
                    });
              } else { // If the user email not present inside DB
                this.validateemail = this.userService.registerSocial(userdata)
                  .pipe(first())
                  .subscribe(
                    data => {
                      this.authenticationService.loginUser(this.user.email, this.user.email)
                        .pipe(first())
                        .subscribe(
                          data => {
                            this.custId = this.authenticationService.getCustomerId();
                            this.custemail = this.user.email;
                            this.custtoken = this.authenticationService.getToken();
                            this.socialtoken = this.user.authToken;
                            this.logintype = "SOCIAL";
                            let ssdata = {
                              "customer_id": this.custId,
                              "jwt_token": this.custtoken,
                              "social_token": this.socialtoken,
                              "social_id": this.user.id,
                              "customer_email": this.custemail,
                              "customer_name": this.user.name,
                              "social_provider": this.user.provider,
                              "customer_login_type": this.logintype
                            }
                            // The service is responsible to insert user session data inside DB every time he/she logged in
                            this.sessionsub = this.userService.addSessionDetails(ssdata)
                              .subscribe(
                                data => {
                                  data = this.responsedata = data;
                                  localStorage.setItem("sessionid", this.responsedata.id);
                                  localStorage.setItem("login_type", this.logintype);
                                  localStorage.setItem("socialtoken", this.socialtoken);
                                  this.routerService.routeToHome();
                                });
                          });
                    });
              }
            }
          )
      }
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  loginSubmit() {
    this.submitted = true;

    // Return if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    /**
       * For Google Analytics to track login event
       */

    this
      .googleAnalyticsService
      .eventEmitter("login", "engagement", "method", "click", 6);

    /**
     * ***********************************************
     */

    this.loading = true;
    this.authservicesub = this.authenticationService.loginUser(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.route.queryParams
            .filter(params => params.returnUrl)
            .subscribe(params => {
              this.returnUrl = params.returnUrl;
            });
          this.custId = this.authenticationService.getCustomerId();
          this.custemail = this.authenticationService.getCustomer();
          this.custtoken = this.authenticationService.getToken();
          this.logintype = "ORGANIC";
          let sdata = {
            "customer_id": this.custId,
            "jwt_token": this.custtoken,
            "customer_email": this.custemail,
            "customer_login_type": this.logintype
          }
          // The service is responsible to insert user session data inside DB every time he/she logged in
          this.sessionsub = this.userService.addSessionDetails(sdata)
            .subscribe(
              data => {
                data = this.responsedata = data;
                localStorage.setItem("sessionid", this.responsedata.id);
                localStorage.setItem("login_type", this.logintype);
                this.routerService.routePath(this.returnUrl);
              });
        },
        error => {
          this.submitMessage = "Please check your username and password and try again.";
          this.loginForm.reset();
          this.formGroupDirective.resetForm();
        });
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.validateemail) {
      this.validateemail.unsubscribe();
    }
    if (this.authservicesub) {
      this.authservicesub.unsubscribe();
    }
    if (this.sessionsub) {
      this.sessionsub.unsubscribe();
    }
  }
}
