import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";

import { GridDisplayProductComponent } from './grid-list-component/grid-display-product/grid-display-product.component';
import { ListDisplayProductComponent } from "./grid-list-component/list-display-product/list-display-product.component";

@NgModule({
    imports: [CommonModule,
        ReactiveFormsModule],
    declarations: [
        GridDisplayProductComponent,
        ListDisplayProductComponent
    ],
    exports: [
        GridDisplayProductComponent,
        ListDisplayProductComponent,
        ReactiveFormsModule,
        CommonModule,
        FormsModule
    ]
})
export default class SharedGridListModule {}