import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';

import { CustomerService } from '../../services/customer.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';
import { User } from '../../models/user.model';
import { ModalService } from '../../modal-window/modal.service';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-my-profile-info',
  templateUrl: './my-profile-info.component.html',
  styleUrls: ['./my-profile-info.component.css']
})
export class MyProfileInfoComponent implements OnInit, OnDestroy {

  customerdata: Subscription;
  updatedata: Subscription;
  customer: User;
  submitted = false;
  submitMessage: string;
  hideSuccessMessage: boolean = true;

  first_name = new FormControl('');
  last_name = new FormControl('');
  phone_number = new FormControl('', [Validators.required, Validators.maxLength(10)]);
  email = new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]);
  responsemsg: string;
  strcontain: string;

  profileForm = new FormGroup({
    first_name: this.first_name,
    last_name: this.last_name,
    email: this.email,
    phone_number: this.phone_number,
  });

  constructor(
    private modalService: ModalService,
    private customerService: CustomerService,
    private authService: AuthenticationService,
    private routerService: RouterService
  ) { }

  custId: string = this.authService.getCustomerId();

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }

  ngOnInit() {
    this.getCustomerDetails();
  }

  getCustomerDetails() {
    this.customerdata = this.customerService.getCustomerInfo(this.custId)
      .subscribe(
        data => {
          data = this.customer = data;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.customer['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.profileForm.setValue(
              {
                first_name: this.customer.first_name,
                last_name: this.customer.last_name,
                email: this.customer.email,
                phone_number: this.customer.phone_number
              }
            )
          }

        });
  }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    let custdata = {
      "first_name": this.f.first_name.value,
      "last_name": this.f.last_name.value,
      "email": this.f.email.value,
      "phone_number": this.f.phone_number.value
    }

    this.customerdata = this.customerService.updateCustomerProfile(custdata, this.custId)
      .subscribe(
        data => {
          data = this.customer = data;
          
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.customer['message'] == 'DOWN') {
            this.routerService.routeToTryAgainPage();
          } else {
            this.responsemsg = this.customer['message'];
            this.strcontain = "Successfully"
            if (!this.responsemsg.includes(this.strcontain)) {
              this.submitMessage = "Something went wrong during, please check your phone number. It should be unique OR come back later"
              this.hideSuccessMessage = false;
            } else {
              this.submitMessage = "Profile has been updated successfully"
              this.hideSuccessMessage = false;
            }
          }
        });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 3000);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.customerdata) {
      this.customerdata.unsubscribe();
    }
    if (this.updatedata) {
      this.updatedata.unsubscribe();
    }
  }

}
