import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ModalService } from '../../modal-window/modal.service';
import { ProductService } from '../../services/product.service';
import { CustomerService } from '../../services/customer.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-main-slider',
  templateUrl: './home-main-slider.component.html',
  styleUrls: ['./home-main-slider.component.css']
})
export class HomeMainSliderComponent implements OnInit, OnDestroy {
  displayStatus: string;
  displayPage: string;
  specialproducts: any[];
  cartresponse: any;
  submitMessage: string;
  wishlistresponse: string;
  hideSuccessMessage: boolean = true;

  homeMainSlider: Subscription;
  homeSliderAddCart: Subscription;
  wishlistadd: Subscription;

  constructor(
    private modalService: ModalService,
    private customerService: CustomerService,
    private authService: AuthenticationService,
    private router: RouterService,
    private productService: ProductService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {
    this.displayStatus = "HOT"; //Will extract all products marked as NEW
    this.displayPage = "HOME" //Will display in home page
    this.homeMainSlider = this.productService.getAllHomeProductList(this.displayStatus, this.displayPage)
      .subscribe(
        response => {
          response = this.specialproducts = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.specialproducts['message'] == 'DOWN') {
            this.router.routeToTryAgainPage();
          }
        });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  addToCart(pid: number, price: number) {
    let cartdata = {
      "cart_items_details": [
        {
          "product_id": pid,
          "quantity": 1,
          "price": price,
          "item_size": ""
        }
      ]
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      localStorage.removeItem("cartdata");
      this.homeSliderAddCart = this.customerService.addToCart(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.cartresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.cartresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.router.routeToShoppingCart();
            }
          });
    } else {
      localStorage.setItem("cartdata", JSON.stringify(cartdata));
      this.router.routeToShoppingCart();
    }
  }

  addToWishlist(pid: number) {

    /**
       * For Google Analytics to track add-to-wishlist event
       */

    this
      .googleAnalyticsService
      .eventEmitter("add_to_wishlist", "shop", "wishlist", "click", 9);

    /**
     * ***********************************************
     */

    let cartdata = {
      "wishlist_items_details": [
        {
          "product_id": pid,
          "item_size": ""
        }
      ]
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.wishlistadd = this.customerService.addToWishList(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.wishlistresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.wishlistresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.hideSuccessMessage = false;
            }
          });
    } else {
      localStorage.setItem("wishlistdata", JSON.stringify(cartdata));
      this.router.routeToWishList();
    }

  }

  FadeOutSuccessMsg() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  getProductList(categoryname: string) {
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.router.routeToSearch(categoryname, 'catgry');
  }

  gotoLogin() {
    this.router.routeToLogin();
  }

  goToSecondLevelNavPage() {
    //window.location.replace('/homeview');
    this.router.routeToSecondLevelNavPage();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.homeMainSlider) {
      this.homeMainSlider.unsubscribe();
    }
    if (this.homeSliderAddCart) {
      this.homeSliderAddCart.unsubscribe();
    }
    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
  }

}
