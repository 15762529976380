import { NgModule } from '@angular/core';

import { ShoppingCartPageRoutingModule } from './shopping-cart-page-routing.module';

import SharedModule from '../shared.module';
import { ShoppingCartPageComponent } from './shopping-cart-page.component';
import { ProductCartDetailsComponent } from '../product-component/product-cart-details/product-cart-details.component';

@NgModule({
    declarations: [
        ShoppingCartPageComponent,
        ProductCartDetailsComponent],
    imports: [
        SharedModule,
        ShoppingCartPageRoutingModule],
    providers: []
})
export class ShoppingCartPageModule { }