import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngo-banner',
  templateUrl: './ngo-banner.component.html',
  styleUrls: ['./ngo-banner.component.css']
})
export class NgoBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goToNGO(url: string) {
    window.open(url, "_blank");
  }

}
