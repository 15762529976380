import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { ModalService } from '../../modal-window/modal.service';
import { ProductService } from '../../services/product.service';
import { RouterService } from '../../services/router.service';
import { CustomerService } from '../../services/customer.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EncrDecrService } from '../../services/encrdecr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utility } from '../../common/utility';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

declare var $: any

@Component({
  selector: 'app-home-special-product',
  templateUrl: './home-special-product.component.html',
  styleUrls: ['./home-special-product.component.css']
})
export class HomeSpecialProductComponent implements OnInit, OnDestroy {

  displayStatus: string;
  displayPage: string;
  specialproducts: any[];
  cartresponse: any;
  submitMessage: string;
  wishlistresponse: string;
  hideSuccessMessage: boolean = true;

  homeSpeacial: Subscription;
  homeSpecialAddCart: Subscription;
  wishlistadd: Subscription;

  constructor(
    private modalService: ModalService,
    private router: RouterService,
    private authService: AuthenticationService,
    private customerService: CustomerService,
    private productService: ProductService,
    private encryptService: EncrDecrService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private routerapi: Router
  ) { }

  custId: string = this.authService.getCustomerId();

  ngOnInit() {

    this.displayStatus = "IDOL"; //Will extract all products marked as SPECIAL
    this.displayPage = "HOME" //Will display in home page
    this.homeSpeacial = this.productService.getAllHomeProductList(this.displayStatus, this.displayPage)
      .subscribe(
        response => {
          response = this.specialproducts = response;
          // IF block will execute only when the corresponding service is down or not
          // getting any response
          if (this.specialproducts['message'] == 'DOWN') {
            this.router.routeToTryAgainPage();
          }
        });
  }

  getProductByMenu(displaystatus: string) {

    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", displaystatus, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.routerapi.navigate(['/search'], { queryParams: { w: displaystatus, mode: 'menu' } });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  viewAllProductList(dstatus: string) {
    this.router.routeToProductsGridDisplay(dstatus);
  }

  addToCart(pid: number, price: number) {
    let cartdata = {
      "cart_items_details": [
        {
          "product_id": pid,
          "quantity": 1,
          "price": price,
          "item_size": ""
        }
      ]
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      localStorage.removeItem("cartdata");
      this.homeSpecialAddCart = this.customerService.addToCart(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.cartresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.cartresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.router.routeToShoppingCart();
            }
          });
    } else {
      localStorage.setItem("cartdata", JSON.stringify(cartdata));
      this.router.routeToShoppingCart();
    }

  }

  addToWishlist(pid: number) {

    /**
       * For Google Analytics to track add-to-wishlist event
       */

    this
      .googleAnalyticsService
      .eventEmitter("add_to_wishlist", "shop", "wishlist", "click", 9);

    /**
     * ***********************************************
     */

    let cartdata = {
      "wishlist_items_details": [
        {
          "product_id": pid,
          "item_size": ""
        }
      ]
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.wishlistadd = this.customerService.addToWishList(cartdata, this.custId)
        .subscribe(
          response => {
            response = this.wishlistresponse = response;
            // IF block will execute only when the corresponding service is down or not
            // getting any response
            if (this.wishlistresponse['message'] == 'DOWN') {
              this.router.routeToTryAgainPage();
            } else {
              this.hideSuccessMessage = false;
            }
          });
    } else {
      localStorage.setItem("wishlistdata", JSON.stringify(cartdata));
      this.router.routeToWishList();
    }

  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  showProductDetailsInfo(pid: string) {
    var encrypted = this.encryptService.set(Utility.secretKey, pid);
    var encodevalue = encodeURIComponent(encrypted);

    window.open('/productview?pid=' + encodevalue, '_blank');
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.homeSpeacial) {
      this.homeSpeacial.unsubscribe();
    }
    if (this.homeSpecialAddCart) {
      this.homeSpecialAddCart.unsubscribe();
    }
    if (this.wishlistadd) {
      this.wishlistadd.unsubscribe();
    }
  }
}
