import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { RouterService } from '../services/router.service';
import { CustomerService } from '../services/customer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.css']
})
export class LogoutPageComponent implements OnInit, OnDestroy {

  sessionsub: Subscription;
  responsedata: any;

  constructor(
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
    private userService: CustomerService
  ) { }

  ngOnInit() {
    var sessionid: string = localStorage.getItem('sessionid');
    let sdata = {
      "id": sessionid
    }
    if (sessionid != null) {
      this.sessionsub = this.userService.updateSessionDetails(sdata)
        .subscribe(
          data => {
            data = this.responsedata = data;
            this.authenticationService.logout();
          });
    } else {
      this.routerService.routeToLogin();
    }

    //this.routerService.routeToLogin();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.sessionsub) {
      this.sessionsub.unsubscribe();
    }
  }

}
