import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RefundReplaceConfirmPageComponent } from './refund-replace-confirm-page.component';

const routes1: Routes = [
    {
        path: "",
        component: RefundReplaceConfirmPageComponent
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes1)]
})
export class RefundReplaceConfirmPageRoutingModule { }