import { Component, OnInit } from '@angular/core';

declare var $: any

@Component({
  selector: 'app-my-productreview-page',
  templateUrl: './my-productreview-page.component.html',
  styleUrls: ['./my-productreview-page.component.css']
})
export class MyProductreviewPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function ($) {
      $("#mobile-menu").mobileMenu({
        MenuWidth: 250,
        SlideSpeed: 300,
        WindowsMaxWidth: 767,
        PagePush: true,
        FromLeft: true,
        Overlay: true,
        CollapseMenu: true,
        ClassName: "mobile-menu"
      });
    });
  }

}
