import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { RouterService } from '../services/router.service';

declare var $: any

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.css']
})
export class NavigationMobileComponent implements OnInit {

  constructor(
    public authenticationService: AuthenticationService,
    private routerService: RouterService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
  }

  getProductList(categoryname: string) {
    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", categoryname, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    this.routerService.routeToSearch(categoryname, 'catgry');
  }

  viewAllProductList(dstatus: string) {
    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", dstatus, "click", 3);

    this.routerService.routeToProductsListDisplay(dstatus);
  }

  getProductByMenu(displaystatus: string) {

    /**
       * For Google Analytics to track select_content event
       */

    this
      .googleAnalyticsService
      .eventEmitter("select_content", "engagement", displaystatus, "click", 3);

    /**
     * ***********************************************
     */
    //window.location.replace('/search?w=' + categoryname + '&mode=catgry');
    //this.routerService.routeToSearch(categoryname, "catgry");
    this.routerService.routeToSearch(displaystatus, 'menu');
  }

  goMyOrderPage() {
    //window.location.replace('/myorders');
    this.routerService.routeToMyOrders();
  }

  goMyProfilePage() {
    //window.location.replace('/myprofile');
    this.routerService.routeToMyProfile();
  }

  goMyAddressPage() {
    //window.location.replace('/myaddresses');
    this.routerService.routeToMyAddress();
  }

  goMyWishListPage() {
    //window.location.replace('/wishlistview');
    this.routerService.routeToMyWishList();
  }

  goToHomePage() {
    //window.location.replace('/homeview');
    this.routerService.routeToHome();
  }

  goToLoginOutPage() {
    window.location.replace('/logout');
  }

  goToLoginPage() {
    //window.location.replace('/login');
    this.routerService.routeToLogin();
  }

  goAboutUsPage() {
    //window.location.replace('/aboutus');
    this.routerService.routeToAboutUs();
  }

  goContactUsPage() {
    //window.location.replace('/contactus');
    this.routerService.routeToContactUs();
  }

  goReplacementPage() {
    //window.location.replace('/replacementpolicy');
    this.routerService.routeToReplacementPolicy();
  }

  goTermUsePage() {
    //window.location.replace('/termsofuse');
    this.routerService.routeToTermsOfUse();
  }

  goPrivacyPolicyPage() {
    //window.location.replace('/privacy');
    this.routerService.routeToPrivacy();
  }

  goShippingPolicyPage() {
    //window.location.replace('/shippingpolicy');
    this.routerService.routeToShippPolicy();
  }

  goMyCheckoutPage() {
    //window.location.replace('/shoppingcartview');
    this.routerService.routeToShoppingCart();
  }

  // @HostListener("click")
  // clicked() {
  //   console.log("something cliked");
  //   $(".dropdownmenu1").click(function (event) {
  //     console.log(event);
  //     event.stopPropagation();
  //   });
  // }

  goToTrackOrderPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToTrackOrder();
  }

  goToBulkOrderPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToBulkOrder();
  }

  goToSell() {
    //window.location.replace('/homeview');
    this.routerService.routeToSellOnKhoaihaat();
  }

  goToSecondLevelNavPage() {
    //window.location.replace('/homeview');
    this.routerService.routeToSecondLevelNavPage();
  }

}
