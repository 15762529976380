import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
// import { Subscription } from 'rxjs';
// import { DataSharedService } from '../services/datashared.service';

declare var $: any

@Component({
  selector: 'app-product-search-page',
  templateUrl: './product-search-page.component.html',
  styleUrls: ['./product-search-page.component.css']
})
export class ProductSearchPageComponent implements OnInit {

  // messagedata: Subscription;
  // message: string;
  // isFilterOptionDisplay: boolean

  constructor(
    // private dataSharedService: DataSharedService
  ) { }

  ngOnInit() {
    // this.messagedata = this.dataSharedService.currentMessage
    //   .subscribe(
    //     message => {
    //       this.message = message;
    //       if(this.message == 'Yes'){
    //         this.isFilterOptionDisplay = true;
    //       }else if (this.message == 'No'){
    //         this.isFilterOptionDisplay = false;
    //       }
    //     });
    $(document).ready(function ($) {
      $("#mobile-menu").mobileMenu({
        MenuWidth: 250,
        SlideSpeed: 300,
        WindowsMaxWidth: 767,
        PagePush: true,
        FromLeft: true,
        Overlay: true,
        CollapseMenu: true,
        ClassName: "mobile-menu"
      });
    });
  }

}
