import { NgModule } from '@angular/core';

import { ProductSearchPageRoutingModule } from './product-search-routing.module';

import SharedModule from '../shared.module';
import { ProductSearchPageComponent } from '../product-search-page/product-search-page.component';
import { ProductSearchViewComponent } from '../product-component/product-search-view/product-search-view.component';

@NgModule({
    declarations: [
        ProductSearchPageComponent,
        ProductSearchViewComponent],
    imports: [
        SharedModule,
        ProductSearchPageRoutingModule],
    providers: []
})
export class ProductSearchPageModule {}