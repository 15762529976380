import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./header/header.component";
import { HeaderCustomComponent } from './header-custom/header-custom.component'
import { FooterComponent } from "./footer/footer.component";
import { HomeOurFeaturesComponent } from "./home-component/home-our-features/home-our-features.component";
import { NavigationMobileComponent } from "./navigation-mobile/navigation-mobile.component";
import { MyLoaderComponent } from "./components/my-loader/my-loader.component";
import { NavigationTopComponent } from "./navigation-top/navigation-top.component";
import { HeaderErrorCustomComponent } from './header-error-custom/header-error-custom.component';

import { BoxShopByOptionsComponent } from './grid-list-component/box-shop-by-options/box-shop-by-options.component';
import { PromotionalLeftSliderComponent } from "./grid-list-component/promotional-left-slider/promotional-left-slider.component";
import { HomeMostviewedProductComponent } from './home-component/home-mostviewed-product/home-mostviewed-product.component';

@NgModule({
    imports: [CommonModule,
        ReactiveFormsModule],
    declarations: [
        HeaderComponent,
        HeaderCustomComponent,
        HeaderErrorCustomComponent,
        FooterComponent,
        HomeOurFeaturesComponent,
        NavigationMobileComponent,
        HomeMostviewedProductComponent,
        MyLoaderComponent,
        NavigationTopComponent,
        BoxShopByOptionsComponent,
        PromotionalLeftSliderComponent
    ],
    exports: [
        HeaderComponent,
        HeaderCustomComponent,
        FooterComponent,
        HomeOurFeaturesComponent,
        NavigationMobileComponent,
        NavigationTopComponent,
        BoxShopByOptionsComponent,
        PromotionalLeftSliderComponent,
        HomeMostviewedProductComponent,
        HeaderErrorCustomComponent,
        MyLoaderComponent,
        ReactiveFormsModule,
        CommonModule,
        FormsModule
    ]
})
export default class SharedModule {}