import { NgModule } from '@angular/core';

import { MyAddressesPageRoutingModule } from './my-addresses-page-routing.module';
import { MyAddressesPageComponent } from './my-addresses-page.component';
import { MyAddressInfoComponent } from '../customer-component/my-address-info/my-address-info.component';

import SharedModule from '../shared.module';
import CustomerAccountRightNavModule from '../modules/customer-account-right-nav.module';

@NgModule({
    declarations: [
        MyAddressesPageComponent,
        MyAddressInfoComponent
    ],
    imports: [
        SharedModule,
        MyAddressesPageRoutingModule,
        CustomerAccountRightNavModule
    ],
    providers: []
})
export class MyAddressesPageModule { }