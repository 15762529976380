import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ModalService } from '../../modal-window/modal.service';
import { CustomerService } from '../../services/customer.service';
import { RouterService } from '../../services/router.service';
import { AuthenticationService } from '../../services/authentication.service';

// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../../common/must-match.validator';

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change-password-popup.component.html',
  styleUrls: ['./change-password-popup.component.css']
})
export class ChangePasswordPopupComponent implements OnInit, OnDestroy {

  changePasswordForm: FormGroup;
  submitted = false;
  hideSuccessMessage: boolean = true;
  errormessage: string;

  changepasswordsub: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private routerService: RouterService,
    private modalService: ModalService,
    private userService: CustomerService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  onSubmit() {
    this.submitted = true;
    // Return if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }
    var useremail = localStorage.getItem("user");
    let userdata = {
      "customer_email": useremail,
      "oldpassword": this.f.oldpassword.value,
      "password": this.f.password.value
    }

    this.changepasswordsub = this.userService.updatePassword(userdata)
      .subscribe(
        data => {
          if (data) {
            this.authenticationService.logout();
            this.routerService.routeToLogin();
          } else {
            this.errormessage = "Curent password did not match with the existing one. Please verify."
            this.hideSuccessMessage = false;
            this.f.oldpassword.reset();
          }
        });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.hideSuccessMessage = true;
    }, 2500);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.changepasswordsub) {
      this.changepasswordsub.unsubscribe();
    }
  }

}
